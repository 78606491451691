import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CashTransfer } from "services/network";

export const startTransferToBank = createAsyncThunk(
  "cashTransfer/startTransferToBank",
  async (payload) => {
    const response = await CashTransfer.startTransferToBank(payload);
    return response.data.data;
  }
);

export const completeTransferToBank = createAsyncThunk(
  "cashTransfer/completeTransferToBank",
  async (payload) => {
    const response = await CashTransfer.completeTransferToBank(payload);
    return response.data.data;
  }
);

const cashTransfer = createSlice({
  name: "cashTransfer",
  initialState: {},
  reducers: {},
});

export default cashTransfer.reducer;
