const smallDollar = require("../../assets/img/small-dollar-bag.svg");
const fundInvestment = require("../../assets/img/fund-investment.svg");
const addInvestment = require("../../assets/img/add-investment.svg");
const investBars = require("../../assets/img/investbars.svg");
const catfish = require("../../assets/img/catfish.svg");
const corn = require("../../assets/img/corn.svg");
const dogs = require("../../assets/img/dogs.svg");
const addBtn = require("../../assets/img/add-btn.svg");
const handCoin = require("../../assets/img/hand-coin.svg");
const handHouse = require("../../assets/img/handHouse.svg");
const handTree = require("../../assets/img/handTree.svg");
const moneyWheels = require("../../assets/img/money-wheels.svg");
const moneyBag = require("../../assets/img/moneyBag.svg");
const pigCoin = require("../../assets/img/pig-coin.svg");
const storeCoin = require("../../assets/img/store-coin.svg");
const financeMan = require("../../assets/img/financeMan.svg");
const addPhoto = require("../../assets/img/addPhoto.svg");
const master = require("../../assets/img/master.svg");
const add = require("../../assets/img/add.svg");
const info = require("../../assets/img/info.svg");
const withblack = require("../../assets/img/withblack.svg");
const withdram = require("../../assets/img/withdraw.svg");
const terminate = require("../../assets/img/terminate.svg");
const bulb = require("../../assets/img/bulb.svg");
const creditcard = require("../../assets/img/creditcard2.svg");
const wallet = require("../../assets/img/wallet2.svg");
const fullterm = require("../../assets/img/fullTerm.svg");
const partTerm = require("../../assets/img/partTerm.svg");
const information = require("../../assets/img/information.svg");
const dogsBg = require("../../assets/img/dogsBg.png");

module.exports = {
  dogsBg,
  smallDollar,
  fundInvestment,
  addInvestment,
  investBars,
  catfish,
  corn,
  dogs,
  PlusIcon: addBtn,
  handCoin,
  handHouse,
  handTree,
  moneyWheels,
  moneyBag,
  pigCoin,
  storeCoin,
  financeMan,
  addPhoto,
  master,
  add,
  info,
  withblack,
  withdram,
  terminate,
  bulb,
  creditcard,
  wallet,
  fullterm,
  partTerm,
  information,
};
