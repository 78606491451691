import React from "react";
import { addInvestment, fundInvestment, investBars } from "../../imageLinks";
import { Link } from "react-router-dom";
import { formatCurrency } from "utils";

const SummaryCard = ({
  showBlank,
  totalCapital,
  totalCapitalNGN,
  totalCapitalUSD,
  totalReturnsUSD,
  totalReturnsNGN,
  totalCarryingValueNGN ,
  totalCarryingValueUSD,
  totalReturns,
  totalInterests,
}) => (
  <div className="card-padding card h-auto card-black flex-column text-white">
    {/* <div className="flex flex-row items-center justify-between content-center">
      <div className="flex flex-col">
        <h5 className="text-xs mb-2 font-medium text-faded">Current Value</h5>

        <div className="text-gray-100 text-2xl font-medium pl-0">
          {showBlank ? "..." : totalReturns == 0 ? "₦0.00" : `₦${formatCurrency(totalReturns)}`}
        </div>
      </div>

      <img src={investBars} className="w-12 h-12" alt="" />
    </div> */}

    <div className="flex flex-row items-center justify-between content-center">
      <div className="text-left">
      <h5 className="text-xs mb-2 font-medium text-faded">Current Value (₦)</h5>

      <div className="text-gray-100 text-2xl font-medium pl-0">
          {showBlank ? "..." : totalCarryingValueNGN == 0 ? "₦0.00" : `₦${formatCurrency(totalCarryingValueNGN)}`}
        </div>
      </div>

      <div style={{ width: 1 }} className="h-20 bg-gray-900" />

      <div className="text-right card-margin--y ml-2 sm:ml-0 sm:pr-6">
        <h5 className="text-xs mb-2 font-medium text-faded">Current Value ($)</h5>
        <div className="text-gray-100 text-2xl font-medium pl-0">
          {showBlank ? "..." : totalCarryingValueUSD == 0 ? "₦0.00" : `$${formatCurrency(totalCarryingValueUSD)}`}
        </div>
      </div>
    </div>


    <div className="flex flex-summary py-4 mt-3 justify-between items-center content-centers border-solid border-b divider-border">
      {/* <div className="text-left">
        <h5 className="text-xs mb-2 font-medium text-faded">
         Principal
        </h5>
        <p className="text-gray-100 text-2xl font-medium text-left">
          {showBlank ? "..." : totalCapitalNGN == 0 ? "₦0.00" : `₦${formatCurrency(totalCapitalNGN)}`}
        </p>
      </div> */}

        <div className="text-left">
        <h5 className="text-xs mb-2 font-medium text-faded">Returns (₦)</h5>
        <p className="text-gray-100 text-2xl font-medium text-left">
          {showBlank ? "..." : totalReturnsNGN == 0 ? "₦0.00" : `₦${formatCurrency(totalReturnsNGN)}`}
        </p>
      </div>

      <div style={{ width: 1 }} className="h-20 bg-gray-900" />

      <div className="text-right card-margin--y ml-2 sm:ml-0 sm:pr-6">
        <h5 className="text-xs mb-2 font-medium text-faded">Returns ($)</h5>
        <p className="ext-gray-100 text-2xl font-medium">
          {showBlank
            ? "..."
            : totalReturnsUSD == 0 ? "₦0.00" :`$${formatCurrency(totalReturnsUSD)}`}
        </p>
      </div>
    </div>

    <div className="flex flex-summary flex-row sm:flex-row justify-between items-center content-center pt-6">
      <Link
        to="/dashboard/investment/fund-investment"
        className="pl-2 flex items-center content-center"
      >
        <img src={fundInvestment} alt="wealth-buddy" className="pr-3" />
        <p className="text-white text-base font-medium self-center">
          Fund Investment
        </p>
      </Link>
      <Link
        to="/dashboard/investment/add-investment"
        className="pl-6 Flexible flex flex-row  relative ml-2 sm:ml-0 items-center content-center pr-5"
      >
        <img src={addInvestment} alt="wealth-buddy" className="pr-3" />
        <p className="text-white text-base font-medium self-center">
          Add Investment
        </p>
      </Link>
    </div>
  </div>
);

export default SummaryCard;
