import React, { Fragment, useContext } from "react";
import AddBankContext from "contexts/AddBankContext";
import { formatCurrency } from "utils";
import CardIcon from "assets/img/cardIcon.png";
import SuccessModal from "shared-components/modals/SuccessModal";

const ChangeWithdrawalLimitSuccess = () => {
  const {
    isChangeWithdrawalLimitSuccessModalOpen,
    closeChangeWithdrawalLimitSuccessModal,
  } = useContext(AddBankContext);

  return (
    isChangeWithdrawalLimitSuccessModalOpen && (
      <SuccessModal
        title="Success"
        subtitle="Withdrawal limit change request successful"
        icon={CardIcon}
        buttonTitle="Done"
        closeModal={closeChangeWithdrawalLimitSuccessModal}
      />
    )
  );
};

export default ChangeWithdrawalLimitSuccess;
