import React, { useContext, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { usePaystackPayment } from "react-paystack";
import AddBankContext from "contexts/AddBankContext";
import Loading from "shared-components/Loading";

const PaystackModal = ({ customerDetails, verifyFundWithNewCardLoading }) => {
  const {
    isPaystackModalOpen,
    amount,
    tranxRef,
    handlePaystackSuccess,
    closePaystackModal,
  } = useContext(AddBankContext);

  const { isCardAdded } = useSelector((state) => state.account.data);
  
  const paymentData = {
    reference: tranxRef,
    email: customerDetails.email,
    amount: amount * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_TEST_KEY,
  }

  if (!isCardAdded) paymentData.channels =  ["card"];
  
  const initializePayment = usePaystackPayment(paymentData)
  
  // const initializePayment = usePaystackPayment({
  //   reference: tranxRef,
  //   email: customerDetails.email,
  //   amount: amount * 100,
  //   channels: isCardAdded
  //   ? ["bank", "card", "qr", "ussd", "mobile_money"]
  //     : ["card"],
    
  //   publicKey: process.env.REACT_APP_PAYSTACK_TEST_KEY,
  // });


  useEffect(() => {
    if (isPaystackModalOpen) {
      initializePayment(handlePaystackSuccess, closePaystackModal);
    }
  }, [isPaystackModalOpen]);

  return (
    isPaystackModalOpen && (
      <div className="modal modal-active fixed inset-0 bg-wb-overlay flex justify-center items-center">
        {verifyFundWithNewCardLoading && <Loading text="" />}
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  customerDetails: state.account.data.customerDetails,
  verifyFundWithNewCardLoading: state.verifyFundWalletWithNewCard.loading,
});

export default connect(mapStateToProps)(PaystackModal);
