import React, { Fragment } from "react";
import SuccessIcon from "assets/img/success.svg";
import CloseModalIcon from "shared-components/svgs/CloseModalIcon";
import FailedIcon from "assets/img/failedDoc.svg";
import { Link } from "react-router-dom";
import Loading from "shared-components/Loading";

const DollarCreateSuccessModal = ({ isVisible, loading, error, close }) =>
  isVisible ? (
    <div className="modal fixed inset-0 bg-wb-overlay flex justify-center items-center modal-active">
      <div className="auth-modal flex flex-col items-center bg-white fadeIn login-fieldset">
        <span className="closeModal cursor-pointer" onClick={close}>
          <span className="closeModal">
            <CloseModalIcon />
          </span>
        </span>
        {loading ? (
          <div className="mx-auto flex flex-col content-center items-center">
            <Loading text=" " />
          </div>
        ) : error ? (
          <Fragment>
            <div className="flex flex-col items-center mb-0">
              <i className="w-20 mb-4">
                <img src={FailedIcon} alt="" />
              </i>
              <h1 className="text-2xl font-medium mb-2">An error occurred</h1>
              <p className="text-center text-gray-500 leading-normal">
                {error}
              </p>

              <button
                onClick={() => {
                  close();
                }}
                className={`mt-6 w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm`}
              >
                Done
              </button>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="flex flex-col items-center mb-0">
              <i className="w-20 mb-4">
                <img src={SuccessIcon} alt="" />
              </i>
              <h1 className="text-2xl font-medium mb-2">
                Your Investment has been received
              </h1>
              <p className="text-center text-gray-500 leading-normal">
                Your investment will be reviewed and your position credited in
                the next 24 hours.
              </p>

              <Link to="/dashboard/investment">
                <button
                  onClick={close}
                  className={`mt-6 w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm`}
                >
                  Done
                </button>
              </Link>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  ) : null;

export default DollarCreateSuccessModal;
