export const START_VERIFY_EMAIL_START = "START_VERIFY_EMAIL_START";
export const START_VERIFY_EMAIL_SUCCESS = "START_VERIFY_EMAIL_SUCCESS";
export const START_VERIFY_EMAIL_FAIL = "START_VERIFY_EMAIL_FAIL";
export const START_VERIFY_EMAIL = "START_VERIFY_EMAIL";

export default {
  START_VERIFY_EMAIL_START,
  START_VERIFY_EMAIL_SUCCESS,
  START_VERIFY_EMAIL_FAIL,
  START_VERIFY_EMAIL,
};
