import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import InvestmentIcon from "shared-components/svgs/InvestmentIcon";
import SavingsIcon from "shared-components/svgs/SavingsIcon";
import GainIcon from "shared-components/svgs/GainIcon";
import WalletIcon from "shared-components/svgs/WalletIcon";
import DashboardIcon from "shared-components/svgs/DashboardIcon";
import { formatCurrency } from "utils";
import ReactTooltip from "react-tooltip";
import _, { forEach } from "lodash";



const DashboardSummary = ({
  dashboard,
  investmentValuation,
  customerSavings,
}) => {


  const totalReturns =
    customerSavings.reduce((total, item) => { 
      if (item.status == 2 || item.status == 3 ) {
        return total + item.totalInterestEarned
      } 
    }, 0) || 0;
  //  _.sumBy(
  //   customerSavings,
  //   (item) => item.totalInterestEarned && item.status !=5
  //   ) || 0;
  


  const  investmentValuationFixedDeposits = (investmentValuation) ? investmentValuation.fixedDeposits : []
  
  const totalFixedDepositUSDInvestments = 
   _.sumBy(
    investmentValuationFixedDeposits,
    (item) => {
      if(item.carryingValue.currency.toString() === "USD"){
        return item.carryingValue.amount
      }else{
        return 0
      }
    } 
  ) || 0
  
  let totalFixedDepositNGNInvestments = 0
  if (investmentValuation) {
    totalFixedDepositNGNInvestments =
      _.sumBy(
        investmentValuationFixedDeposits,
        (item) => {
          if (item.carryingValue.currency.toString() === "NGN") {
            return item.carryingValue.amount
          } else {
            return 0
          }
        }
      )
  }

  let totalFixedDepositIncomeNGN = 0
  if (investmentValuation) {
    totalFixedDepositIncomeNGN = _.sumBy(
      investmentValuationFixedDeposits,
      (item) => {
        if (item.carryingValue.currency.toString() === "NGN") {
          return item.interestLessTaxes.amount
        } else {
          return 0
        }
      }
    )
  }


  let InvestmentPrincipalSummation = 0
  if (investmentValuation) {
    InvestmentPrincipalSummation =
      ((investmentValuation.fundCost) + totalFixedDepositNGNInvestments
        + (investmentValuation.totalTBillValue)
        + investmentValuation.bondValue
        + investmentValuation.equityValue)
  }
    

  let InvestmentReturnsummation = 0
  if (investmentValuation) {
    InvestmentReturnsummation =
      ((investmentValuation.fundValue) - (investmentValuation.fundCost))
      + totalFixedDepositIncomeNGN
      + (investmentValuation.totalTBillInterestValue)
      + investmentValuation.bondReturn
      + investmentValuation.equityReturn
  }




 //console.log(typeof(investmentValuationData.fixedDeposits))
// console.log(`Totalreturnsgist ${JSON.stringify(totalReturns)}`)
// console.log(`TotalInvestments ${JSON.stringify(totalInvestments)}`)

//  const USDInvestment = _.isEmpty(investmentValuationData) ? [] : "got here";
  //investmentValuationData.fixedDeposits.filter(item => item.carryingValue.currency.toString() === "USD") ;

  //console.log(`USDInvestment: ${JSON.stringify(USDInvestment)}`)
  //console.log(typeof(USDInvestment))
 
  // const totalUSDInvestment = _.sumBy(
  //   USDInvestment,
  //   (item) => item.carryingValue.amount
  // );

  //console.log(`totalUSDInvestment: ${JSON.stringify(totalUSDInvestment)}`)

  // const totalUSDInvestment = _.sumBy(
  //   USDInvestment.forEach((item)=>item.filter(carryingValue.amount.toString() === "USD"
  //     )

  //   )
  //   //(item) => item.fixedDeposits.carryingValue.amount
  // );

  return (
    <Fragment>
      <div className="flex flex-col card card-black flex-summary">
        <div className="flex justify-between items-center card-margin--x mini-summary">
          <div className="text-white flex">
            <span className="mr-2 text-faded">
              <DashboardIcon />
            </span>
            <div>
              <h5 className="text-xs mb-2 font-medium text-faded">
                Net asset (₦)
              </h5>
              <h2 className="summary-balance font-medium">
           
                {`₦${formatCurrency(
                  dashboard.netAssetValue + InvestmentPrincipalSummation + InvestmentReturnsummation
                )}`}
              </h2>
            </div>
          </div>
          <div className="text-white flex">
            <span className="mr-2 text-faded">
              <DashboardIcon />
            </span>
            <div>
              <h5 className="text-xs font-medium mb-2 text-faded">
                Net asset ($)
              </h5>
              <h2 className="summary-balance font-medium">
                {`$${formatCurrency(dashboard.netAssetValueDollar + totalFixedDepositUSDInvestments)}`}
              </h2>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center card-margin--x mini-summary">
          <div className="text-white flex">
            <span className="mr-2 text-faded">
              <WalletIcon />
            </span>
            <div>
            <Link
                to="/dashboard/wallet"
                className="summary-balance font-medium"
                data-tip="Add fund to wallet"
              >
              <h5 className="text-xs mb-2 font-medium text-faded">Wallet</h5>
              
                {`₦${formatCurrency(dashboard.walletBalance)}`}
              </Link>
            </div>
          </div>
          <div className="text-white flex">
            <span className="mr-2 text-faded">
              <GainIcon />
            </span>
            <div>
              <h5 className="text-xs font-medium mb-2 text-faded">Returns</h5>
              <h2 className="summary-balance font-medium">
                {`₦${formatCurrency(totalReturns + InvestmentReturnsummation) || 0}`}
              </h2>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center card-margin--x mini-summary">
          <div className="text-white flex">
            <div className="mr-2 text-faded">
              <SavingsIcon />
            </div>
            <div>
              <h5 className="text-xs mb-2 font-medium text-faded">Savings</h5>
              <h2 className="summary-balance font-medium">
                {`₦${formatCurrency(dashboard.totalSavings)}`}
              </h2>
            </div>
          </div>
          <div className="text-white flex">
            <span className="mr-2 text-faded">
              <InvestmentIcon />
            </span>
            <div>
              <h5 className="text-xs mb-2 font-medium text-faded">
                Investment
              </h5>
              <h2 className="summary-balance font-medium">
                {`₦${formatCurrency(InvestmentPrincipalSummation)}`}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <ReactTooltip effect="solid" multiline={true} />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard.data,
  investmentValuation: state.investments.investmentValuationData,
  customerSavings: state.customerSavings.data,
});

export default connect(mapStateToProps)(DashboardSummary);
