import React, { useState, useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ChoosePaymentMethodModal from "../ChoosePaymentMethodModal";
import CreatePaymentForm from "./CreatePaymentForm";
import CreateAirtimeForm from "./CreatePaymentForm";
import CreateCableForm from "./CreatePaymentForm";
import RecurringModal from "../BillsList/RecurringModal/RecurringModal";
import { useSelector } from "react-redux";

import {
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import AddBankContext from "contexts/AddBankContext";
import Select from "react-select";
import Loading from "shared-components/Loading";
import EmptyCard from "shared-components/EmptyCard";



const generateVlidationSchema = (customerField) =>
Yup.object({
    amount: Yup.number()
    .required('ERROR: The amount is required!')
    .test(
      'Is positive?', 
      'ERROR: The amount must be at least 100', 
      (value) => value > 0
    ),
    paymentCode: Yup.string(),
    currencyCode: Yup.string(),
    itemFee: Yup.string(),
    [customerField]: Yup.string().required(),
  });

export const selectStyles = {
  control: () => ({
    width: "100%",
    height: "48px",
    display: "flex",
    justifyContent: "space-between",
    background: "#fff",
    border: "1px solid #e7eaf4",
    boxSizing: "border-box",
    borderRadius: "4px",
    alignItems: "center",
    padding: "20px 15px",
    fontWeight: "400",
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#000",
    visibility: "visible",
    listStyle: "none",
    boxSizing: "border-box",
    margin: 0,
    border: 0,
    fontSize: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "16px 20px",
    cursor: "pointer",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    borderTop: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  }),
};

const ProviderForm = (props) => {
  // CONTEXT
  const {
    makePurchase,
    billers,
    billPaymentItems,
    dispatchMakePurchase,
    dispatchGetBillPaymentItems,
    dispatchResetPurchase,
    
  } = useContext(AddBankContext);

  //PROPS
  // const { handleTitleChange } = props;
  // const { handleTitleChange } = props;

  // REDUX
  const { data: dashboard } = useSelector((state) => state.dashboard);
  const { data: cards } = useSelector((state) => state.cards);
  const { data: customer } = useSelector((state) => state.account);

  //ROUTER
  const history = useHistory();
  const { state } = useLocation();
  const { id, billerId } = useParams();
  const customerId = state?.biller.customerfield1;

  const activeBiller = billers.data.length
    ? billers.data.find((biller) => biller.billerid === billerId)
    : {};

  // STATE
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);
  const [cardId, setCardId] = useState("");
  const [catId] = useState(id);
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false)
  const [action, setAction] = useState(false)

  // CONTROLLER
  const handlePayment = () => {
    formik.validateForm()
    .then((errors) => {
     // console.log(`${JSON.stringify(errors)}`)
     // alert(errors.leng)
     //if (errors) return;

      formik.submitForm();
    });
  };

  const handleSubmit = (values) => {
  
   if(values.amount < 0) return

    const body = {
      customerMobile: customer.phone,
      customerEmail: customer.email,
      paymentCode: values.paymentCode,
      customerId: values[customerId],
      currencyCode: values.currencyCode,
      amount: String(values.amount * 100),
      itemFee: String(values.itemFee),
    };
    if (cardId) {
      body.cardId = cardId;
    }

    dispatchMakePurchase(body);
  };

  // EFFECT
  useEffect(() => {
    if (billerId) {
      dispatchGetBillPaymentItems(billerId);
    }
  }, [billerId]);

  // FORMS

  const formik = useFormik({
    initialValues: {
      [customerId]: "",
      itemFee: 0,
      paymentItem: "",
      paymentCode: "",
      currencyCode: "",
      amount: 0,
    },

    validationSchema: generateVlidationSchema(customerId),
    onSubmit:  handleSubmit
  });

  // RENDER HELPERS
  const renderBillPaymentItems = () => {
    const options = billPaymentItems.data.map((paymentItem) => {
      return {
        value: paymentItem,
        label: paymentItem.paymentitemname,
      };
    });

    const handlePaymentItemChange = (paymentItem) => {
      formik.setFieldValue("paymentItem", paymentItem.value);
      formik.setFieldValue("paymentCode", paymentItem.value.paymentCode);
      formik.setFieldValue("currencyCode", paymentItem.value.currencyCode);
      formik.setFieldValue("amount", paymentItem.value.amount / 100);
    };

    return (
      <div className="form-group mb-4">
        <label className="flex flex-col" htmlFor="">
          <small className="mb-2">Choose a product</small>

          <Select
            className="w-full max-wallet-lg"
            styles={selectStyles}
            options={options}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "rgba(113, 128, 150, 0.1)",
                primary: "rgba(153, 191, 24, 0.35)",
              },
            })}
            onChange={handlePaymentItemChange}
          />
        </label>
      </div>
    );
  };

  if (!billPaymentItems.data && !billPaymentItems.loading) {
    return (
      <div className="px-12 mt-10">
        <h1 className="text-4xl py-8 font-medium content-header--border content-header--title">
          {activeBiller.billername ? `${activeBiller.billername} Purchase` : ""}
        </h1>
        <div className="flex justify-center mt-20">
          <div className="mt-10 md:mt-0 mx-4 w-full max-wallet-lg bg-white shadow-md rounded-14">
            <div className="px-8 py-6 border border-orange-900 wb-border-b flex align-middle">
              <div className="cursor-pointer" onClick={history.goBack}>
                <img
                  className="h-4"
                  src={require("assets/img/Arrow.svg")}
                  alt="Go Back"
                />
              </div>
              <p className="font-bold text-lg ml-4">
                {activeBiller.billername}
              </p>
            </div>
            <div className="flex flex-col items-center bg-white fadeIn login-fieldset onboarding-wrap auth-modal">
              <EmptyCard title="No payment item available"></EmptyCard>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="px-12 mt-10">
      <h1 className="text-4xl py-8 font-medium content-header--border content-header--title">
        {activeBiller.billername ? `${activeBiller.billername} Purchase` : ""}
      </h1>
      <div className="flex justify-center mt-20">
        {showAddPaymentModal && (
          <ChoosePaymentMethodModal
            setVisible={setShowAddPaymentModal}
            dashboard={dashboard}
            cards={cards}
            card={cardId}
            setCard={setCardId}
            paymentSuccess={makePurchase.data}
            loading={makePurchase.loading}
            paymentError={makePurchase.error}
            handlePayment={handlePayment}
            resetPurchase={dispatchResetPurchase}
          />
        )}
        {show && (
          <RecurringModal
            setShow={setShow}
            show={show}
            setChecked={setChecked}
            checked={checked}
          />
        )}

        <div className=" md:mt-0 mx-4 w-full max-wallet-lg bg-white shadow-md rounded-14">
          <div className="px-8 py-6 border border-orange-900 wb-border-b flex align-middle">
            <div className="cursor-pointer" onClick={history.goBack}>
              <img
                className="h-4"
                src={require("assets/img/Arrow.svg")}
                alt="Go Back"
              />
            </div>
            <p className="font-bold text-lg ml-4">{activeBiller.billername}</p>
          </div>
          {billPaymentItems.loading ? (
            <div className="flex justify-center my-10">
              <Loading />
            </div>
          ) : (
            <>
              {billPaymentItems.data && catId !== "4" && catId !== "2"  && (
                <div className="p-8 py-6">
                  {renderBillPaymentItems()}
                  <CreatePaymentForm
                    setShowAddPaymentModal={setShowAddPaymentModal}
                    setShow={setShow}
                    formik={formik}
                    field={customerId}
                  />
                </div>
              )}

              {billPaymentItems.data && catId === "2" && (
                <div className="p-8 py-6">
                  {renderBillPaymentItems()}
                  <CreateAirtimeForm
                    setShowAddPaymentModal={setShowAddPaymentModal}
                    setShow={setShow}
                    formik={formik}
                      field={customerId}
                      action={action}
                  />
                </div>
              )}

              {billPaymentItems.data && catId === "4" && (
                <div className="p-8 py-6">
                  {renderBillPaymentItems()}
                  <CreateCableForm
                    setShowAddPaymentModal={setShowAddPaymentModal}
                    setShow={setShow}
                    formik={formik}
                    field={customerId}
                  />
                </div>
              )}


            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProviderForm;
