import React, { useEffect, useState } from "react";
import { financeMan } from "../../imageLinks";
import moment from "moment";
import { connect, useDispatch } from "react-redux";
import { getAllInvestments } from "state/slices/investments";
import Chart from "../Chart";
import { Link, Redirect, useParams } from "react-router-dom";
import { formatCurrency } from "utils";
import AccountIndicator from "../AccountIndicator";


const InvestmentInfo = ({ getAllInvestmentsData, investmentRateData }) => {
  const dispatch = useDispatch();
  const [highestRate, setHighestRate] = useState(null);
  const [lowestDuration, setLowestDuration] = useState(null);
  const params = useParams();

 // console.log(`param: ${params.investmentID}`)
  const investmentName = getAllInvestmentsData.find(
    (item) => item.investmentID.toString() === params.investmentId
  );
  

 // console.log(`getAllInvestmentsData: ${JSON.stringify(getAllInvestmentsData)}`)
 // console.log(`investmentRateData: ${JSON.stringify(investmentRateData)}`)
  //console.log(`investmentName: ${JSON.stringify(investmentName)}`)


  useEffect(() => {
    const AllTypeDurationFiltered = investmentRateData.filter(each=>each.investmentType === investmentName.investmentType);
    let OnlyDuration = [];
    let DurationLowest = [];
    AllTypeDurationFiltered.map(every => {
      OnlyDuration.push(every.interestRate);
      DurationLowest.push(every.duration);
      return 
    })
   
    const highest = Math.max(...OnlyDuration);
    const lowest = Math.min(...DurationLowest)
    setHighestRate(highest);
    setLowestDuration(lowest);
    return () => {
    setHighestRate(null);
    }
  }, [investmentRateData]);


  useEffect(() => {
    if (getAllInvestmentsData.length === 0) {
      dispatch(getAllInvestments());
    }
  }, []);

  const investmentDetails = [
    {
      name: "EQUITY MARKET FUND",
      investmentID: 51129656,
      summary:
        `You can begin your investment journey via our equity market mutual fund for very low amounts and subsequent fundings can be any amount you desire`,
      earnings:
        "The equity market fund is a high risk fund, that invests in a combination of fixed income instruments like treasury bills, commercial papers, fixed deposit and call placement.",
      auction: false,
    },
     {
      name: "MONEY MARKET FUND",
      investmentID: 51129542,
      summary:
        `The money market fund is a low-risk fund, that invests in equity instruments like stocks. The Meristem Money market fund is actively managed to return the best returns to investors. Our mutual funds allow you begin your investment journey with as low as NGN10,000 for the first month and fund your account with any amount subsequently. Our mutual fund account never expires and allows you gain interests as early as after 30days.
          Money funds are ideal investment vehicles for investors that are not as well-versed in financial investing or do not possess a large amount of capital with which to invest. Equity funds are practical investments for most people.`,
      earnings:
        "The money market fund is a low risk fund, that invests in a combination of fixed income instruments like treasury bills, commercial papers, fixed deposit and call placement.",
      auction: false,
    },
    {
      name: "FIXED DEPOSITS (FIXTIP)",
      investmentID: 45149062,
      summary:
        "The fixed term investment account serves as a better alternative to a savings account, especially for people looking to achieve set short-term projects. It affords individuals the opportunity to set aside money and get higher interest than a typical savings account in 30days. Are you looking to get a new phone? House? Or just a better way to save? FIXTIP makes all that easy.",
      earnings:
        "Fixed income broadly refers to those types of investment security that pay investors fixed interest or dividend payments until its maturity date.",
      auction: false,
    },
    {
      name: "TREASURY BILLS (MTLIP)",
      investmentID: 51118581,
      summary:
        'Treasury Bills, also known as "T-bills," are investment options issued by the Nigerian government. Treasury Bills afford you the opportunity to lend money to the government and get paid back with interest after a period time usually 91days, 182 days or 364days subject to your choice. This investment also offers you the opportunity to get your interest upfront or re-invest your capital plus interest after your investment tenure is over. T-bills is a great way to save for rent, school fees and other capital-intensive cost you may have.',
      earnings:
        "Treasury Bills afford you the opportunity to lend money to the government and get paid back with interest after a period time usually 91days, 182 days or 364days subject to your choice.",
      auction: true,
    },
     {
      name: "TREASURY BILLS (ASSET)",
      investmentID: 51119099,
      summary:
        'Treasury Bills, also known as "T-bills," are investment options issued by the Nigerian government. Treasury Bills afford you the opportunity to lend money to the government and get paid back with interest after a period time usually 91days, 182 days or 364days subject to your choice. This investment also offers you the opportunity to get your interest upfront or re-invest your capital plus interest after your investment tenure is over. T-bills is a great way to save for rent, school fees and other capital-intensive cost you may have.',
      earnings:
        "Treasury Bills afford you the opportunity to lend money to the government and get paid back with interest after a period time usually 91days, 182 days or 364days subject to your choice.",
      auction: true,
    },
    {
      name: "DOLLAR INVESTMENT (MDIP)",
      investmentID: 45149064,
      summary:
        `This investment offers you a shield from currency risk.
        It is a great way to spread your investment and reduce your risk exposure. The Meristem Dollar investment creates a platform for you to earn interest in foreign currency and invest in dollar denominated instruments.
        MERISTEM DOLLAR INVESTMENT PORTFOLIO-MDIP(USD).`,
      earnings: "Invest a minimum of $2000 and subsequent multiples of $500 or its naira equivalent (at available exchange rates) to diversify your portfolio. Earnings paid out at the end of the year.",
      auction: true,
    },
    {
      name: "ETHICAL EARNINGS (MEEP)",
      investmentID: 45149066,
      summary:`Would you like a portfolio product that aligns with your beliefs around interest earning?
        Then MEEP is for you. It offers a means of portfolio diversification especially for investors who have certain investment constraints due to religious or other ethical reasons. 
        It is backed by investments in ethical securities (excluding interest bearing, gambling/ alcoholic backed instruments etc) which provides highly competitive income for investors.`,
      earnings: "",
      auction: true
    },
    {
      name: "REAP-ARENA",
      investmentID: 45149135,
      summary:`Through this product, investors have the opportunity to co-own real estate and generate income via short let rental and full-term tenancy arrangement.
      This is a British pound denominated investment, designed to offer investors the actual advantage of currency appreciation and rental income. Invest a minimum of £2000 and subsequent multiples of £500 or its naira equivalent (at available exchange rates)`,
      earnings: "",
      auction: true
    }
  ];

  if (!investmentName) {
    return <Redirect to="/dashboard/investment" />;
  }

  return (
    <div className="px-4 sm:px-12  flex flex-col fadeIn mt-10">
      <div className="flex flex-wrap flex-row content-center w-full sm:w-8/12 items-center  mb-10 ">
        <Link
          to="/dashboard/investment"
          className="text-sm cursor-pointer text-gray-200"
        >
          Investment
        </Link>
        <div className="text-xs sm:mx-2 text-gray-200">&gt;&gt;</div>
        <Link
          to="/dashboard/investment/add-investment"
          className="text-sm cursor-pointer text-gray-200"
        >
          Add new Investment
        </Link>
        <div className="text-xs sm:mx-2 text-gray-200">&gt;&gt;</div>
        <div className="text-sm text-black">
          {investmentName.label} <AccountIndicator />
        </div>
      </div>

      <div
        style={{ backgroundColor: "#A8C93A" }}
        className="card relative rounded-lg py-4 sm:py-16 px-4  flex flex-col justify-between sm:flex-row"
      >
        <div className="flex flex-col">
          <p className="text-white font-bold text-xl sm:text-2xl">
            Get Value for your money
          </p>

          <p className="text-white mt-4  sm:text-lg sm:w-3/4 text-left">
            With our wide range of investment packages, you can relax and watch
            your money grow.
          </p>
        </div>
        <img
          src={financeMan}
          className="sm:absolute right-0 top-0 pb-20 pr-10"
        />
      </div>

      <p className="font-bold text-black text-xl sm:text-2xl ml-2 text-left mt-10">
        {investmentName.label}
      </p>

      <div className="flex flex-col sm:flex-row">
        <div className="sm:w-1/2 w-auto sm:mr-4">
          <div
            style={{
              border: "1px solid #F1F1F1",
            }}
            className="card p-4 rounded mt-10 "
          >
            <p
              style={{ color: "#999999" }}
              className="text-lg font-bold sm:text-base"
            >
              About this investment
            </p>

            <p className="text-lg text-black sm:text-base text-hairline  leading-loose mt-3">
              {
                investmentDetails.find(each=>each.investmentID === investmentName.investmentID).summary
              }
            </p>
          </div>

          <div
            style={{
              border: "1px solid #F1F1F1",
            }}
            className="card p-4 rounded mt-10"
          >
            <p
              style={{ color: "#999999" }}
              className="text-lg font-bold sm:text-base"
            >
              Earning on this investment
            </p>

            <p className="text-lg text-black sm:text-base text-hairline leading-loose  mt-3">
              {investmentDetails.find(each=>each.investmentID === investmentName.investmentID).earnings}
            </p>
          </div>

          {investmentName.investmentType === 1 ? (
            <div
              style={{
                border: "1px solid #F1F1F1",
              }}
              className="card py-4  rounded mt-10"
            >
              {/* <p
                style={{ color: "#999999" }}
                className="text-lg font-bold pl-4 sm:text-base"
              >
                {
                  investmentName.name === "MEMF"
                    ? "Meristem Equity Market Fund"
                  : "Meristem Money Market Fund"
                }
                   
              </p> */}

              <div>
                {investmentName.investmentType === 1 ?
                  <div className="px-4">
                    <table className="w-full ">
                      <tr>
                        <td className="border p-2">  Bid price: </td>
                        <td className="border p-2"> {investmentName.bidPrice} </td>
                      </tr>
                      <tr>
                        <td className="border p-2">  Offer price: </td>
                        <td className="border p-2"> {investmentName.offerPrice} </td>
                      </tr>
                      {investmentName.name === "MEMF" ?
                        (<tr>
                          <td className="border p-2">   YTD return: </td>
                          <td className="border p-2"> {investmentName.yield} </td>
                        </tr>)
                        : null
                      }
                      {investmentName.name === "MMMF" ?
                        <tr>
                          <td className="border p-2">   Effective Yield : </td>
                          <td className="border p-2"> {investmentName.yield} </td>
                        </tr>
                      : null}
                      {investmentName.name === "MMMF" ?
                        <tr>
                          <td className="border p-2">   Gross Yield : </td>
                          <td className="border p-2"> {investmentName.yield} </td>
                        </tr>
                          : null
                      }
                    </table>
                  </div>
                  : null}
              </div>
            </div>
          ) : investmentName.investmentType === 3 ? (
            <div
              style={{
                border: "1px solid #F1F1F1",
              }}
              className="card py-4  rounded mt-10"
            >
              <p
                style={{ color: "#999999" }}
                className="text-lg font-bold pl-4 sm:text-base"
              >
                Auction
              </p>
            </div>
          ) : null}
        </div>

        <div
          style={{
            border: "1px solid #F1F1F1",
          }}
          className="card p-10 sm:p-4 flex flex-col w-auto sm:w-1/2 rounded mt-10"
        >
          <p
            style={{ color: "#999999" }}
            className="text-lg font-bold sm:text-base"
          >
            Investment Details
          </p>

          {!investmentName.assetClass ? null : (
            <div className="flex flex-row mt-8 content-center justify-between items-center">
              <p className="font-bold text-black text-base sm:text-sm">
                Asset Class
              </p>
              <p className="font-hairline text-right text-black text-base sm:text-sm">
                {investmentName.assetClass}
              </p>
            </div>
          )}

          {!investmentName.currency ? null : (
            <div className="flex flex-row mt-8 content-center justify-between items-center">
              <p className="font-bold text-black text-base sm:text-sm">
                Currency
              </p>
              <p className="font-hairline text-right text-black text-base sm:text-sm">
                {investmentName.currency}
              </p>
            </div>
          )}

          {/* {!investmentName.penaltyPercentage ? null : (
            <div className="flex flex-row mt-8 content-center justify-between items-center">
              <p className="font-bold text-black text-base sm:text-sm">
                Penalty Percentage
              </p>
              <p className="font-hairline text-right text-black text-base sm:text-sm">
                {investmentName.penaltyPercentage}%
              </p>
            </div>
          )} */}

          {investmentName.investmentType === 1 || !investmentName.interestRate ? null : (
            <div className="flex flex-row mt-8 content-center justify-between items-center">
              <p className="font-bold text-black text-base sm:text-sm">
                Interest Rate
              </p>
              <p className="font-hairline text-right text-black text-base sm:text-sm">
                {investmentName.interestRate.toFixed(1)}%
              </p>
            </div>
          )}

          {!investmentName.isTerminationAllowed ? null : (
            <div className="flex flex-row mt-8 content-center justify-between items-center">
              <p className="font-bold text-black text-base sm:text-sm">
                Can be terminated
              </p>
              <p className="font-hairline text-right text-black text-base sm:text-sm">
                {investmentName.isTerminationAllowed ? "Yes" : "No"}
              </p>
            </div>
          )}

          {!investmentName.bidPrice ? null : (
            <div className="flex flex-row mt-8 content-center justify-between items-center">
              <p className="font-bold text-black text-base sm:text-sm">
                Bid Price
              </p>
              <p className="font-hairline text-right text-black text-base sm:text-sm">
                {investmentName.bidPrice.toFixed(2)}
              </p>
            </div>
          )}

          {/* {investmentName.investmentType === 1 ? (
            <div className="flex flex-row mt-8 content-center justify-between items-center">
              <p className="font-bold text-black text-base sm:text-sm">
                Date issued
              </p>
              <p className="font-hairline text-right text-black text-base sm:text-sm">
                {moment(investmentName.dateIssued).format("L")}
              </p>
            </div>
          ) : null} */}

          <div className="flex flex-row mt-8 content-center justify-between items-center">
            <p className="font-bold text-black text-base sm:text-sm">
              Minimum Duration
            </p>
            
              <p className="font-hairline text-right text-black text-base sm:text-sm">
                 {  (investmentDetails.investmentID == "51129542" ? '30 days' : investmentDetails.investmentID == "45149066" ?  '60 days' : `${lowestDuration} days`) }
              </p>
          </div>
          {investmentName.investmentType !== 1 && highestRate ? 
            <div className="flex flex-row mt-8 content-center justify-between items-center">
              <p className="font-bold text-black text-base sm:text-sm">Returns</p>
              <p className="font-hairline text-right text-black text-base sm:text-sm">
              { investmentName.investmentID == "45149066" ? `${highestRate.toFixed(2)}%` : `Up to ${highestRate.toFixed(2)}%` }
              </p>
            </div> : null
          }
          <div className="flex flex-row mt-8 content-center justify-between items-center">
            <p className="font-bold text-black text-base sm:text-sm">
              Minimun Deposit
            </p>
            <p className="font-hairline text-right text-black text-base sm:text-sm">
            { investmentName.investmentID == "45149066" ? `₦${formatCurrency("1000000")}` : investmentName.investmentID == "45149064" ? `$${formatCurrency(investmentName.minimumAmount)}` : `₦${formatCurrency(investmentName.minimumAmount)}`  }
                    
            </p>
          </div>

          <div className="justify-center flex-row flex content-center items-center">
            <Link to="/dashboard/investment/add-investment">
              <button className="mt-12 w-40  border-b text-center bg-white leading-loose border-wb-primary text-wb-primary mr-3 border wealth-buddy--cta  rounded-sm">
                Back
              </button>
            </Link>

            {investmentName.currency === "USD" ? (
              <Link
                to={{
                  pathname: "/dashboard/investment/dollar-investment",
                  investmentId: `${investmentName.investmentID}`,
                }}
              >
                <button className="mt-12 w-40  border-b text-center bg-wb-primary leading-loose border-wb-primary mr-3 border wealth-buddy--cta text-white rounded-sm">
                  Invest
                </button>
              </Link>
            ) : null}
            {investmentName.currency !== "USD" ? (
              <Link
                to={{
                  pathname: "/dashboard/investment/add-investment-form",
                  investmentId: `${investmentName.investmentID}`,
                }}
              >
                <button className="mt-12 w-40  border-b text-center bg-wb-primary leading-loose border-wb-primary mr-3 border wealth-buddy--cta text-white rounded-sm">
                  Invest
                </button>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  getAllInvestmentsData: state.investments.getAllInvestmentsData,
  investmentRateData: state.investments.investmentRateData
});

export default connect(mapStateToProps)(InvestmentInfo);
