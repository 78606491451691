import React, { Fragment } from "react";
import CloseModalIcon from "shared-components/svgs/CloseModalIcon";
import { FaUsers } from "react-icons/fa";

const NotificationUpdateDetailsModal = ({
  isVisible,
  onClose: handleClose,
  notification,
}) => {
  return (
    isVisible && (
      <div className="modal fixed inset-0 bg-wb-overlay flex justify-center items-center modal-active pb-0">
        <div className="auth-modal flex flex-col items-center bg-white fadeIn login-fieldset">
          <span className="closeModal" onClick={handleClose}>
            <CloseModalIcon />
          </span>

          <Fragment>
            <div className="flex flex-col w-full justify-start items-start">
              <h1 className="text-lg font-medium mb-2">
                {notification.heading}
              </h1>
            </div>

            <div className="w-full flex flex-col justify-center items-center">
              <div className="w-full flex flex-row items-center">
                {notification.content}
              </div>
            </div>

            <div className="flex justify-center pt-10">
              <button
                type="submit"
                className="text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm px-5"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </Fragment>
        </div>
      </div>
    )
  );
};

export default NotificationUpdateDetailsModal;
