import React, { useContext } from "react";
import InvestmentPortfolioContext from "contexts/InvestmentPortfolioContext";

const AccountIndicator = () => {
  const { portfolioName } = useContext(InvestmentPortfolioContext);
  return portfolioName ? (
    <span className="px-2 py-1 ml-5 text-xs text-black leading-3 bg-yellow-400 text-black rounded">
      Portfolio - {portfolioName}
    </span>
  ) : (
    <span className="px-2 py-1 ml-5 text-xs text-black leading-3 bg-green-200 text-black rounded">
      Primary Account
    </span>
  );
};

export default AccountIndicator;
