export const GET_DASHBOARD_DATA_START = "GET_DASHBOARD_DATA_START";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAIL = "GET_DASHBOARD_DATA_FAIL";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";

export default {
  GET_DASHBOARD_DATA_START,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL,
  GET_DASHBOARD_DATA,
};
