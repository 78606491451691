import React, { useEffect } from "react";
import { logo } from "assets/exports";
import {
  handCoin,
  handTree,
  moneyBag,
  pigCoin,
  storeCoin,
} from "../../imageLinks";
import {
  getAllInvestments,
  getInvestmentRates,
} from "state/slices/investments";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "shared-components/Loading";
import { formatCurrency } from "utils";
import AccountIndicator from "../AccountIndicator";

const AddInvestment = ({
  getAllInvestmentsData,
  getAllInvestmentsLoading,
  investmentRateData,
  investmentRateLoading,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!getAllInvestmentsData || getAllInvestmentsData.length == 0) {
      dispatch(getAllInvestments());
    }

    if (!investmentRateData || investmentRateData.length == 0) {
      dispatch(getInvestmentRates());
    }
  }, []);

  if (!getAllInvestmentsData) {
    return <div className="text-4xl text-center">No investments found</div>;
  }

  const colors = {
    51129656: "#CDEBF5",
    51129542: "#CDEBF5",
    51118581: "#FFDEAD",
    51119099: "#DCCDF5",
    45149064: "#DCCDF5",
    45149066: "#DAFF8A",
    45149062: "#A2E6E0",
    45149135: "#CDEBF5",
  };

  const icons = {
    51129656: handCoin,
    51129542: handCoin,
    51118581: storeCoin,
    51119099: handTree,
    45149064: handTree,
    45149066: moneyBag,
    45149062: pigCoin,
    45149135: handCoin,
  };

  const labelName = { //used as proxy becaue i dont want to use  backend names(label)
    51129656: "Equity Market Fund", //MERISTEM EQUITY MARKET FUND
    51129542: "Money Market Fund", //MERISTEM MONEY MARKET FUND
    51118581: "Treasury Bills (MTLIP)", //MERISTEM MTLIP
    51119099: "Treasury Bills (ASSETS)",
    45149064: "Dollar Investments (MDIP)", //MERISTEM DOLLAR INVESTMENT PORTFOLIO-MDIP(USD)
    45149066: "Ethical Earning (MEEP)", //MERISTEM ETHICAL EARNINGS PORTFOLIO-MEEP(NGN)
    45149062: "Fixed Deposits (FIXTIP)", //MERISTEM FIXTIP(NGN)
    45149135: "Reap-Arena", //REAP-OJOTA(NGN)
  };

  const assetType = {
    1: "Funds",
    2: "Fixed Deposit",
    3: "Treasury Bills",
    4: "Dollar Investment",
    6: "MEEP",
  };

  const investments = getAllInvestmentsData.filter(
    (item) =>
      item.investmentType === 1 ||
      item.investmentType === 2 ||
      item.investmentType === 3 ||
      item.investmentType === 4 ||
      item.investmentType === 6
  );

  return getAllInvestmentsLoading ? (
    <div className="flex flex-col justify-center min-screen items-center">
      <div className="flex flex-col justify-center items-center">
        <i className="w-10 mb-4" dangerouslySetInnerHTML={{ __html: logo }} />
        <Loading text="" />
      </div>
    </div>
  ) : (
    <div className="px-12 flex flex-col fadeIn mt-10">
      <div className="flex flex-row flex-wrap  sm:w-8/12 items-center  mb-10 ">
        <Link
          to="/dashboard/investment"
          className="text-xs cursor-pointer text-gray-200"
        >
          Investment
        </Link>
        <p className="text-xs mx-4 text-gray-200"> {">>"} </p>
        <span className="text-sm text-black">
          Add Investment <AccountIndicator />
        </span>
      </div>

      <div className="font-bold text-xl text-black" style={{
        marginBottom: '40px'
      }}>Add new Investment</div>
     <div className="flex-grow flex justify-center items-start">
          <div className="create-saving--overview">
            <div className="flex justify-between create-savings flex-wrap">
              {investments &&
                  investments.map((item, index) => {
                return (
                  <Link
                  key={index}
                  to={`/dashboard/investment/${item.investmentID}/investment-info`}
                  >
                  <div className="flex card flex-col sm:flex-row  bg-white " style={{
                    overflow: 'hidden'
              }}>
                <div
                  style={{
                    backgroundColor: `${
                        colors[item.investmentID] || "#DCCDF5"
                    }`,
                  }}
                  className="flex justify-center content-center py-6 px-8  items-center"
                >
                  <img
                    src={icons[item.investmentID]} 
                    width={50}
                    height={50}
                    alt=""
                  />
                </div>

                <div className="py-3 px-3 justify-between flex flex-col sm:flex-row"
                style={{
                  flexGrow: 1
                }}
                >
                  <div>
                    <p
                      style={{ width: "200px" }}
                      className="font-bold text-black text-base"
                    >
                      {/* {item.label} */}
                      {labelName[item.investmentID]} 
                    </p>
                    <p className="font-bold text-black mt-5 text-base">
                      { item.investmentID == "45149066" ? `₦${formatCurrency("1000000")}` : item.investmentID == "45149064" ? `$${formatCurrency(item.minimumAmount)}` : `₦${formatCurrency(item.minimumAmount)}`  }
                    </p>
                    <p className="text-xs ">Minimum Capital</p>
                  </div>
                  <div className="flex justify-between flex-col">
                    <div
                      style={{
                        backgroundColor: `${
                          colors[item.investmentID] || "#DCCDF5"
                        }`,
                        padding: "6px",
                        fontSize: "10px",
                        alignSelf: "flex-end",
                      }}
                      className="mt-6 mb-4  text-xs sm:mb-0 sm:mt-0 rounded-full"
                    >
                      {assetType[item.investmentType] || null}
                    </div>
                    {/* <div>
                      <p
                        style={{ color: "#6F8A15" }}
                        className="text-xs font-bold"
                      >
                        {items.interestRate.toFixed(1)}% per annum
                      </p>
                      <p className="text-base text-black text-right font-hairline">
                        Returns
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  getAllInvestmentsLoading: state.investments.getAllInvestmentsLoading,
  getAllInvestmentsData: state.investments.getAllInvestmentsData,
  investmentRateLoading: state.investments.investmentRateLoading,
  investmentRateData: state.investments.investmentRateData,
});

export default connect(mapStateToProps)(AddInvestment);
