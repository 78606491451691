import React, { useEffect, useState } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import InvestHome from "./InvestHome";
import AddInvestment from "./components/addInvestments/AddInvestment";
import InvestmentInfo from "./components/investmentInfo/InvestmentInfo";
import AddInvestmentForm from "./components/addInvestmentForm/AddInvestmentForm";
import FundInvestment from "./fundInvestment/FundInvestment";
import MutualFundRecurrentInvestment from './viewAnInvestment/component/viewCard/components/MutualFundsRecurrentInvestment'
import ViewInvestment from "./viewAnInvestment/ViewInvestment";
import FundExistingInvestment from "./viewAnInvestment/component/fundExistingInvestment/FundExistingInvestment";
import WithdrawInvestment from "./withdrawInvestment/WithdrawInvestment";
import RollOver from "./rollOverInvestment/RollOver";
import TerminateInvestment from "./terminateInvestment/TerminateInvestment";
import DollarInvestment from "./dollarInvestment/DollarInvestment";
import InvestmentPortfolioContext from "contexts/InvestmentPortfolioContext";
import { AccountsTab } from "constants/enums";
import produce from "immer";
import { getSubAccountInvestmentValuation } from "../../state/slices/investments";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

const Investment = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const subAccountInvestmentValuationInitialState = {
    portfolioHoldings: [],
    fixedDeposits: [],
    treasuryBills: [],
  };

  const [state, setState] = useState({
    activeAccountTab: AccountsTab.PRIMARY_ACCOUNT,
    portfolioId: "",
    portfolioName: "",
    isFetchingSubAccount: false,
    subAccountInvestmentValuationData: subAccountInvestmentValuationInitialState,
  });

  useEffect(() => {
    fetchSubAccountInvestmentValuation().then(undefined);
  }, [state.portfolioId]);

  const setPortfolio = (subAccount) => {
    setState(
      produce((draft) => {
        draft.portfolioId = subAccount?.portfolioName;
        draft.portfolioAltId = subAccount?.portfolioID;
        draft.portfolioName = subAccount?.name;
        if (subAccount) {
          draft.isFetchingSubAccount = true;
        }
      })
    );
  };

  const setActiveAccountTab = (value) => {
    setState(
      produce((draft) => {
        draft.activeAccountTab = value;
      })
    );
  };

  const fetchSubAccountInvestmentValuation = async () => {
    if (!state.portfolioId) {
      return false;
    }

    setState(
      produce((draft) => {
        draft.isFetchingSubAccount = true;
        draft.subAccountInvestmentValuationData = subAccountInvestmentValuationInitialState;
      })
    );

    const resultAction = await dispatch(
      getSubAccountInvestmentValuation(state.portfolioAltId)
    );

    if (getSubAccountInvestmentValuation.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);

      setState(
        produce((draft) => {
          draft.isFetchingSubAccount = false;
          draft.subAccountInvestmentValuationData = response.data;
        })
      );
    } else {
      setState(
        produce((draft) => {
          draft.isFetchingSubAccount = false;
          draft.subAccountInvestmentValuationData = subAccountInvestmentValuationInitialState;
        })
      );
    }
  };

  return (
    <InvestmentPortfolioContext.Provider
      value={{
        portfolioId: state.portfolioId,
        portfolioName: state.portfolioName,
        isFetchingSubAccount: state.isFetchingSubAccount,
        subAccountInvestmentValuationData:
          state.subAccountInvestmentValuationData,
        activeAccountTab: state.activeAccountTab,
        setPortfolio,
        setActiveAccountTab,
      }}
    >
      <Switch>
        <Route exact path={`${path}`} component={InvestHome} />
        <Route
          exact
          path={`${path}/add-investment`}
          component={AddInvestment}
        />
        <Route
          exact
          path={`${path}/:investmentId/investment-info`}
          component={InvestmentInfo}
        />
        <Route
          exact
          path={`${path}/add-investment-form`}
          component={AddInvestmentForm}
        />
        <Route
          exact
          path={`${path}/fund-investment`}
          component={FundInvestment}
        />
        <Route
          exact
          path={`${path}/fund-investment/existing`}
          component={FundExistingInvestment}
        />
        <Route
          exact
          path={`${path}/recurrent-investment`}
          component={MutualFundRecurrentInvestment}
        />
        <Route
          exact
          path={`${path}/view-investment`}
          component={ViewInvestment}
        />
        <Route
          exact
          path={`${path}/view-investment/withdraw`}
          component={WithdrawInvestment}
        />
        <Route
          exact
          path={`${path}/view-investment/roll-over`}
          component={RollOver}
        />
        <Route
          exact
          path={`${path}/view-investment/terminate`}
          component={TerminateInvestment}
        />
        <Route
          exact
          path={`${path}/dollar-investment`}
          component={DollarInvestment}
        />
      </Switch>
    </InvestmentPortfolioContext.Provider>
  );
};

export default Investment;
