export const GET_CARDS_DATA_START = "GET_CARDS_DATA_START";
export const GET_CARDS_DATA_SUCCESS = "GET_CARDS_DATA_SUCCESS";
export const GET_CARDS_DATA_FAIL = "GET_CARDS_DATA_FAIL";
export const GET_CARDS_DATA = "GET_CARDS_DATA";

export default {
  GET_CARDS_DATA_START,
  GET_CARDS_DATA_SUCCESS,
  GET_CARDS_DATA_FAIL,
  GET_CARDS_DATA,
};
