import React from "react";
import { Link } from "react-router-dom";
import ProviderCard from "./ProviderCard";

const BillProviders = ({ billers, handleTitleChange, categoryId }) => {
    return (
        <div className="p-8 py-6 grid grid-cols-2 lg:grid-cols-4 gap-8">
            {billers.data.map((biller) => (
                <Link
                    to={{
                        pathname: `/dashboard/wallet/bill-payment/${categoryId}/${biller.billerid}`,
                        state: { biller },
                    }}
                    // to={`/dashboard/wallet/bill-payment/${categoryId}/${biller.billerid}`}
                    onClick={() => handleTitleChange(biller.billername)}
                    key={biller.billername}
                >
                    <ProviderCard provider={biller} />
                </Link>
            ))}
        </div>
    );
};

export default BillProviders;
