import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";

export const useTracking = () => {
  const { listen } = useHistory();

  useEffect(() => {
    const unlisten = listen((location) => {
      ReactGA.pageview(location.pathname + location.search);
    });

    return unlisten;
  }, [listen]);
};
