import React, { useContext } from "react";
import SummaryCard from "./SummaryCard";
import InvestmentPortfolioContext from "contexts/InvestmentPortfolioContext";
import _, { forEach } from "lodash";

const dollarCurrencyRate = 415.31
const SubAccountSummaryCard = () => {
  const {
    portfolioId,
    isFetchingSubAccount,
    subAccountInvestmentValuationData,
  } = useContext(InvestmentPortfolioContext);

  // const fundReturns =
  //   subAccountInvestmentValuationData.fundValue ||
  //   subAccountInvestmentValuationData.fundValue -
  //     subAccountInvestmentValuationData.fundCost ||
  //   subAccountInvestmentValuationData.fundCost ||
  //   0;

  // const totalFixedDepositCarryingtValueUSDInvestments = _.sumBy(
  //   subAccountInvestmentValuationData.fixedDeposits,
  //   (item) => {
  //     if(item.carryingValue.currency.toString() === "USD"){
  //       return item.carryingValue.amount
  //     }
  //   }
  // );

  // const totalFixedDepositCarryingValueNGNInvestments = _.sumBy(
  //   subAccountInvestmentValuationData.fixedDeposits,
  //   (item) => {
  //     if(item.carryingValue.currency.toString() === "NGN"){
  //       return item.carryingValue.amount
  //     }
  //   }
  // );

  // const totalFixedDepositPrincipalUSDInvestments = _.sumBy(
  //   subAccountInvestmentValuationData.fixedDeposits,
  //   (item) => {
  //     if(item.carryingValue.currency.toString() === "USD"){
  //       return (item.principalBalance.amount/dollarCurrencyRate)
  //     }
  //   }
  // );
  
  // const totalFixedDepositPrincipalNGNInvestments = _.sumBy(
  //   subAccountInvestmentValuationData.fixedDeposits,
  //   (item) => {
  //     if(item.carryingValue.currency.toString() === "NGN"){
  //       return item.principalBalance.amount
  //     }
  //   }
  // );

  // const totalFixedDeposistReturnsUSDInvestments = _.sumBy(
  //   subAccountInvestmentValuationData.fixedDeposits,
  //   (item) => {
  //     if(item.carryingValue.currency.toString() === "USD"){
  //       return (item.interestLessTaxes.amount/dollarCurrencyRate)
  //     }
  //   }
  // );

  // const totalFixedDeposistReturnsNGNInvestments = _.sumBy(
  //   subAccountInvestmentValuationData.fixedDeposits,
  //   (item) => {
  //     if(item.carryingValue.currency.toString() === "NGN"){
  //       return item.interestLessTaxes.amount
  //     }else{
  //       return 0
  //     }
  //   }
  // );


  // const totalCarryingValueNGN =
  // subAccountInvestmentValuationData.fundCost + (subAccountInvestmentValuationData.fundValue - subAccountInvestmentValuationData.fundCost) +
  // totalFixedDepositCarryingValueNGNInvestments +
  // subAccountInvestmentValuationData.totalTBillDiscountedValue;

  // const totalCarryingValueUSD = totalFixedDepositCarryingtValueUSDInvestments


  // const fundReturns =
  // subAccountInvestmentValuationData.fundValue - subAccountInvestmentValuationData.fundCost;

  //   const totalCapital =
  //   subAccountInvestmentValuationData.fundCost +
  //   subAccountInvestmentValuationData.totalFixedDepositPrincipal +
  //   subAccountInvestmentValuationData.totalTBillDiscountedValue;

  // const totalCapitalNGN =
  // subAccountInvestmentValuationData.fundCost +
  //   totalFixedDepositPrincipalNGNInvestments +
  //   subAccountInvestmentValuationData.totalTBillDiscountedValue;

  //   const totalCapitalUSD=
  //   subAccountInvestmentValuationData.fundCost +
  //   totalFixedDepositPrincipalUSDInvestments +
  //   subAccountInvestmentValuationData.totalTBillDiscountedValue;

  // const totalReturns =
  //   fundReturns +
  //   totalCapital +
  //   subAccountInvestmentValuationData.totalFixedDepositIncome +
  //   subAccountInvestmentValuationData.totalTBillInterestValue;

  //   const totalReturnsNGN =
  //   fundReturns +
  //   totalFixedDeposistReturnsNGNInvestments +
  //   subAccountInvestmentValuationData.totalTBillInterestValue;

  //   const totalReturnsUSD = totalFixedDeposistReturnsUSDInvestments;


  // const totalInterests =
  //   fundReturns +
  //   subAccountInvestmentValuationData.totalFixedDepositIncome +
  //   subAccountInvestmentValuationData.totalTBillInterestValue;


  const totalFixedDepositCarryingtValueUSDInvestments = 
   _.sumBy(
    subAccountInvestmentValuationData.fixedDeposits,
    (item) => {
      if(item.carryingValue.currency.toString() === "USD"){
        return item.carryingValue.amount
      }
    }
  ) ||  0; 

  const totalFixedDepositCarryingValueNGNInvestments = 
  _.sumBy(
    subAccountInvestmentValuationData.fixedDeposits,
    (item) => {
      if(item.carryingValue.currency.toString() === "NGN"){
        return item.carryingValue.amount
      }
    }
  ) ||  0; 

  const totalFixedDepositPrincipalUSDInvestments = 
  _.sumBy(
    subAccountInvestmentValuationData.fixedDeposits,
    (item) => {
      if(item.carryingValue.currency.toString() === "USD"){
        return (item.principalBalance.amount/dollarCurrencyRate)
      }
    }
  ) || 0; 
  
  const totalFixedDepositPrincipalNGNInvestments = 
  _.sumBy(
    subAccountInvestmentValuationData.fixedDeposits,
    (item) => {
      if(item.carryingValue.currency.toString() === "NGN"){
        return item.principalBalance.amount
      }
    }
  ) || 0;

  const totalFixedDeposistReturnsUSDInvestments = 
   _.sumBy(
    subAccountInvestmentValuationData.fixedDeposits,
    (item) => {
      if(item.carryingValue.currency.toString() === "USD"){
        return (item.interestLessTaxes.amount/dollarCurrencyRate)
      }
    }
  ) || 0;

  const totalFixedDeposistReturnsNGNInvestments = 
 _.sumBy(
    subAccountInvestmentValuationData.fixedDeposits,
    (item) => {
      if(item.carryingValue.currency.toString() === "NGN"){
        return item.interestLessTaxes.amount
      }else{
        return 0
      }
    }
  ) ||  0


  const totalCarryingValueNGN = 
    subAccountInvestmentValuationData.fundCost + (subAccountInvestmentValuationData.fundValue - subAccountInvestmentValuationData.fundCost)
   + totalFixedDepositCarryingValueNGNInvestments 
   + (subAccountInvestmentValuationData.totalTBillDiscountedValue + subAccountInvestmentValuationData.totalTBillInterestValue)
   + subAccountInvestmentValuationData.equityValue
   + subAccountInvestmentValuationData.bondValue
    

  const totalCarryingValueUSD = totalFixedDepositCarryingtValueUSDInvestments;


  const fundReturns =
    subAccountInvestmentValuationData.fundValue - subAccountInvestmentValuationData.fundCost
    || 0

    const totalCapital = 
    ( subAccountInvestmentValuationData.fundCost +
        subAccountInvestmentValuationData.totalFixedDepositPrincipal +
        subAccountInvestmentValuationData.totalTBillDiscountedValue +
        subAccountInvestmentValuationData.equityCost +
        subAccountInvestmentValuationData.bondCost
    ) || 0

  const totalCapitalNGN = 
   ( subAccountInvestmentValuationData.fundCost +
    totalFixedDepositCarryingValueNGNInvestments +
    subAccountInvestmentValuationData.totalTBillDiscountedValue +
      subAccountInvestmentValuationData.equityCost
  ) || 0

    const totalCapitalUSD =  totalFixedDepositPrincipalUSDInvestments;

  const totalReturns = 
     (fundReturns + totalCapital +
      subAccountInvestmentValuationData.totalFixedDepositIncome +
      subAccountInvestmentValuationData.totalTBillInterestValue) +
      subAccountInvestmentValuationData.equityReturn +
      subAccountInvestmentValuationData.bondReturn
    || 0

    const totalReturnsNGN = 
     ( fundReturns + totalFixedDeposistReturnsNGNInvestments +
        subAccountInvestmentValuationData.totalTBillInterestValue) +
       subAccountInvestmentValuationData.equityReturn +
       subAccountInvestmentValuationData.bondReturn
    || 0

    const totalReturnsUSD = totalFixedDeposistReturnsUSDInvestments;


  const totalInterests = 
   ( fundReturns + subAccountInvestmentValuationData.totalFixedDepositIncome +
      subAccountInvestmentValuationData.totalTBillInterestValue
      + subAccountInvestmentValuationData.equityReturn
      + subAccountInvestmentValuationData.bondReturn)
  || 0

  return (
    <SummaryCard
      showBlank={isFetchingSubAccount || !portfolioId}
      totalCapital={totalCapital}
      totalCapitalNGN={totalCapitalNGN}
      totalReturnsNGN={totalReturnsNGN}
      totalCapitalUSD={totalCapitalUSD}
      totalReturnsUSD={totalReturnsUSD}
      totalReturns={totalReturns}
      totalInterests={totalInterests}
      totalCarryingValueNGN={totalCarryingValueNGN}
      totalCarryingValueUSD={totalCarryingValueUSD}
    />
  );
};

export default SubAccountSummaryCard;
