import React from "react";
import "./Toggle.scss";

const Toggle = (props) => {
  return (
    <span className="model-3">
      <div className="checkbox">
        <span
          onClick={() => props.onClick && props.onClick()}
          className={`${props.className && props.className}`}
        />
        <label></label>
      </div>
    </span>
  );
};

export default Toggle;
