import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import WalletHome from "./WalletHome";
import BillPayment from "./components/BillPayment/BillPayment";
import ProviderForm from "./components/ProviderForms/ProviderForm";
import WalletProvider from "./components/WalletProvider/WalletProvider";
import "./wallet.scss";

const Wallet = () => {
  const { path } = useRouteMatch();

  return (
    <WalletProvider>
      <Switch>
        <Route exact path={path} component={WalletHome} />
        <Route
          path={`${path}/bill-payment/:id`}
          component={BillPayment}
          exact
        />
        <Route
          path={`${path}/bill-payment/:id/:billerId`}
          component={ProviderForm}
        />
      </Switch>
    </WalletProvider>
  );
};

export default Wallet;
