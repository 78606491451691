import React, { useState, useEffect } from 'react';
import {
  smallDollar,
  withblack,
  withdram,
  terminate,
  fundInvestment,
  investBars,
} from '../../../imageLinks';
import { formatCurrency } from 'utils';
import { connect, useDispatch } from 'react-redux';
import { FaQuestionCircle } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import MutualFundsRecurrentInvestmentModal from "./components/MutualFundsRecurrentInvestment"
import { toggleRecurringFunds } from 'services/network/investment';
import { toast } from 'react-toastify';
import { unwrapResult } from "@reduxjs/toolkit";
import { Link, useHistory } from 'react-router-dom';
import {
  getAllFixedTransactions,
  getInvestmentTransactionsForFund,
  getAllTbillsTransactions,
  getInvestmentValuation,
} from "state/slices/investments";



const dollarCurrencyRate = 415.31

const ViewCard = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [recurrentModal, setRecurrentModal] = useState(false);
  const [close, setClose] = useState(false);


  const specificData = props.allPersonalInvestmentsData.filter(
    (item) => props.investmentId == item.securityId
  );

  const setInvestmentTypeOne = props.investmentValuationData.fixedDeposits.filter(
    (item) => item.instrumentId == props.investmentIdFixed
  );
  const setInvestmentTypeTwo = props.investmentValuationData.portfolioHoldings.filter(
    (item) => item.securityId == props.investmentIdFunds
  );
  const setInvestmentTypeThree = props.investmentValuationData.treasuryBills.filter(
    (item) => item.typeId == props.investmentIdTbills
  );

  let makeArray = [];
  let currencyCheck = 'NGN'

  if (setInvestmentTypeOne.length == 0 && setInvestmentTypeTwo.length == 0) {
    makeArray = setInvestmentTypeThree;
  } else if (
    setInvestmentTypeTwo.length == 0 &&
    setInvestmentTypeThree.length == 0
  ) {
    makeArray = setInvestmentTypeOne;
    currencyCheck = makeArray[0].carryingValue.currency
  } else if (
    setInvestmentTypeOne.length == 0 &&
    setInvestmentTypeThree.length == 0
  ) {
    makeArray = setInvestmentTypeTwo;
  }
  const refresh = () => {

    dispatch(getAllTbillsTransactions());
    dispatch(getInvestmentTransactionsForFund());
    dispatch(getAllFixedTransactions());
    dispatch(getInvestmentValuation());
    history.push("/dashboard/investment");
}

  const onSubmitRecurringInvestment = () => {
    // checkIfFundIsConfigureForRecurrentInvestment 
    if (setInvestmentTypeTwo[0].cardId === null
      || setInvestmentTypeTwo[0].recurringAmount === null
      || setInvestmentTypeTwo[0].schedule === null) {
      setRecurrentModal(true)
    }
    else {
      //pop up to ask if want to turn off recurrent or not
      //   const data = {
      //     "securityID":  setInvestmentTypeTwo[0].securityId,
      //     "enableReccurring": true
      //   }
      // console.log("data", data)
      //     const resultAction = await dispatch(toggleRecurringFunds(data));
      //     if (toggleRecurringFunds.fulfilled.match(resultAction)) {
      //        const response = unwrapResult(resultAction);
      //        refresh()
      //        toast.success(response.message || "Recurring Investment Enabled");
     
         
      //    } 
      refresh()
      toast.success( "Recurring Investment successfully Disabled");



    }
  }
  const onclose = () => {
    setRecurrentModal(false);
  }

  return (
    <div className="card-padding card h-auto mt-6  card-black  flex-column  text-white">
    <div className="flex flex-row items-center justify-between content-center">
      <div className="flex flex-col">
        <h5 className="text-xs mb-2 font-medium text-faded">Current Value</h5>

        <div className="text-gray-100 text-2xl font-medium pl-0">
         {
          `${currencyCheck}`
         }
        {' '}

        {/* { currencyCheck == "USD"
              ? `${formatCurrency(((makeArray[0].carryingValue.amount)).toFixed(2) ) }`
              : `${formatCurrency(
              setInvestmentTypeOne.length === 0 && setInvestmentTypeTwo.length === 0
                ? ( makeArray[0].faceValue.amount + makeArray[0].interestAccrued.amount).toFixed(2)
                : setInvestmentTypeTwo.length === 0 && setInvestmentTypeThree.length === 0
                ?  (makeArray[0].carryingValue.amount).toFixed(2)
                : makeArray[0].totalPurchaseCost.toFixed(2)
            )}`
          
          }  */}
         {`${formatCurrency(
              setInvestmentTypeOne.length == 0 && setInvestmentTypeTwo.length == 0
                ? (makeArray[0].faceValue.amount + makeArray[0].interestAccrued.amount).toFixed(2)
                : setInvestmentTypeTwo.length == 0 && setInvestmentTypeThree.length == 0
                ? ( makeArray[0].carryingValue.amount).toFixed(2)
                : ( makeArray[0].totalGainLoss + makeArray[0].totalPurchaseCost).toFixed(2)
            )}`}
        </div>
      </div>
        {
          setInvestmentTypeTwo.length === 0 ? null
            : <div className="flex flex-row" >
              <span className='cursor-pointer' onClick={onSubmitRecurringInvestment}> {setInvestmentTypeTwo[0].isRecurrent ? "Automate investments" : "Automate investments"}</span>&nbsp;
              <span data-tip="This is recurring investment, you can toggle it on/off" > <FaQuestionCircle /></span>
            </div> 
        }
    </div>
    <div className="flex flex-summary py-4 mt-3 justify-between items-center content-centers border-solid border-b divider-border">
      <div className="text-left">
        <h5 className="text-xs mb-2 font-medium text-faded">
        Principal
        </h5>
        <p className="text-gray-100 text-2xl font-medium text-left">

          {
          `${currencyCheck}`
         }
            {' '}
             { currencyCheck == "USD"
              ? `${formatCurrency(((makeArray[0].principalBalance.amount)/dollarCurrencyRate).toFixed(2) ) }`
              : `${formatCurrency(
              setInvestmentTypeOne.length === 0 && setInvestmentTypeTwo.length === 0
                ? makeArray[0].faceValue.amount.toFixed(2)
                : setInvestmentTypeTwo.length === 0 && setInvestmentTypeThree.length === 0
                ?  (makeArray[0].principalBalance.amount).toFixed(2)
                : makeArray[0].totalPurchaseCost.toFixed(2)
            )}`
          
          } 
        </p>
      </div>

      <div style={{ width: 1 }} className="h-20 bg-gray-900" />

      <div className="text-left card-margin--y ml-2 sm:ml-0 sm:pr-6">
        <h5 className="text-xs mb-2 font-medium text-faded">Accrued Interests</h5>
        <p className="ext-gray-100 text-2xl font-medium">
         {
          `${currencyCheck}`
         }
        {' '}
        { currencyCheck  == "USD"
          ? `${formatCurrency(
            setInvestmentTypeOne.length == 0 &&
              setInvestmentTypeTwo.length == 0
              ? (makeArray[0].interestAccrued.amount).toFixed(2)
              : setInvestmentTypeTwo.length == 0 &&
                setInvestmentTypeThree.length == 0
              ? ((makeArray[0].interestLessTaxes.amount) /dollarCurrencyRate).toFixed(2)
              : (makeArray[0].totalGainLoss).toFixed(2)
          )} `
          : `${formatCurrency(
            setInvestmentTypeOne.length == 0 &&
              setInvestmentTypeTwo.length == 0
              ? (makeArray[0].interestAccrued.amount).toFixed(2)
              : setInvestmentTypeTwo.length == 0 &&
                setInvestmentTypeThree.length == 0
              ? (makeArray[0].interestLessTaxes.amount).toFixed(2)
              : (makeArray[0].totalGainLoss).toFixed(2)
          )} `
        }
            
            </p>
      </div>
    </div>


      <div className="flex flex-summary flex-row sm:flex-row justify-between items-center content-center pt-6">
        {setInvestmentTypeOne.length == 0 &&
        setInvestmentTypeThree.length == 0 ? (
          <Link
            to={{
              pathname: 'fund-investment/existing',
              // investmentId: `${specificData[0].securityId}`,
              investmentId: `${
                setInvestmentTypeOne.length == 0 &&
                setInvestmentTypeTwo.length == 0
                  ? makeArray[0].id
                  : setInvestmentTypeTwo.length == 0 &&
                    setInvestmentTypeThree.length == 0
                  ? makeArray[0].instrumentId
                  : makeArray[0].securityId
              }`,
            }}
            className="pl-2 flex items-center content-center"
          >
            <img src={fundInvestment} alt="wealth-buddy" className="sm:pr-3" />
            <p className="text-white text-base ml-3 self-center">
              Fund Investment
            </p>
          </Link>
        ) : null}

        {setInvestmentTypeOne.length == 0 &&
        setInvestmentTypeThree.length == 0 ? (
          <Link
            to={{
              pathname: '/dashboard/investment/view-investment/withdraw',
              // investmentId: `${specificData[0].securityId}`,
              investmentId: `${
                setInvestmentTypeOne.length == 0 &&
                setInvestmentTypeTwo.length == 0
                  ? makeArray[0].id
                  : setInvestmentTypeTwo.length == 0 &&
                    setInvestmentTypeThree.length == 0
                  ? makeArray[0].instrumentId
                  : makeArray[0].securityId
              }`,
            }}
            className="pl-6 flex flex-wrap  sm:mt-0 relative ml-2 sm:ml-0 items-center content-center pr-5"
          >
            <img src={withblack} alt="wealth-buddy" className="sm:pr-3" />
            <p className="text-white text-base self-center ">Withdraw</p>
          </Link>
        ) : null}

        {setInvestmentTypeOne.length == 0 &&
        setInvestmentTypeThree.length == 0 ? null : (
          <Link
            to={{
              pathname: '/dashboard/investment/view-investment/terminate',
              // investmentId: `${specificData[0].securityId}`,
              investmentId: `${
                setInvestmentTypeOne.length == 0 &&
                setInvestmentTypeTwo.length == 0
                  ? makeArray[0].id
                  : setInvestmentTypeTwo.length == 0 &&
                    setInvestmentTypeThree.length == 0
                  ? makeArray[0].instrumentId
                  : makeArray[0].securityId
              }`,
            }}
            className="pl-6 flex mt-6 sm:mt-0 relative ml-2 sm:ml-0 items-center content-center pr-5"
          >
            <img src={terminate} alt="wealth-buddy" className="pr-3" />
            <p className="text-white text-base self-center">Terminate</p>
          </Link>
        )}
      </div>
      <ReactTooltip effect="solid" multiline={true} />

      {recurrentModal ? (
        <MutualFundsRecurrentInvestmentModal payload={setInvestmentTypeTwo[0] } close={onclose}
        />
      ) : null}

    </div>
  );
};

const mapStateToProps = (state) => ({
  // ALL PERSONAL INVESTMENT DATA
  allPersonalInvestmentsData: state.investments.allPersonalInvestmentsData,
  allPersonalInvestmentsLoading:
    state.investments.allPersonalInvestmentsLoading,
  allPersonalInvestmentsError: state.investments.allPersonalInvestmentsError,
  investmentValuationData: state.investments.investmentValuationData,
  investmentValuationLoading: state.investments.investmentValuationLoading,
});

export default connect(mapStateToProps)(ViewCard);
