import React from "react";
import SuccessModal from "shared-components/modals/SuccessModal";
import BankIconSuccess from "assets/img/bankIconSuccess.png";

const AddSubAccountSuccessModal = ({ isVisible, onClose }) =>
  isVisible && (
    <SuccessModal
      title="Success"
      subtitle="Your sub-account has been successfully created"
      icon={BankIconSuccess}
      buttonTitle="Done"
      closeModal={onClose}
    />
  );

export default AddSubAccountSuccessModal;
