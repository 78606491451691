import React, { useEffect } from "react";
import { Provider as StoreProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "state/store";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./styles/index.css";
import AuthRoutes from "routes/AuthRoutes";
import DashboardRoutes from "routes/DashboardRoutes";
import PrivateRoute from "shared-components/PrivateRoute";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";
import ReactNotification from "react-notifications-component";
import ReactGA from "react-ga";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const App = () => {
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    let Tawk_API = window.Tawk_API || {};
    const s1 = document.createElement("script");

    s1.async = false;
    s1.src = `https://embed.tawk.to/${process.env.REACT_APP_TAWKTO_ID}/default`;
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");

    const s0 = document.getElementsByTagName("script")[0];
    s0.parentNode.insertBefore(s1, s0);

    s1.onload = () => {
      Tawk_API = window.Tawk_API;
    };

    return () => {
      document.body.removeChild(s1);
    };
  }, []);

  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <main>
            <ReactNotification />
            <Switch>
              <Route path="/auth" component={AuthRoutes} />
              <PrivateRoute path="/dashboard" component={DashboardRoutes} />
              <Redirect exact from="/" to="/dashboard" />
            </Switch>
            <ToastContainer
              position="top-right"
              theme="dark"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
            />
          </main>
        </BrowserRouter>
      </PersistGate>
    </StoreProvider>
  );
};

export default App;
