import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  PersonalTargetSavings,
  FixedFlexibleSavings,
  FixedLockSavings,
  Savings,
  GroupTargetSavings,
  GroupChallengeSavings,
  GroupContributorySavings,
  MkatSavings
} from "services/network";
import { getDashboardData } from "../ducks/dashboard/actions";
import { getRecentSavingTransactionsData } from "../ducks/recentSavingTransactions/actions";
import { getCustomerSavingsData } from "../ducks/customerSavings/actions";
import { SavingsType, GroupSavingsType } from "constants/enums";

const initialState = {
  createPersonalTargetSavingsLoading: false,
  createPersonalTargetSavingsError: null,
  createPersonalTargetSavingsEntities: [],
  createMkatSavingsLoading: false,
  createMkatSavingsError: null,
  createPMkatSavingsEntities: [],
  createGroupTargetSavingsLoading: false,
  createGroupTargetSavingsError: null,
  createGroupTargetSavingsEntities: [],
  fetchByIdLoading: false,
  fetchByIdError: null,
  fetchByIdEntities: [],
  startCancelLoading: false,
  startCancelError: null,
  startCancelEntities: [],
  completeCancelLoading: false,
  completeCancelError: null,
  completeCancelEntities: [],
  pausePersonalTargetSavingLoading: false,
  pausePersonalTargetSavingError: null,
  pausePersonalTargetSavingEntities: [],
  resumePersonalTargetSavingLoading: false,
  resumePersonalTargetSavingError: null,
  resumePersonalTargetSavingEntities: [],
  pauseGroupTargetSavingLoading: false,
  pauseGroupTargetSavingError: null,
  pauseGroupTargetSavingEntities: [],
  resumeGroupTargetSavingLoading: false,
  resumeGroupTargetSavingError: null,
  resumeGroupTargetSavingEntities: [],
  startWithdrawLoading: false,
  startWithdrawError: null,
  startWithdrawEntities: [],
  completeWithdrawLoading: false,
  completeWithdrawError: null,
  completeWithdrawEntities: [],
  startTopUpLoading: false,
  startTopUpError: null,
  startTopUpEntities: [],
  pendingInvitationsLoading: false,
  pendingInvitationsError: null,
  pendingInvitationsEntities: [],
  savingsStatisticsEntities: {},
  changeCardOnRunningSavingLoading: false,
  changeCardOnRunningSavingError: null,
  changeCardOnRunningSavingEntities: [],
  
};

export const createMkatSavings = createAsyncThunk(
  "savings/createMkatSavings",
  async (payload, thunkAPI) => {
    const response = await MkatSavings.mkatSavings(payload)

    thunkAPI.dispatch(getDashboardData());
    thunkAPI.dispatch(getCustomerSavingsData());
    thunkAPI.dispatch(getRecentSavingTransactionsData());

    return response.data.data;
  }
);

export const createPersonalTargetSavings = createAsyncThunk(
  "savings/createPersonalTargetSavings",
  async (payload, thunkAPI) => {
    const response = await PersonalTargetSavings.createPersonalTargetSavings(
      payload
    );

    thunkAPI.dispatch(getDashboardData());
    thunkAPI.dispatch(getCustomerSavingsData());
    thunkAPI.dispatch(getRecentSavingTransactionsData());

    return response.data.data;
  }
);

export const createFixedLockSavings = createAsyncThunk(
  "savings/createFixedLockSavings",
  async (payload, thunkAPI) => {
    const response = await FixedLockSavings.createFixedLock(payload);

    thunkAPI.dispatch(getDashboardData());
    thunkAPI.dispatch(getCustomerSavingsData());
    thunkAPI.dispatch(getRecentSavingTransactionsData());

    return response.data.data;
  }
);

export const createFixedFlexibleSavings = createAsyncThunk(
  "savings/createFixedFlexibleSavings",
  async (payload, thunkAPI) => {
    const response = await FixedFlexibleSavings.createFixedFlexible(payload);

    thunkAPI.dispatch(getDashboardData());
    thunkAPI.dispatch(getCustomerSavingsData());
    thunkAPI.dispatch(getRecentSavingTransactionsData());

    return response.data.data;
  }
);

export const createGroupTargetSavings = createAsyncThunk(
  "savings/createGroupTargetSavings",
  async (payload, thunkAPI) => {
    const response = await GroupTargetSavings.createGroupTargetSavings(payload);

    thunkAPI.dispatch(getDashboardData());
    thunkAPI.dispatch(getCustomerSavingsData());
    thunkAPI.dispatch(getRecentSavingTransactionsData());

    return response.data.data;
  }
);

export const createGroupChallengeSavings = createAsyncThunk(
  "savings/createGroupChallengeSavings",
  async (payload, thunkAPI) => {
    const response = await GroupChallengeSavings.createGroupChallengeSavings(
      payload
    );

    thunkAPI.dispatch(getDashboardData());
    thunkAPI.dispatch(getCustomerSavingsData());
    thunkAPI.dispatch(getRecentSavingTransactionsData());

    return response.data.data;
  }
);

export const createGroupContributorySavings = createAsyncThunk(
  "savings/createGroupContributorySavings",
  async (payload, thunkAPI) => {
    const response = await GroupContributorySavings.createGroupContributorySavings(
      payload
    );

    thunkAPI.dispatch(getDashboardData());
    thunkAPI.dispatch(getCustomerSavingsData());
    thunkAPI.dispatch(getRecentSavingTransactionsData());

    return response.data.data;
  }
);

export const fetchSavingsById = createAsyncThunk(
  "savings/fetchSavingsById",
  async (savingsId) => {
    const response = await Savings.getAllSavings(savingsId);
    return response.data.data;
  }
);

export const fetchGroupChallengeSavingsById = createAsyncThunk(
  "savings/fetchGroupChallengeSavingsById",
  async (savingsId) => {
    const response = await GroupChallengeSavings.fetchGroupChallengeSavingsById(
      savingsId
    );
    return response.data.data;
  }
);

export const fetchGroupSavingsById = createAsyncThunk(
  "savings/fetchGroupSavingsById",
  async ({ savingsID, savingsType }, thunkAPI) => {
    const request = {
      [SavingsType.GroupTargetSavings]:
        GroupTargetSavings.fetchGroupTargetSavingsById,
      [SavingsType.GroupChallengeSavings]:
        GroupChallengeSavings.fetchGroupChallengeSavingsById,
      [SavingsType.GroupContributorySavings]:
        GroupContributorySavings.fetchGroupContributorySavingsById,
    };
    const response = await request[savingsType](savingsID);
    return response.data.data;
  }
);

export const startGroupSavings = createAsyncThunk(
  "savings/startGroupSavings",
  async ({ savingsID, savingsType }, thunkAPI) => {
    const request = {
      [SavingsType.GroupTargetSavings]:
        GroupTargetSavings.startGroupTargetSavings,
      [SavingsType.GroupChallengeSavings]:
        GroupChallengeSavings.startGroupChallengeSavings,
      [SavingsType.GroupContributorySavings]:
        GroupContributorySavings.startGroupContributorySavings,
    };
    const response = await request[savingsType]({ savingsID });

    thunkAPI.dispatch(getDashboardData());
    thunkAPI.dispatch(getCustomerSavingsData());
    thunkAPI.dispatch(getRecentSavingTransactionsData());

    return response.data.data;
  }
);

export const startCancelSavings = createAsyncThunk(
  "savings/startCancel",
  async ({ savingsID, savingsType }) => {
    const request = {
      [SavingsType.PersonalTargetSavings]:
        PersonalTargetSavings.startCancelPersonalTargetSavings,
      [SavingsType.FixedLockSavings]:
        FixedLockSavings.startCancelFixedLockSavings,
      [SavingsType.FixedFlexibleSavings]:
        FixedFlexibleSavings.startCancelFixedFlexibleSavings,
      [SavingsType.MKATSavings]:
        MkatSavings.startCancelSavings,
      [SavingsType.MFATSavings]:
        MkatSavings.startCancelSavings,
    };
    const response = await request[savingsType](savingsID);
    return response.data.data;
  }
);

export const completeCancelSavings = createAsyncThunk(
  "savings/completeCancel",
  async ({ savingsID, savingsType }, thunkAPI) => {
    const request = {
      [SavingsType.PersonalTargetSavings]:
        PersonalTargetSavings.completeCancelPersonalTargetSavings,
      [SavingsType.FixedLockSavings]:
        FixedLockSavings.completeCancelFixedLockSavings,
      [SavingsType.FixedFlexibleSavings]:
        FixedFlexibleSavings.completeCancelFixedFlexibleSavings,
      [SavingsType.MKATSavings]:
        MkatSavings.completeCancelSavings,
      [SavingsType.MFATSavings]:
        MkatSavings.completeCancelSavings,
    };
    const response = await request[savingsType](savingsID);
    return response.data.data;
  }
);

export const startWithdrawSavings = createAsyncThunk(
  "savings/startWithdraw",
  async ({ savingsType, formValues }) => {
    const request = {
      [SavingsType.PersonalTargetSavings]:
        PersonalTargetSavings.startPersonalTargetWithdrawal,
      [SavingsType.FixedLockSavings]: FixedLockSavings.startFixedLockWithdraw,
      [SavingsType.FixedFlexibleSavings]:
        FixedFlexibleSavings.startFixedFlexibleWithdraw,
      [SavingsType.GroupTargetSavings]:
        GroupTargetSavings.startGroupTargetWithdraw,
      [SavingsType.GroupChallengeSavings]:
        GroupChallengeSavings.startGroupChallengeWithdraw,
      [SavingsType.MKATSavings]:
        MkatSavings.startSavingsWithdrawal,
      [SavingsType.MFATSavings]:
        MkatSavings.startSavingsWithdrawal,
    };
    const response = await request[savingsType](formValues);
    return response.data.data;
  }
);

export const completeWithdrawSavings = createAsyncThunk(
  "savings/completeWithdraw",
  async ({ savingsType, formValues }, thunkAPI) => {
    const request = {
      [SavingsType.PersonalTargetSavings]:
        PersonalTargetSavings.completePersonalTargetWithdrawal,
      [SavingsType.FixedLockSavings]:
        FixedLockSavings.completeFixedLockWithdraw,
      [SavingsType.FixedFlexibleSavings]:
        FixedFlexibleSavings.completeFixedFlexibleWithdraw,
      [SavingsType.GroupTargetSavings]:
        GroupTargetSavings.completeGroupTargetWithdraw,
      [SavingsType.GroupChallengeSavings]:
        GroupChallengeSavings.completeGroupChallengeWithdraw,
      [SavingsType.MKATSavings]:
        MkatSavings.completeCancelSavings,
      [SavingsType.MFATSavings]:
        MkatSavings.completeCancelSavings,
      
    };
    const response = await request[savingsType](formValues);
    thunkAPI.dispatch(getDashboardData());
    return response.data.data;
  }
);

export const treatGroupSavingsInvitation = createAsyncThunk(
  "savings/treatGroupSavingsInvitation",
  async ({ formValues, groupSavingsType }, thunkAPI) => {
    const request = {
      [GroupSavingsType.GroupTargetSavings]:
        GroupTargetSavings.treatGroupTargetInvitation,
      [GroupSavingsType.GroupChallengeSavings]:
        GroupChallengeSavings.treatGroupChallengeInvitation,
      [GroupSavingsType.GroupContributorySavings]:
        GroupContributorySavings.treatGroupContributoryInvitation,
    };
    const response = await request[groupSavingsType](formValues);

    thunkAPI.dispatch(getDashboardData());
    thunkAPI.dispatch(getCustomerSavingsData());
    thunkAPI.dispatch(getRecentSavingTransactionsData());
    thunkAPI.dispatch(getPendingSavingsInvitations());

    return response.data.data;
  }
);

export const getPendingSavingsInvitations = createAsyncThunk(
  "savings/getPendingInvitations",
  async () => {
    const response = await Savings.getPendingSavingsInvitations();
    return response.data.data;
  }
);

export const cancelGroupSavings = createAsyncThunk(
  "savings/cancelGroupSavings",
  async ({ savingsID, savingsType }, thunkAPI) => {
    const request = {
      [SavingsType.GroupTargetSavings]:
        GroupTargetSavings.cancelGroupTargetSavings,
      [SavingsType.GroupChallengeSavings]:
        GroupChallengeSavings.cancelGroupChallengeSavings,
      [SavingsType.GroupContributorySavings]:
        GroupContributorySavings.cancelGroupContributorySavings,
    };
    const response = await request[savingsType](savingsID);
    return response.data.data;
  }
);

export const pauseGroupTargetSavings = createAsyncThunk(
  "savings/pauseGroupTargetSavings",
  async ({ savingsID }, thunkAPI) => {
    const response = await GroupTargetSavings.pauseGroupTargetSavings(
      savingsID
    );

    thunkAPI.dispatch(getDashboardData());
    thunkAPI.dispatch(getCustomerSavingsData());
    thunkAPI.dispatch(getRecentSavingTransactionsData());

    return response.data.data;
  }
);

export const resumeGroupTargetSavings = createAsyncThunk(
  "savings/resumeGroupTargetSavings",
  async ({ savingsID }, thunkAPI) => {
    const response = await GroupTargetSavings.resumeGroupTargetSavings(
      savingsID
    );

    thunkAPI.dispatch(getDashboardData());
    thunkAPI.dispatch(getCustomerSavingsData());
    thunkAPI.dispatch(getRecentSavingTransactionsData());

    return response.data.data;
  }
);

export const pausePersonalTargetSavings = createAsyncThunk(
  "savings/pausePersonalTargetSavings",
  async ({ savingsID }, thunkAPI) => {
    const response = await PersonalTargetSavings.pausePersonalTargetSavings(
      savingsID
    );

    thunkAPI.dispatch(getDashboardData());
    thunkAPI.dispatch(getCustomerSavingsData());
    thunkAPI.dispatch(getRecentSavingTransactionsData());

    return response.data.data;
  }
);

export const resumePersonalTargetSavings = createAsyncThunk(
  "savings/resumePersonalTargetSavings",
  async ({ savingsID }, thunkAPI) => {
    const response = await PersonalTargetSavings.resumePersonalTargetSavings(
      savingsID
    );

    thunkAPI.dispatch(getDashboardData());
    thunkAPI.dispatch(getCustomerSavingsData());
    thunkAPI.dispatch(getRecentSavingTransactionsData());

    return response.data.data;
  }
);

export const startTopUpPersonalTargetSavings = createAsyncThunk(
  "savings/startTopUpPersonalTargetSavings",
  async (payload, thunkAPI) => {
    const response = await PersonalTargetSavings.startTopUpPersonalTargetSavings(
      payload
    );

    thunkAPI.dispatch(getDashboardData());
    thunkAPI.dispatch(getCustomerSavingsData());
    thunkAPI.dispatch(getRecentSavingTransactionsData());

    return response.data.data;
  }
);

export const getSavingsStatistics = createAsyncThunk(
  "savings/getSavingsStatistics",
  async () => {
    const response = await Savings.getSavingsStatistics();
    return response.data;
  }
);

export const changeCardOnRunningSavings = createAsyncThunk(
  "savings/changeCardOnRunningSavings",
  async (payload) => {
    const response = await Savings.changeCardOnRunningSavings(payload);
    return response.data.data;
  }
);

const savings = createSlice({
  name: "savings",
  initialState,
  reducers: {
    setPendingSavingsInvitations: (state, action) => {
      state.pendingInvitationsEntities = action.payload;
    },
  },
  extraReducers: {
    [fetchSavingsById.pending]: (state) => {
      state.fetchByIdLoading = true;
      state.fetchByIdError = null;
    },
    [fetchSavingsById.fulfilled]: (state, action) => {
      state.fetchByIdEntities = action.payload;
      state.fetchByIdLoading = false;
      state.fetchByIdError = null;
    },
    [fetchSavingsById.rejected]: (state, action) => {
      state.fetchByIdEntities = null;
      state.fetchByIdLoading = false;
      state.fetchByIdError = action.error;
    },
    [startCancelSavings.pending]: (state) => {
      state.startCancelLoading = true;
      state.startCancelError = null;
    },
    [startCancelSavings.fulfilled]: (state, action) => {
      state.startCancelEntities = action.payload;
      state.startCancelLoading = false;
      state.startCancelError = null;
    },
    [startCancelSavings.rejected]: (state, action) => {
      state.startCancelEntities = null;
      state.startCancelLoading = false;
      state.startCancelError = action.error;
    },
    [completeCancelSavings.pending]: (state) => {
      state.completeCancelLoading = true;
      state.completeCancelError = null;
    },
    [completeCancelSavings.fulfilled]: (state, action) => {
      state.completeCancelEntities = action.payload;
      state.completeCancelLoading = false;
      state.completeCancelError = null;
    },
    [completeCancelSavings.rejected]: (state, action) => {
      state.completeCancelEntities = null;
      state.completeCancelLoading = false;
      state.completeCancelError = action.error;
    },
    [pauseGroupTargetSavings.pending]: (state) => {
      state.pauseGroupTargetSavingLoading = true;
      state.pauseGroupTargetSavingError = null;
    },
    [pauseGroupTargetSavings.fulfilled]: (state, action) => {
      state.pauseGroupTargetSavingEntities = action.payload;
      state.pauseGroupTargetSavingLoading = false;
      state.pauseGroupTargetSavingError = null;
    },
    [pauseGroupTargetSavings.rejected]: (state, action) => {
      state.pauseGroupTargetSavingEntities = null;
      state.pauseGroupTargetSavingLoading = false;
      state.pauseGroupTargetSavingError = action.error;
    },
    [resumeGroupTargetSavings.pending]: (state) => {
      state.resumeGroupTargetSavingLoading = true;
      state.resumeGroupTargetSavingError = null;
    },
    [resumeGroupTargetSavings.fulfilled]: (state, action) => {
      state.resumeGroupTargetSavingEntities = action.payload;
      state.resumeGroupTargetSavingLoading = false;
      state.resumeGroupTargetSavingError = null;
    },
    [resumeGroupTargetSavings.rejected]: (state, action) => {
      state.resumeGroupTargetSavingEntities = null;
      state.resumeGroupTargetSavingLoading = false;
      state.resumeGroupTargetSavingError = action.error;
    },
    [pausePersonalTargetSavings.pending]: (state) => {
      state.pausePersonalTargetSavingLoading = true;
      state.pausePersonalTargetSavingError = null;
    },
    [pausePersonalTargetSavings.fulfilled]: (state, action) => {
      state.pausePersonalTargetSavingEntities = action.payload;
      state.pausePersonalTargetSavingLoading = false;
      state.pausePersonalTargetSavingError = null;
    },
    [pausePersonalTargetSavings.rejected]: (state, action) => {
      state.pausePersonalTargetSavingEntities = null;
      state.pausePersonalTargetSavingLoading = false;
      state.pausePersonalTargetSavingError = action.error;
    },
    [resumePersonalTargetSavings.pending]: (state) => {
      state.resumePersonalTargetSavingLoading = true;
      state.resumePersonalTargetSavingError = null;
    },
    [resumePersonalTargetSavings.fulfilled]: (state, action) => {
      state.resumePersonalTargetSavingEntities = action.payload;
      state.resumePersonalTargetSavingLoading = false;
      state.resumePersonalTargetSavingError = null;
    },
    [resumePersonalTargetSavings.rejected]: (state, action) => {
      state.resumePersonalTargetSavingEntities = null;
      state.resumePersonalTargetSavingLoading = false;
      state.resumePersonalTargetSavingError = action.error;
    },
    [startTopUpPersonalTargetSavings.pending]: (state) => {
      state.startTopUpLoading = true;
      state.startTopUpError = null;
    },
    [startTopUpPersonalTargetSavings.fulfilled]: (state, action) => {
      state.startTopUpEntities = action.payload;
      state.startTopUpLoading = false;
      state.startTopUpError = null;
    },
    [startTopUpPersonalTargetSavings.rejected]: (state, action) => {
      state.startTopUpEntities = null;
      state.startTopUpLoading = false;
      state.startTopUpError = action.error.message;
    },
    [startWithdrawSavings.pending]: (state) => {
      state.startWithdrawLoading = true;
      state.startWithdrawError = null;
    },
    [startWithdrawSavings.fulfilled]: (state, action) => {
      state.startWithdrawEntities = action.payload;
      state.startWithdrawLoading = false;
      state.startWithdrawError = null;
    },
    [startWithdrawSavings.rejected]: (state, action) => {
      state.startWithdrawEntities = null;
      state.startWithdrawLoading = false;
      state.startWithdrawError = action.error.message;
    },
    [completeWithdrawSavings.pending]: (state) => {
      state.completeWithdrawLoading = true;
      state.completeWithdrawError = null;
    },
    [completeWithdrawSavings.fulfilled]: (state, action) => {
      state.completeWithdrawEntities = action.payload;
      state.completeWithdrawLoading = false;
      state.completeWithdrawError = null;
    },
    [completeWithdrawSavings.rejected]: (state, action) => {
      state.completeWithdrawEntities = null;
      state.completeWithdrawLoading = false;
      state.completeWithdrawError = action.error.message;
    },
    [getPendingSavingsInvitations.pending]: (state) => {
      state.pendingInvitationsLoading = true;
      state.pendingInvitationsError = null;
    },
    [getPendingSavingsInvitations.fulfilled]: (state, action) => {
      state.pendingInvitationsEntities = action.payload;
      state.pendingInvitationsLoading = false;
      state.pendingInvitationsError = null;
    },
    [getPendingSavingsInvitations.rejected]: (state, action) => {
      state.pendingInvitationsEntities = null;
      state.pendingInvitationsLoading = false;
      state.pendingInvitationsError = action.error;
    },
    [getSavingsStatistics.fulfilled]: (state, action) => {
      state.savingsStatisticsEntities = action.payload;
    },
    [changeCardOnRunningSavings.pending]: (state) => {
      state.changeCardOnRunningSavingLoading = true;
      state.changeCardOnRunningSavingError = null;
    },
    [changeCardOnRunningSavings.fulfilled]: (state, action) => {
      state.changeCardOnRunningSavingEntities = action.payload;
      state.changeCardOnRunningSavingLoading = false;
      state.changeCardOnRunningSavingError = null;
    },
    [changeCardOnRunningSavings.rejected]: (state, action) => {
      state.changeCardOnRunningSavingEntities = null;
      state.changeCardOnRunningSavingLoading = false;
      state.changeCardOnRunningSavingError = action.error;
    },
  },
});

export const { setPendingSavingsInvitations } = savings.actions;

export default savings.reducer;
