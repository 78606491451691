import React from "react";

const NavShape = (props) => {
  return (
    <svg width={306} height={819} viewBox="0 0 306 819" fill="none" {...props}>
      <g opacity={0.7}>
        <mask
          id="prefix__a"
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={306}
          height={819}
        >
          <path fill="#99BF18" d="M0 0h306v819H0z" />
        </mask>
        <g mask="url(#prefix__a)" fill="#fff" fillOpacity={0.21}>
          <path d="M-26 855c96.228-75.898 160.129-176.174 211-286l-27.816 37.698c-29.069 39.205-57.636 78.662-87.958 116.611C38.904 761.007 8.08 798.453-17.73 839.921c-3.008 4.775-6.516 9.55-8.27 15.079zM.17 787.083c33.253-47.206 63.46-96.442 85.8-149.992.76-1.777 1.269-3.553 2.03-5.33-.508-.253-.761-.507-1.27-.761-11.676 15.735-23.607 31.47-34.776 47.459-28.43 40.607-60.162 78.169-89.862 117.507-2.03 2.791-4.061 5.329-6.092 7.867L-20.9 815A1148.578 1148.578 0 00.17 787.083zM199.827 666.157c-3.274-2.498-4.534-.5-6.8 1.748-28.963 29.724-57.422 59.447-86.888 88.672-23.673 23.479-50.37 43.961-75.554 65.941-4.03 3.497-7.807 6.744-11.585 10.241L40.407 843l.756-.749c6.044-5.745 12.844-10.741 19.14-16.236 34-29.474 65.229-61.945 94.443-95.915 13.851-16.236 27.451-32.471 40.547-49.456 2.015-2.498 3.526-5.246 5.289-7.743 1.763-2.248 2.267-4.496-.755-6.744z" />
        </g>
      </g>
    </svg>
  );
};

export default NavShape;
