import React, { useContext } from "react";
import { PlusIcon } from "../../imageLinks";
import { Link } from "react-router-dom";
import InvestmentCard from "../InvestmentCard";
import { MdTabUnselected, MdFolder } from "react-icons/md";
import InvestmentPortfolioContext from "contexts/InvestmentPortfolioContext";
import Loading from "shared-components/Loading";

const SubAccountInvestments = () => {
  const {
    portfolioId,
    isFetchingSubAccount,
    subAccountInvestmentValuationData,
  } = useContext(InvestmentPortfolioContext);
  const investments = [
    ...subAccountInvestmentValuationData.portfolioHoldings.map(
      (investment) => ({
        navigationPayload: {
          investmentId: investment.securityId,
        },
        companyName: investment.companyName,
        currentValue: investment.currentValue,
        currency: 'NGN',
        portfolioPercentage: investment.portPercentage,
      })
    ),
    ...subAccountInvestmentValuationData.fixedDeposits.map((investment) => ({
      navigationPayload: {
        investmentId: investment.typeId,
        fixedId: investment.instrumentId,
      },
      companyName: investment.productLabel,
      currentValue: investment.carryingValue.amount,
      currency: investment.carryingValue.currency,
      portfolioPercentage: investment.interestRate,
    })),
    ...subAccountInvestmentValuationData.treasuryBills.map((investment) => ({
      navigationPayload: {
        investmentId: investment.typeId,
        tBillId: investment.id,
      },
      companyName: investment.typeLabel,
      currentValue: investment.valueAsAtDate.amount,
      currency: 'NGN',
      portfolioPercentage: investment.interestRate,
    })),
  ];

  return isFetchingSubAccount ? (
    <div className="flex flex-col justify-center items-center h-48">
      <MdFolder size={40} className="text-gray-200" />
      <span className="text-gray-200 pt-2">Loading sub-accounts...</span>
      <Loading text="" />
    </div>
  ) : !portfolioId ? (
    <div className="flex flex-col justify-center items-center h-48">
      <MdTabUnselected size={40} className="text-gray-200" />
      <span className="text-gray-200 pt-2">Select a sub-account</span>
    </div>
  ) : (
    <div className="flex flex-row flex-wrap scroll-container ps">
      <div className="grid grid-cols-2 gap-5 mb-10">
        <Link
          to={`/dashboard/investment/add-investment`}
          className="md:w-full md:mr-0"
        >
          <div className="card flex flex-col h-full py-5 justify-center p-12 content-center items-center border-gray-100">
            <img src={PlusIcon} alt="" />
            <p className="text-sm text-black mt-3 text-center">
              Add Investment
            </p>
          </div>
        </Link>

        {investments.map((investment, index) => (
          <InvestmentCard
            key={index}
            navigationPayload={investment.navigationPayload}
            companyName={investment.companyName}
            currentValue={investment.currentValue}
            currency={investment.currency}
            portPercentage={investment.portfolioPercentage}
          />
        ))}
      </div>
    </div>
  );
};

export default SubAccountInvestments;
