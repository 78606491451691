import React, { useEffect, Fragment, useContext, useState } from "react";
import CardIcon from "assets/img/cardIcon.png";
import Loading from "shared-components/Loading";
import classNames from "classnames";
import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import NumberFormat from "react-number-format";
import AddBankContext from "contexts/AddBankContext";
import { closeModalOnOutsideClick } from "utils";
import { useDispatch } from "react-redux";
import { changeWithdrawalLimit } from "state/slices/customer";
import produce from "immer";
import CustomErrorMessage from "shared-components/forms/CustomErrorMessage";

const ChangeWithdrawalLimit = () => {
  const dispatch = useDispatch();

  const {
    isChangeWithdrawalLimitModalOpen,
    closeChangeWithdrawalLimitModal,
    openChangeWithdrawalLimitSuccessModal,
  } = useContext(AddBankContext);

  useEffect(() => {
    if (isChangeWithdrawalLimitModalOpen) {
      closeModalOnOutsideClick(closeChangeWithdrawalLimitModal);
    }
  }, [isChangeWithdrawalLimitModalOpen]);

  const [state, setState] = useState({
    isSubmitting: false,
    submitError: null,
  });

  const initialValues = {
    newLimit: "",
    reasonForChange: "",
  };

  const validationSchema = yup.object().shape({
    newLimit: yup.number().label("New limit").required(),
    reasonForChange: yup.string().label("Reason for change").required(),
  });

  const handleSubmitWithdrawalLimit = async (formValues) => {
    const payload = {
      newLimit: parseFloat(formValues.newLimit),
      reasonForChange: formValues.reasonForChange,
    };

    setState(
      produce((draft) => {
        draft.isSubmitting = true;
        draft.submitError = null;
      })
    );

    const resultAction = await dispatch(changeWithdrawalLimit(payload));

    if (changeWithdrawalLimit.fulfilled.match(resultAction)) {
      openChangeWithdrawalLimitSuccessModal();

      setState(
        produce((draft) => {
          draft.isSubmitting = false;
        })
      );
    } else {
      setState(
        produce((draft) => {
          draft.isSubmitting = false;
          draft.submitError =
            resultAction.error?.message || resultAction.error?.payload;
        })
      );
    }
  };

  return (
    isChangeWithdrawalLimitModalOpen && (
      <div className="modal fixed inset-0 bg-wb-overlay flex justify-center items-center modal-active">
        <Fragment>
          <div className="auth-modal flex flex-col items-center bg-white fadeIn login-fieldset">
            <div className="flex flex-col items-center mb-0">
              <i className="w-20 mb-4">
                <img src={CardIcon} alt="" />
              </i>
              <h1 className="text-2xl font-medium mb-2">
                Change withdrawal limit
              </h1>
              <p className="text-center text-gray-500 leading-normal">
                Enter your new withdrawal limit with reason
              </p>
            </div>

            <Fragment>
              {state.isSubmitting ? (
                <div className="mt-8 flex flex-col items-center">
                  <Loading text="Submitting withdrawal limit request" />
                </div>
              ) : (
                <Fragment>
                  {state.submitError && (
                    <div className="w-72 py-8 text-xs text-left">
                      <p className="w-full p-3 bg-red-200 text-red-700 rounded text-center font-medium">
                        {state.submitError}
                      </p>
                    </div>
                  )}

                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validateOnMount={true}
                    onSubmit={handleSubmitWithdrawalLimit}
                  >
                    {({
                      handleSubmit,
                      isValid,
                      setFieldValue,
                      values,
                      handleBlur,
                    }) => {
                      return (
                        <Form
                          className="flex flex-col items-center mt-10"
                          onSubmit={handleSubmit}
                        >
                          <Fragment>
                            <fieldset className="w-full">
                              <label className="block text-xs mb-2">
                                New limit
                              </label>
                              <NumberFormat
                                thousandSeparator={true}
                                placeholder="eg: 5,000,000"
                                autoComplete="off"
                                type="text"
                                id="newLimit"
                                name="newLimit"
                                className="block w-full text-xs p-3 border border-gray-400 rounded"
                                value={values.newLimit}
                                onBlur={handleBlur}
                                onValueChange={({ value }) =>
                                  setFieldValue("newLimit", value)
                                }
                              />
                              <ErrorMessage
                                name="newLimit"
                                component={CustomErrorMessage}
                              />
                            </fieldset>

                            <fieldset className="mt-6 w-full">
                              <label className="block text-xs mb-2">
                                Reason for Change
                              </label>
                              <Field
                                placeholder="Reason for change"
                                type="text"
                                id="reasonForChange"
                                name="reasonForChange"
                                className="block w-full text-xs p-3 border border-gray-400 rounded"
                              />
                              <ErrorMessage
                                name="reasonForChange"
                                component={CustomErrorMessage}
                              />
                            </fieldset>
                          </Fragment>

                          <div className="nav-buttons flex justify-center">
                            <div
                              onClick={closeChangeWithdrawalLimitModal}
                              className=" w-40  border-b text-center bg-white leading-loose border-wb-primary text-wb-primary mr-3 border wealth-buddy--cta text-white rounded-sm"
                            >
                              Back
                            </div>
                            <button
                              type="submit"
                              className={classNames({
                                "w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm": true,
                                opaque: !isValid,
                              })}
                              disabled={!isValid}
                            >
                              Proceed
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </Fragment>
              )}
            </Fragment>
          </div>
        </Fragment>
      </div>
    )
  );
};

export default ChangeWithdrawalLimit;
