import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { formatCurrency } from "utils";
import { info } from "../../imageLinks";
import moment from "moment";
import { Redirect } from "react-router-dom";
import fundImg from "assets/img/funds_img.jpg";
import { Link, useHistory } from "react-router-dom";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import InvestModal from "../investModal/InvestModal";
import { connect } from "react-redux";
import "./check.scss";
import DropdownFixed from "./DropdownFixed";
import RollOver from "./RollOver";
import AccountIndicator from "../AccountIndicator";
import DatePicker, { utils } from "react-modern-calendar-datepicker";

const today = utils("en").getToday();

function AddInvestmentForm(props) {
  const [isInvestModalVisible, setIsInvestModalVisible] = useState(false);
  const [currentRate, setCurrentRate] = useState(null);
  const [TypeDuration, setTypeDuration] = useState([]);
  const [AllTypeDuration, setAllTypeDuration] = useState(null);
  const [chosenDuration, setChosenDuration] = useState(null);

  const setDuration = (duration) => {
    setChosenDuration(duration);
  }

  const history = useHistory();

  const investment = props.getAllInvestmentsData.find(
    (item) => item.investmentID == props.location.investmentId
  );

  useEffect(() => {
    const AllTypeDurationFiltered = props.investmentRateData.filter(each=>each.investmentType === investment.investmentType);
    setAllTypeDuration(AllTypeDurationFiltered);

    let OnlyDuration = [];
    AllTypeDurationFiltered.map(every =>  OnlyDuration.push({days: every.duration, percentage: every.interestRate, date: moment(today).add(30, "days").format("MMM Do YYYY")}))
    const UniqueOnlyDuration = [];
    OnlyDuration.map(every=>(
      UniqueOnlyDuration.some(each=>each.days === every.days) ? null : UniqueOnlyDuration.push(every)
    ))
    UniqueOnlyDuration.sort(function(a, b){return a.days - b.days});
    setTypeDuration(UniqueOnlyDuration);

    return () => {
      setTypeDuration(OnlyDuration);
    }
  }, [props.investmentRateData]);

  const [state, setState] = useState({
    duration: "",
    rollOver: "NO_ROLLOVER",
    amount: null,
    date: null,
    amountError: "",
    frequency: false,
  });

  const checkInvestmentAmountType = (amount) => {
    if (amount < 1000000){
      return 6
    }
    if (amount >= 1000000 && amount <10000000){
      return 5
    }
    if (amount >= 10000000 && amount <100000000){
      return 4
    }
    if (amount >= 100000000 && amount <500000000){
      return 3
    }
    if (amount >= 500000000){
      return 1
    }
  }

  useEffect(() => {
     // console.log(AllTypeDuration);
      //console.log(chosenDuration);
      //console.log(checkInvestmentAmountType(state.amount))

    if (AllTypeDuration && chosenDuration && state.amount){
      const realRate = AllTypeDuration.find(each=> each.duration === chosenDuration && each.investmentAmountType === checkInvestmentAmountType(state.amount));
      realRate && setCurrentRate(realRate.interestRate)
    }
    return () => {
    setCurrentRate(null)
    }
  }, [chosenDuration, state.amount, AllTypeDuration])

  const setDurationDays = (value) => {
    setState({
      ...state,
      duration: value,
    });
  };
  const setRollOverRule = (value) => {
    setState({
      ...state,
      rollOver: value,
    });
  };

  const callSetAmount = (value) => {
    setState({
      ...state,
      amount: value,
    });
  };

  const onclose = (value) => {
    setIsInvestModalVisible(value);
  };

  if (!investment) {
    return <Redirect to="/dashboard/investment/add-investment" />;
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (state.amount === null) {
      setState({
        ...state,
        amountError: "Field is required",
      });
    } else if (state.amount < investment.minimumAmount) {
      setState({
        ...state,
        amountError: `Amount must be more than ${investment.minimumAmount}`,
      });
    } else {
      setIsInvestModalVisible(true);
    }
  };

  const myDate = Date.now();
  const todayDate = moment(myDate).format("YYYY-MM-DD");

  const investmentFundsPayload = {
    investmentId: `${investment.investmentID}`,
    transAmount: parseInt(state.amount),
    currency: `${investment.currency}`,
    duration:
      investment.minimumDurationInDays === 0
        ? 30
        : investment.minimumDurationInDays,
    fundName: `${investment.name}`,
    frequency: `${state.frequency}`,
    investmentImage: `no-image`,
    description: `${investment.name}`,
    investmentType: investment.investmentType,
  };

  const investmentTBillsPayload = {
    investmentID: `${investment.investmentID}`,
    investmentType: parseInt(investment.investmentType),
    treasuryBillTypeName: `${investment.name}`,
    faceValue: parseInt(state.amount),
    discountRate: `${parseFloat(currentRate)}`,
    startDate: `${todayDate}`, //date . now today's Date no more date display only for mutual funds
    status: "PENDING",
    tenor: `${state.duration}`, //drop down duration 31 days ,72 days,180 days,270days
    investmentImage: `no-image`,
  };

  const investmentFixedPayload = {
    investmentId: `${investment.investmentID}`,
    currency: `${investment.currency}`,
    investmentImage: `no-image`,
    autoRollover: state.frequency,
    currentRate: currentRate,
    instrumentTypeName: `${investment.name}`,
    rollOverrule: `${state.rollOver}`, //dropdown rule for rollover rule should apply
    startDate: `${todayDate}`, //no more date only dropdown
    faceValue: parseInt(state.amount),
    tenure: parseInt(state.duration), // 1 year ,1,2,3,6 month
    investmentType: investment.investmentType,
  };

  const myFormData =
    investment.investmentType == 1
      ? investmentFundsPayload
      : investment.investmentType == 2
      ? investmentFixedPayload
      : investment.investmentType == 3
      ? investmentTBillsPayload
      : investment.investmentType == 6
      ? investmentFixedPayload
      : {};

  const formCurrency = investment.currency == "USD" ? "$" : "₦";

  return (
    <div className="px-4 sm:px-12  flex flex-col fadeIn mt-10">
      <div className="flex flex-row flex-wrap w-full sm:w-8/12 items-center  mb-10 ">
        <p
          onClick={() => {
            return history.push("/dashboard/investment");
          }}
          style={{ color: "#999999" }}
          className="text-xs cursor-pointer"
        >
          Investment
        </p>
        <p style={{ color: "#999999" }} className="text-xs sm:mx-4">
          {" "}
          {">>"}{" "}
        </p>
        <p
          onClick={() => {
            return history.push("/dashboard/investment/add-investment");
          }}
          style={{ color: "#999999" }}
          className="text-xs sm:ml-4 ml-2 mr-2 cursor-pointer"
        >
          Add new Investment
        </p>
        <p style={{ color: "#999999" }} className="text-xs sm:mx-4 ">
          {" "}{">>"}{" "}
        </p>
        <div className="text-sm text-black ml-2">
          {investment.label} <AccountIndicator />
        </div>
      </div>

      <p className="text-black font-bold text-2xl text-left">
        Add new investment
      </p>

      <div className="flex flex-col sm:flex-row">
        <div
          style={{ border: "1px solid #F1F1F1" }}
          className="card sm:w-1/2 pt-24 pb-56 w-auto mb-20 flex flex-col justify-center content-center mt-6 sm:mr-4"
        >
          <fieldset className="mb-4 w-full px-6 mx-auto">
            <label className="block text-xs font-medium">
              How much do you want to invest?
            </label>
            <NumberFormat
              min={investment.minimumAmount}
              thousandSeparator={true}
              placeholder="ex. 100000"
              autoComplete="off"
              type="text"
              id="amount"
              name="amount"
              className="block w-full text-xs mt-2 p-3 border border-gray-400 rounded"
              value={state.amount}
              onValueChange={({ value }) => {
                (value > 0) ? callSetAmount(value) : callSetAmount(value * (-1));
              }}
            />
            {state.amountError ? (
              <p className="label-error--text mt-3 text-xs color-red font-medium text-center bg-red-200">
                {state.amountError}
              </p>
            ) : null}
          </fieldset>
          {investment.investmentType == 2 || investment.investmentType == 3 || investment.investmentType == 6 
          ? (
            <fieldset className="mb-4 w-full px-6 mx-auto">
              <label className="block text-xs font-medium">
                How long would you want to invest
              </label>
              <div className="fieldset mt-2 w-full">
                <DropdownFixed
                  items={TypeDuration}
                  myDuration={setDurationDays}
                  setDuration={setDuration}
                />
              </div>
            </fieldset>
          ) : null}

          {/*TODO Remove redundant RollOver component*/}

          {/* {investment.investmentType == 2 || investment.investmentType == 3 ? (
            <div className="pl-6 sm:pl-24 my-2">
              <input
                onClick={() => {
                  setState({
                    ...state,
                    frequency: !state.frequency,
                  });
                }}
                type="checkbox"
                id="todo"
                name="todo"
                className=""
                value="todo"
              />
              <label
                style={{ position: "relative", top: "-7px" }}
                htmlFor="todo"
                className="text-xs font-bold sm:pl-2 pr-4 text-center"
              >
                Would like this to be a recuring investment
              </label>
            </div>
          ) : null} */}
          {investment.investmentType === 3 ? (
            <div
              style={{ border: "1px solid #8CB13D", background: "#F9FFEB" }}
              className="rounded flex flex-col self-center sm:w-8/12 w-9/12 mt-4 p-2 pb-4"
            >
              <div className="flex flex-row items-center">
                <img src={info} className="h-5 mr-2 w-5" />
                <p style={{ color: "#8CB13D" }} className="text-sm font-bold">
                  Available Tenors
                </p>
              </div>

              <div
                style={{ borderLeft: "0.5px dashed #C3D894" }}
                className="ml-2 pl-2"
              >
                {
                  TypeDuration.map(each=>(
                    <div className="flex flex-row items-center mt-4">
                    <div
                      style={{
                        backgroundColor: "#8CB13D",
                        borderRadius: "50%",
                      }}
                      className="h-2 w-2"
                    />
                    <p className="ml-4 text-xs">{each.days} days tenor plan</p>
                  </div>
                  ))
                }
              </div>
            </div>
          ) : null}
          {/* showing the tenors available for MT_LIP */}
        </div>
        {/* column one end */}

        {/* column two */}
        <div
          style={{ border: "1px solid #F1F1F1" }}
          className="sm:w-1/2 w-auto card pt-24  pb-20  flex flex-col justify-center mt-6 items-center"
        >
          {/* image setting */}
          <div className="w-72 shadow-lg p-2">
            {/* image preview content start */}

            {/* <div className="personalize--card"> */}
            <img src={fundImg} className="h-full w-full" />
            {/* image preview content end */}
          </div>
          {/* image setting end */}

          {/* image text content */}

          <p className="text-xs mt-10 text-black text-center text-opacity-25">
            Investment capital
          </p>

          <p className="text-black text-lg text-center mt-2 font-bold">
            {formCurrency + formatCurrency(state.amount)}
          </p>

          <div className="flex flex-row justify-between w-full mt-4 px-16 items-center">
            <p className="text-left text-black text-opacity-25 text-base">
              Interest rate per year
            </p>
            {
              chosenDuration && state.amount && currentRate? 
              <p className="text-right text-black text-base">
                {currentRate.toFixed(2)}%
                {/* {investment.interestRate.toFixed(2)}% */}
              </p>
              : null
            }
          </div>
          {investment.investmentType == 1 ? (
            <div className="flex flex-row justify-between px-16 mt-6 w-full items-center">
              <p className="text-left text-black text-opacity-25 text-base">
                Start date
              </p>

              <p className="text-right text-black text-base">
                {moment(state.date === null ? Date.now() : state.date)
                  // .subtract(1, "months")
                  .format("L")}
              </p>
            </div>
          ) : null}

          <div className="nav-buttons flex justify-center">
            <Link
              to="/dashboard/investment/add-investment"
              className="mt-12 sm:w-40 w-40 border-b text-center bg-white leading-loose border-wb-primary text-wb-primary mr-3 border wealth-buddy--cta rounded-sm"
            >
              Back
            </Link>

            {investment.investmentType == 2 ||
            investment.investmentType == 3 ||
            investment.investmentType == 6 ? (
              <button
                onClick={(e) => {
                  handleOnSubmit(e);
                }}
                disabled={!currentRate || state.duration.length === 0 ? true : false}
                className={`mt-12  sm:w-40 w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm `}
              >
                Next
              </button>
            ) : null}

            {investment.investmentType == 1 ? (
              <button
                onClick={(e) => {
                  handleOnSubmit(e);
                }}
                disabled={state.amount === null ? true : false}
                className={`mt-12 sm:w-40 w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm `}
              >
                Next
              </button>
            ) : null}
          </div>
        </div>
      </div>

      {isInvestModalVisible ? (
        <InvestModal
          close={onclose}
          createInvestmentPayload={myFormData}
          investType={investment.investmentType}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  getAllInvestmentsData: state.investments.getAllInvestmentsData,
  investmentRateData: state.investments.investmentRateData
});

export default connect(mapStateToProps)(AddInvestmentForm);
