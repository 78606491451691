import { useFormikContext } from "formik";
import React, { Fragment, useEffect } from "react";
import {
  enquireBankAccount,
  enquireBankAccountFail,
} from "state/ducks/enquireBankAccount/actions";
import { connect } from "react-redux";

const Alert = ({ message, color }) => (
  <div
    className={`w-full mt-5 p-3 bg-${color}-200 text-${color}-700 rounded text-xs text-center font-medium`}
  >
    {message}
  </div>
);

const EnquireBankModalVerification = ({
  enquireBankAccountLoading,
  enquireBankAccountError,
  enquireBankAccountData,
  dispatchEnquireBankAccount,
  dispatchEnquireBankAccountFail,
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext();
  const { accountNumber, bankName } = values;
  const markAsValid = () => setFieldValue(props.name, true);
  const markAsInvalid = () => setFieldValue(props.name, false);

  useEffect(() => {
    let isCurrent = true;

    if (accountNumber.length === 10 && bankName !== "") {
      if (isCurrent) {
        dispatchEnquireBankAccount(values, { markAsValid });
      }
    } else {
      dispatchEnquireBankAccountFail(null);
      markAsInvalid();
    }

    return () => {
      isCurrent = false;
    };
  }, [bankName, accountNumber, setFieldValue, props.name]);

  return (
    <Fragment>
      {enquireBankAccountLoading && (
        <Alert color="blue" message="Verifying Bank Account" />
      )}
      {enquireBankAccountError && (
        <Alert color="red" message={enquireBankAccountError} />
      )}
      {enquireBankAccountData.accountName && (
        <Alert color="green" message={enquireBankAccountData.accountName} />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  enquireBankAccountLoading: state.enquireBankAccount.loading,
  enquireBankAccountError: state.enquireBankAccount.error,
  enquireBankAccountData: state.enquireBankAccount.data,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchEnquireBankAccount: (payload, meta) =>
    dispatch(enquireBankAccount(payload, meta)),
  dispatchEnquireBankAccountFail: (payload, meta) =>
    dispatch(enquireBankAccountFail(payload, meta)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnquireBankModalVerification);
