import React, { Fragment, useState } from "react";
import personalSavings from "assets/img/personalIcon.png";
import fixedSavings from "assets/img/fixedIcon.png";
import groupSavings from "assets/img/groupIcon.png";
import groupConSavings from "assets/img/groupConIcon.png";
import fixedFlexSavings from "assets/img/fixedFlex.png";
import roundRobin from "assets/img/round-robin.png";
import handCoin from "../../../assets/img/hand-coin.svg";
import CreatePersonalSavingsModal from "./components/CreatePersonalSavingsModal";
import { FaQuestionCircle } from "react-icons/fa";
import SavingsInfoModal from "./components/SavingsInfoModal";
import produce from "immer";
import ReactTooltip from "react-tooltip";
import { connect, useDispatch } from "react-redux";
import { SavingsType } from "constants/enums";


const CreateSavingsHome = ({ history, savingsConfiguration }) => {
  const [
    isCreatePersonalSavingsModalVisible,
    setCreatePersonalSavingsModalOpen,
  ] = useState(false);

  const [state, setState] = useState({
    isSavingsInfoModalVisible: false,
    savingsInfoModalSavingsItem: {},
  });


  const foundTypeOfSavings = (st) => {
    const found = savingsConfiguration.find(element => element.savingsType == st)
    return found.interestRate
  } 
  


  const showSavingsInfoModal = (e, savings) => {
    e.stopPropagation();
    setState(
      produce((draft) => {
        draft.isSavingsInfoModalVisible = true;
        draft.savingsInfoModalSavingsItem = savings;
      })
    );
  };

  const closeSavingsInfoModal = () => {
    setState(
      produce((draft) => {
        draft.isSavingsInfoModalVisible = false;
        draft.savingsInfoModalDetails = "";
      })
    );
  };

  const openCreatePersonalSavingsModal = () => {
    setCreatePersonalSavingsModalOpen(true);
  };

  const closeCreatePersonalSavingsModal = () => {
    setCreatePersonalSavingsModalOpen(false);
  };

  const navigateToPath = (e, path) => {
    e.preventDefault();
    history.push(path);
  };

  const options = [
    {
      heading: "Personal savings",
      subheading: "Smash your Goals with a personal savings plan.",
      handleClick: openCreatePersonalSavingsModal,
      icon: personalSavings,
      details: "details details details",
      tipText:
       `This savings type allows you set financial goal towards which you can save in incremental amounts<br />
        on a daily, weekly or monthly basis with the money deducted automatically via card or money in your wallet<br />
        You can also top-up this savings type if you miss a payment or have extra cash.  Upon maturity,all saved amounts <br />
        and your interest is credited to your wallet and can be withdrawn.<br />
        
         `,
         rate: foundTypeOfSavings(SavingsType.PersonalTargetSavings)
    },
    {
      heading: "Fixed Lock savings",
      subheading: "Save today to enjoy tomorrow.",
      path: "/dashboard/savings/create/fixed-lock",
      icon: fixedSavings,
      details: "details details details",
      tipText:
        `This savings type is designed for you to put away a fixed,<br />
         lump sum of money which can then not be accessed for a<br /> specified period.
         This money will not be available till the specified date <br />
         of maturity so only use this savings type when you are sure<br />
         you will not need the money
        
        `,
        rate: foundTypeOfSavings(SavingsType.FixedLockSavings)
    },
    {
      heading: "Fixed Flexible savings",
      subheading:
        "Save for a rainy day by locking money away until you need it.",
      path: "/dashboard/savings/create/fixed-flexible",
      icon: fixedFlexSavings,
      details: "details details details",
      tipText:
        `This savings type is allows you to put away a fixed, lump sum of money<br />
         for a specified period. This money can be accessed in emergencies even<br />
         before the specified date of maturity. However, this comes at a price. <br />
         20% of interests earned on whatever you saved will be charged as a penalty<br />
        for liquidating the savings before the specified maturity date.
        `,
        rate: foundTypeOfSavings(SavingsType.FixedFlexibleSavings)
    },
    {
      heading: "Group Target Savings",
      subheading:
        "Don’t do it alone. Start a savings plan with friends and family.",
      path: "/dashboard/savings/create/group-target",
      icon: groupSavings,
      details: "details details details",
      tipText:
        `This savings type allows you and one or more people set a financial goal<br />
         towards which you all can save in incremental amounts on a daily, <br />
         weekly or monthly basis with the money deducted automatically <br />
         via your card or money in your wallet. This money is saved in a common<br />
         pot administered by the admin who created the savings type. <br />
         Do you want to save for Dad’s retirement party with your siblings? <br />
         This is the one you need.
        `,
        rate: foundTypeOfSavings(SavingsType.GroupTargetSavings)
    },
    {
      heading: "Group Challenge Savings ",
      subheading: "Invite friends and family to fun savings challenges.",
      path: "/dashboard/savings/create/group-challenge",
      icon: groupConSavings,
      details: "details details details",
      tipText: 
      `This savings type allows you and one or more people set a financial goal<br />
       towards which you all independently save towards. This is in incremental<br />
       amounts on a daily, weekly or monthly basis with the money deducted automatically<br />
       via your card or money in your wallet. This money is saved in individual pots, <br />
       but you can see the progress of all members of the challenge.<br />
       Challenges are visible only to members of the challenge.
      `,
      rate: foundTypeOfSavings(SavingsType.GroupChallengeSavings)
    },
    {
      heading: "Group Contributory Savings",
      subheading:
        "Save with friends using our take on the traditional collaborative savings.",
      path: "/dashboard/savings/create/group-contributory",
      icon: roundRobin,
      details: "details details details",
      tipText:
        `Would you like to do Ajo/Esusu without the hassles of chasing people<br />
        to pay when it is your turn? This savings type allows you create Ajo<br />
        with as many people as you do right now. The specified periodic amounts<br />
        are debited instantly at the end of the onth/week and sent to the person<br />
        whose turn it is to collect.`,
      rate: foundTypeOfSavings(SavingsType.GroupContributorySavings)


     
    },
    {
      heading: "Meristem Children Education Trust",
      subheading: "Secure your child's future with our Child Education Trust",
      path: "/dashboard/savings/create/mkat-savings",
      icon: handCoin,
      details: "details details details",
      tipText:`Be intentional about planning financially for the education<br />
       and future of your children and wards through periodic deposits<br />
        or a lump sum. The trust fund is created in the name of the child<br />
        and funds will not be accessible until after 1 year.`,
        rate: foundTypeOfSavings(SavingsType.MKATSavings)
    },
    // {
    //   heading: "MFAT Savings",
    //   subheading:
    //   "Meristem Future Assurance Trust Savings",
    //   path: "/dashboard/savings/create/mfat-savings",
    //   icon: roundRobin,
    //   details: "details details details",
    //   tipText:
    //   "Secure your child’s future with our Child Education Trust.",
    // },

 

  ];

  return (
    <Fragment><br />
      <div className="px-12 flare fadeIn">
        <div className="page-heading mb-12 flex flex-col">
          <h1 className="text-4xl mb-6 font-medium">Savings</h1>
          <p className="w-2/5 leading-normal">
            Think Big! Start with a savings plan.
          </p>
        </div>
        <div className="flex-grow flex justify-center items-start">
          <div className="create-saving--overview">
            <div className="flex justify-between create-savings flex-wrap">
              {options.map((item, index) => {
                return item.handleClick ? (
                  <div
                    key={index}
                    onClick={item.handleClick}
                    className="card flex items-center"
                  >
                    <div className="savings-image">
                      <img src={item.icon} alt="" />
                    </div>
                    <div className="savings-items h-full flex flex-col items-start justify-center" >
                      <div className="flex flex-row items-center justify-center mb-2">
                        <h1 className="w-full font-medium card-header">
                          {item.heading}
                        </h1>
                        <span
                          data-tip={item.tipText}
                          //onClick={(e) => showSavingsInfoModal(e, item)}
                          className="text-gray-200 hover:text-green-300 hover:text-xl animate-fade--hover p-1 text-center ml-2 cursor-pointer"
                        > <FaQuestionCircle />
                        </span>
                      </div>
                      <p className="card-excerpt leading-4">
                        {item.subheading}
                      </p>
                      <p className="card-excerpt leading-4">
                        Rate: {item.rate} %
                      </p>
                    </div>
                  </div>
                ) : (
                  <a
                    key={index}
                    onClick={(e) => navigateToPath(e, item.path)}
                    className="card flex items-center"
                    >
                      {
                     item.heading === "Meristem Children Education Trust" ?
                          (
                            <div className="savings-image" style={{ backgroundColor: "#B8DDE9" }} >
                               <img src={item.icon} alt="" />
                            </div>
                          ) :
                          (
                            <div className="savings-image" >
                               <img src={item.icon} alt="" />
                            </div>
                          )
                      }
                    <div className="savings-items h-full flex flex-col items-start justify-center">
                      <div className="flex flex-row items-center justify-center mb-2">
                        <h1 className="w-full font-medium card-header">
                          {item.heading}
                        </h1>
                        <span
                          data-tip={item.tipText}
                          // onClick={(e) => showSavingsInfoModal(e, item)}
                          className="text-gray-200 hover:text-green-300 hover:text-xl animate-fade--hover p-1 text-center ml-2 cursor-pointer"
                        >
                          <FaQuestionCircle />
                        </span>
                      </div>
                      <p className="card-excerpt leading-4">
                        {item.subheading}
                        </p>
                        <p className="card-excerpt leading-4">
                        Rate: {item.rate} %
                      </p>
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <CreatePersonalSavingsModal
        isVisible={isCreatePersonalSavingsModalVisible}
        closeModal={closeCreatePersonalSavingsModal}
      />

      <SavingsInfoModal
        isVisible={state.isSavingsInfoModalVisible}
        savings={state.savingsInfoModalSavingsItem}
        onClose={closeSavingsInfoModal}
      />

      <ReactTooltip effect="solid" multiline={true} />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  savingsConfiguration: state.savingsConfiguration.data,
});
//validate an email with regex

export default connect(mapStateToProps)(CreateSavingsHome);

