import React from "react";
import DashboardSummary from "./components/DashboardSummary";
import DashboardSavings from "./components/DashboardAssetsAllocation";
import DashboardAnalysis from "./components/DashboardAnalysis";
import UpdateFeeds from "./components/UpdateFeeds";

const DashboardHome = () => (
  <div className="px-12 flex flex-col fadeIn">
    <h1 className="text-4xl py-8 font-medium content-header--border content-header--title">
      Overview
    </h1>
    <div className="flex-wrap flex justify-between max-cards mt-10">
      <div className="flex home-card summary-analysis">
        <DashboardSummary />
        <DashboardSavings />
        <DashboardAnalysis />
      </div>
      <div className="flex flex-col home-card card summary-feeds card-padding has-scrollBar">
        <UpdateFeeds />
      </div>
    </div>
  </div>
);

export default DashboardHome;
