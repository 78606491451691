import React from "react";
import { Link } from "react-router-dom";
import "./billsList.scss";
import Loading from "shared-components/Loading";
import ImgBills from "assets/img/utility-bills.png";
import ImgCables from "assets/img/cables.png";
import ImgAirtime from "assets/img/airtime.png";
import ImgSend from "assets/img/send-receive.png";

const BillsList = (props) => {
  const { billCategories } = props;
  //   const categoryImages = {
  //     "assets/img/utility-bills.png": 1,
  //     "assets/img/cables.png": 2,
  //     "assets/img/airtime.png": 4,
  //     "assets/img/send-receive.png": 8,
  //   };

  return (
    <div className="billPayment-container">
      <div className="billPayment-card">
        {billCategories.data.map((billCategory, index) => {
          if (index < 4) {
            return (
              <Link
                to={{
                  pathname: `/dashboard/wallet/bill-payment/${billCategory.categoryid}`,
                  hash: `all-categories/${billCategory.categoryname}`,
                }}
                key={billCategory.categoryid}
              >
                <div className="billPayment-item">
                  <div className="payment-icon">
                    <img
                      src={
                        billCategory.categoryid === "1"
                          ? ImgBills
                          : billCategory.categoryid === "2"
                          ? ImgCables
                          : billCategory.categoryid === "8"
                          ? ImgAirtime
                          : billCategory.categoryid === "4"
                          ? ImgSend
                          : billCategory.categoryid === "3"
                          ? ImgAirtime
                          : ""
                      }
                      alt={billCategory.categoryname}
                    />
                  </div>
                  <p className="payment-text">{billCategory.categoryname}</p>
                </div>
              </Link>
            );
          }
        })}
      </div>
      <div className="flex justify-center">
        {billCategories.loading ? (
          <div className="flex flex-col-reverse text-center align-center">
            <Loading />
          </div>
        ) : (
          <Link
            to={{
              pathname: `wallet/bill-payment/1`,
              hash: "all-categories/utility-bills",
            }}
          >
            <button
              className="mt-6 w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm"
              type="submit"
            >
              VIEW MORE
            </button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default BillsList;
