import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Login from "../pages/auth/Login";
import SignUpPersonalDetails from "../pages/auth/SignUp/PersonalDetails";
import SignUpConfirmEmail from "../pages/auth/SignUp/ConfirmEmail";
import SignUpSetPassword from "../pages/auth/SignUp/SetPassword";
import SignUpSuccess from "../pages/auth/SignUp/Success";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import { useTracking } from "hooks/useTracking";

const AuthRoutes = () => {
  let { path } = useRouteMatch();

  useTracking();

  return (
    <Switch>
      <Route exact path={`${path}/login`} component={Login} />
      <Route exact path={`${path}/sign-up`} component={SignUpPersonalDetails} />
      <Route
        exact
        path={`${path}/sign-up/verify-email`}
        component={SignUpConfirmEmail}
      />
      <Route
        exact
        path={`${path}/sign-up/set-password`}
        component={SignUpSetPassword}
      />
      <Route exact path={`${path}/sign-up/success`} component={SignUpSuccess} />
      <Route
        exact
        path={`${path}/forgot-password`}
        component={ForgotPassword}
      />
      <Route exact path={`${path}/reset-password`} component={ResetPassword} />
    </Switch>
  );
};

export default AuthRoutes;
