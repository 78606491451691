import React from "react";
import ImgBills from "assets/img/utility-bills.png";
import ImgCables from "assets/img/cables.png";
import ImgAirtime from "assets/img/airtime.png";
import ImgSend from "assets/img/send-receive.png";

const ProviderCard = (props) => {
const { provider } = props;

return (
    <div className="provider-card">
      <div className="image-wrapper w-full h-32 content-center rounded-lg">
        {/* <img src="baseUrl.logoUrl" alt="" /> */}
       <div className="payment-icon">
          <img
                      src={
                        provider.categoryid === "1"
                          ? ImgBills
                          : provider.categoryid === "2"
                          ? ImgCables
                          : provider.categoryid === "3"
                          ? ImgAirtime
                          : provider.categoryid === "4"
                          ? ImgSend
                          : provider.categoryid === "8"
                          ? ImgAirtime
                          : ""
                      }
                      alt={provider.categoryid}
                    />
                    </div>
      </div>
      <div className="mt-2 h-16 w-full">{provider.billername}</div>
      
    </div>
  );
};

export default ProviderCard;
