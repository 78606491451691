import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { connect } from "react-redux";

const CompleteProfileUpdate = ({ dashboard }) => {
  const history = useHistory();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => setShow(true), 5000);
  }, []);

  const completeLater = (e) => {
    e.preventDefault();
    setShow(false);
  };

  const completeProfile = () => {
    setShow(false);
    history.push("/dashboard/settings");
  };

  return (
    <CSSTransition
      in={show}
      timeout={1000}
      classNames="animated-fade"
      unmountOnExit
    >
      <div className="flex flex-col shadow-lg rounded-lg bg-white p-5 complete-profile--wrapper">
        <h4 className="font-medium text-sm">Complete your profile</h4>
        <div className="progress mt-10">
          <div
            className={classNames({
              "progress-meter": true,
              "progress-meter-green": dashboard.profileUpdateStatus,
            })}
            style={{ width: `${dashboard.profileUpdateStatus}%` }}
          />
        </div>
        <div className="text-xs mt-3">
          Your account profile is {dashboard.profileUpdateStatus}% complete.
          Kindly complete your KYC to enable withdrawals.
        </div>
        <div className="flex-grow flex justify-start items-center mt-5">
          <a
            href="#/"
            onClick={completeLater}
            className="py-1 px-3 text-center leading-loose bg-white font-semibold text-xs text-gray-700 rounded appearance-none"
          >
            Later
          </a>
          <button
            type="button"
            className="py-1 px-3 text-center leading-loose bg-wb-primary font-semibold text-xs text-white rounded ml-5"
            onClick={completeProfile}
          >
            Complete my profile
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard.data,
});

export default connect(mapStateToProps)(CompleteProfileUpdate);
