import React, { useState } from "react";

const CreatePaymentForm = (props) => {
  const { setShowAddPaymentModal, setShow, formik, field } = props;

  const handleModal = () => {
    setShow(true);
  };

  return (
    <div>
      <form className="payment-form">
        {/* <div className="form-group mb-4">
                    <label className="flex flex-cols" htmlFor="">
                        <small>Email or Phone number</small>
                        <input
                            className="border-gray-500 py-2 px-4"
                            type="text"
                            name="emailOrPhone"
                            value={formik.emailOrPhone}
                            onChange={formik.handleChange}
                        />
                    </label>
                </div> */}
        <div className="form-group mb-4">
          <label className="flex flex-cols" htmlFor="">
            <small>{field}</small>
            <input
              className="border-gray-500 py-2 px-4"
              type="text"
              name={field}
              value={formik.values[field]}
              onFocus={formik.handleFocus}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.errors[field] && formik.touched[field] && (
              <p className="label-error--text mt-3 text-xs color-red font-medium text-center bg-red-200">
                {formik.errors[field]}
              </p>
            )}
          </label>
        </div>
        <div className="form-group mb-4">
          <label className="flex flex-cols">
            <small>Amount</small>
            <input
              className="border-gray-500 py-2 px-4"
              type="number"
              name="amount"
              min="0"
              value={formik.values.amount}
              onFocus={formik.handleFocus}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              readOnly={Number(formik.values.paymentItem.amount) ? true : false}
            />
            {formik.errors.amount && formik.touched.amount && (
              <p className="label-error--text mt-3 text-xs color-red font-medium text-center bg-red-200">
                {formik.errors.amount}
              </p>
            )}
          </label>
          <label className="mt-4">
            <small className="my-4 text-gray-600">
              <span className="text-bold text-gray-600">Fee:</span>{" "}
              <span className="text-gray-600">
                {Intl.NumberFormat("en-NG", {
                  style: "currency",
                  currency: "NGN",
                }).format(formik.values.itemFee)}
              </span>
            </small>
          </label>
        </div>
        <button
          disabled={
            !Boolean(formik.values[field]) ||
            !Boolean(formik.values.amount) ||
            !Boolean(formik.values.paymentItem)
          }
          type="button"
          className="w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm"
          onClick={() => setShowAddPaymentModal(true)}
        >
          Continue
        </button>
      </form>
    </div>
  );
};

export default CreatePaymentForm;
