import React from "react";
import SuccessModal from "shared-components/modals/SuccessModal";
import CardIcon from "assets/img/cardIcon.png";

const ChangeRunningSavingCardSuccess = ({ isVisible, close }) =>
  isVisible && (
    <SuccessModal
      title="Success"
      subtitle="You have successfully updated your card"
      icon={CardIcon}
      buttonTitle="Ok"
      closeModal={close}
    />
  );

export default ChangeRunningSavingCardSuccess;
