import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { verifyFundWalletWithNewCard } from "state/ducks/verifyFundWalletWithNewCard/actions";
import AddBankContext from "contexts/AddBankContext";
import PaystackModal from "providers/WalletProvider/components/PaystackModal";
import FundWalletModal from "providers/WalletProvider/components/FundWalletModal";
import NoBankYetModal from "providers/WalletProvider/components/NoBankYetModal";
import AddBankModal from "providers/WalletProvider/components/AddBankModal";
import FundWalletSuccess from "providers/WalletProvider/components/FundWalletSuccess";
import AddBankSuccess from "providers/WalletProvider/components/AddBankSuccess";
import WithdrawFundsModal from "providers/WalletProvider/components/WithdrawFundsModal";
import WithdrawFundsSuccess from "providers/WalletProvider/components/WithdrawFundsSuccess";
import ChangeWithdrawalLimit from "providers/WalletProvider/components/ChangeWithdrawalLimit";
import ChangeWithdrawalLimitSuccess from "providers/WalletProvider/components/ChangeWithdrawalLimitSuccess";
import produce from "immer";
import {
  getAllBillers,
  getBillCategories,
  getBillPaymentItems,
  getBillersPerCategory,
  makePurchase,
  resetMakePurchase,
} from "state/slices/billsPayment";

const WalletProvider = (props) => {
  const [account, setAccount] = useState(false);
  const dispatch = useDispatch();
  const {
    allBillersData,
    allBillersLoading,
    allBillersError,
    allBillersPerCategoryData,
    allBillersPerCategoryLoading,
    allBillersPerCategoryError,
    allBillCategoriesData,
    allBillCategoriesError,
    allBillCategoriesLoading,
    makePurchaseData,
    makePurchaseError,
    makePurchaseLoading,
    billPaymentItemsData,
    billPaymentItemsError,
    billPaymentItemsLoading,
  } = useSelector((state) => state.bills);
  const {
    data: cardsData,
    loading: cardsLoading,
    error: cardsError,
  } = useSelector((state) => state.cards);
  const {
    data: bankAccountsData,
    loading: bankAccountsLoading,
    error: bankAccountsError,
  } = useSelector((state) => state.bankAccounts);

  const dispatchVerifyFundWalletWithNewCard = (payload, meta) =>
    dispatch(verifyFundWalletWithNewCard(payload, meta));

  const dispatchGetAllBillers = () => dispatch(getAllBillers());

  const dispatchGetBillCategories = () => dispatch(getBillCategories());

  const dispatchGetBillersByCategory = (payload) => {
    dispatch(getBillersPerCategory(payload));
  };

  const dispatchMakePurchase = (data) => dispatch(makePurchase(data));

  const dispatchResetPurchase = () => dispatch(resetMakePurchase());

  const dispatchGetBillPaymentItems = (billerId) =>
    dispatch(getBillPaymentItems(billerId));

  useEffect(() => {
    dispatchGetAllBillers();
    dispatchGetBillCategories();
  }, []);

  const [state, setState] = useState({
    tranxRef: "",
    amount: 0,
    isFundWalletModalOpen: false,
    isSuccessModalOpen: false,
    isPaystackModalOpen: false,
    isNoBankYetModalOpen: false,
    isAddBankModalOpen: false,
    isAddBankSuccessModalOpen: false,
    isWithdrawFundsModalOpen: false,
    isWithdrawFundsSuccessModalOpen: false,
    isChangeWithdrawalLimitModalOpen: false,
    isChangeWithdrawalLimitSuccessModalOpen: false,
  });

  const handleAccount = () => {
    setAccount(!account);
  };

  const openFundWalletModal = () => {
    setState(
      produce((draftState) => {
        draftState.isFundWalletModalOpen = true;
      })
    );
  };

  const closeFundWalletModal = () => {
    setState(
      produce((draftState) => {
        draftState.isFundWalletModalOpen = false;
      })
    );
  };

  const showSuccessModal = () => {
    setState(
      produce((draftState) => {
        draftState.isSuccessModalOpen = true;
        draftState.isFundWalletModalOpen = false;
      })
    );
  };

  const closeSuccessModal = () => {
    setState(
      produce((draftState) => {
        draftState.isSuccessModalOpen = false;
      })
    );
  };

  const showPaystackModal = () => {
    setState(
      produce((draftState) => {
        draftState.isPaystackModalOpen = true;
      })
    );
  };

  const closePaystackModal = () => {
    setState(
      produce((draftState) => {
        draftState.isPaystackModalOpen = false;
      })
    );
  };

  const openNoBankYetModal = () => {
    setState(
      produce((draftState) => {
        draftState.isNoBankYetModalOpen = true;
      })
    );
  };

  const closeNoBankYetModal = () => {
    setState(
      produce((draftState) => {
        draftState.isNoBankYetModalOpen = false;
      })
    );
  };

  const openAddBankModal = () => {
    setState(
      produce((draftState) => {
        draftState.isAddBankModalOpen = true;
      })
    );
  };

  const closeAddBankModal = () => {
    setState(
      produce((draftState) => {
        draftState.isAddBankModalOpen = false;
      })
    );
  };

  const showAddBankSuccessModal = () => {
    setState(
      produce((draftState) => {
        draftState.isAddBankSuccessModalOpen = true;
      })
    );
  };

  const closeAddBankSuccessModal = () => {
    setState(
      produce((draftState) => {
        draftState.isAddBankSuccessModalOpen = false;
      })
    );
  };

  const setAmount = (amount) => {
    setState(
      produce((draftState) => {
        draftState.amount = amount;
      })
    );
  };

  const startWithdrawFundsIntent = () => {
    if (bankAccountsData && bankAccountsData.length > 0) {
      openWithdrawFundsModal();
    } else {
      openNoBankYetModal();
    }
  };

  const continueToPaystack = (tranxRef) => {
    setState(
      produce((draftState) => {
        draftState.tranxRef = tranxRef;
        draftState.isFundWalletModalOpen = false;
        draftState.isPaystackModalOpen = true;
      })
    );
  };

  const completePaystackPayment = () => {
    setState(
      produce((draftState) => {
        draftState.isPaystackModalOpen = false;
        draftState.isSuccessModalOpen = true;
      })
    );
  };

  const handlePaystackSuccess = () => {
    const params = { transactionReference: state.tranxRef };
    const meta = { completePaystackPayment };
    dispatchVerifyFundWalletWithNewCard(params, meta);
  };

  const continueToAddBankDetails = () => {
    setState(
      produce((draftState) => {
        draftState.isNoBankYetModalOpen = false;
        draftState.isAddBankModalOpen = true;
      })
    );
  };

  const showAddBankSuccess = () => {
    setState(
      produce((draftState) => {
        draftState.isAddBankModalOpen = false;
        draftState.isAddBankSuccessModalOpen = true;
      })
    );
  };

  const openWithdrawFundsModal = () => {
    setState(
      produce((draftState) => {
        draftState.isWithdrawFundsModalOpen = true;
      })
    );
  };

  const closeWithdrawFundsModal = () => {
    setState(
      produce((draftState) => {
        draftState.isWithdrawFundsModalOpen = false;
      })
    );
  };

  const openChangeWithdrawalLimitModal = () => {
    setState(
      produce((draftState) => {
        draftState.isWithdrawFundsModalOpen = false;
        draftState.isChangeWithdrawalLimitModalOpen = true;
      })
    );
  };

  const closeChangeWithdrawalLimitModal = () => {
    setState(
      produce((draftState) => {
        draftState.isChangeWithdrawalLimitModalOpen = false;
      })
    );
  };

  const showWithdrawFundsSuccessModal = () => {
    setState(
      produce((draftState) => {
        draftState.isWithdrawFundsSuccessModalOpen = true;
        draftState.isWithdrawFundsModalOpen = false;
      })
    );
  };

  const closeWithdrawFundsSuccessModal = () => {
    setState(
      produce((draftState) => {
        draftState.isWithdrawFundsSuccessModalOpen = false;
      })
    );
  };

  const openChangeWithdrawalLimitSuccessModal = () => {
    setState(
      produce((draftState) => {
        draftState.isChangeWithdrawalLimitSuccessModalOpen = true;
        draftState.isChangeWithdrawalLimitModalOpen = false;
      })
    );
  };

  const closeChangeWithdrawalLimitSuccessModal = () => {
    setState(
      produce((draftState) => {
        draftState.isChangeWithdrawalLimitSuccessModalOpen = false;
      })
    );
  };

  const contextValues = {
    tranxRef: state.tranxRef,
    amount: state.amount,
    isFundWalletModalOpen: state.isFundWalletModalOpen,
    isSuccessModalOpen: state.isSuccessModalOpen,
    isPaystackModalOpen: state.isPaystackModalOpen,
    isNoBankYetModalOpen: state.isNoBankYetModalOpen,
    isAddBankModalOpen: state.isAddBankModalOpen,
    isAddBankSuccessModalOpen: state.isAddBankSuccessModalOpen,
    isWithdrawFundsModalOpen: state.isWithdrawFundsModalOpen,
    isWithdrawFundsSuccessModalOpen: state.isWithdrawFundsSuccessModalOpen,
    isChangeWithdrawalLimitModalOpen: state.isChangeWithdrawalLimitModalOpen,
    isChangeWithdrawalLimitSuccessModalOpen:
      state.isChangeWithdrawalLimitSuccessModalOpen,
    account,
    bankAccounts: {
      loading: bankAccountsLoading,
      data: bankAccountsData,
      error: bankAccountsError,
    },
    billers: {
      loading: allBillersLoading,
      data: allBillersData,
      error: allBillersError,
    },
    billCategories: {
      loading: allBillCategoriesLoading,
      data: allBillCategoriesData,
      error: allBillCategoriesError,
    },
    billersByCategory: {
      loading: allBillersPerCategoryLoading,
      data: allBillersPerCategoryData,
      error: allBillersPerCategoryError,
    },
    cards: {
      loading: cardsLoading,
      data: cardsData,
      error: cardsError,
    },
    makePurchase: {
      data: makePurchaseData,
      error: makePurchaseError,
      loading: makePurchaseLoading,
    },
    billPaymentItems: {
      data: billPaymentItemsData,
      error: billPaymentItemsError,
      loading: billPaymentItemsLoading,
    },
    openFundWalletModal,
    closeFundWalletModal,
    showSuccessModal,
    closeSuccessModal,
    showPaystackModal,
    closePaystackModal,
    openNoBankYetModal,
    closeNoBankYetModal,
    openAddBankModal,
    closeAddBankModal,
    showAddBankSuccessModal,
    closeAddBankSuccessModal,
    setAmount,
    continueToPaystack,
    completePaystackPayment,
    handlePaystackSuccess,
    startWithdrawFundsIntent,
    openWithdrawFundsModal,
    closeWithdrawFundsModal,
    openChangeWithdrawalLimitModal,
    closeChangeWithdrawalLimitModal,
    showWithdrawFundsSuccessModal,
    openChangeWithdrawalLimitSuccessModal,
    closeChangeWithdrawalLimitSuccessModal,
    closeWithdrawFundsSuccessModal,
    continueToAddBankDetails,
    showAddBankSuccess,
    handleAccount,
    dispatchGetAllBillers,
    dispatchVerifyFundWalletWithNewCard,
    dispatchGetBillersByCategory,
    dispatchMakePurchase,
    dispatchGetBillPaymentItems,
    dispatchResetPurchase,
  };

  return (
    <Fragment>
      <AddBankContext.Provider value={contextValues}>
        {props.children}
        <FundWalletModal />
        <PaystackModal />
        <FundWalletSuccess />
        <NoBankYetModal />
        <AddBankModal />
        <AddBankSuccess />
        <WithdrawFundsModal />
        <WithdrawFundsSuccess />
        <ChangeWithdrawalLimit />
        <ChangeWithdrawalLimitSuccess />
      </AddBankContext.Provider>
    </Fragment>
  );
};

export default WalletProvider;
