import React, {useEffect} from "react";
import { PlusIcon } from "../../imageLinks";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import InvestmentCard from "../InvestmentCard";

const PrimaryAccountInvestments = ({
  investmentValuationData,
  investmentValuationLoading,
}) => {
  const investments =investmentValuationData !== null ? [
    ...investmentValuationData.portfolioHoldings.map((investment) => ({
      navigationPayload: {
        investmentId: investment.securityId,
      },
      companyName: investment.companyName,
      currentValue: investment.currentValue,
      currency: 'NGN',
      portfolioPercentage: investment.portPercentage,
    })),
    ...investmentValuationData.fixedDeposits.map((investment) => ({
      navigationPayload: {
        investmentId: investment.typeId,
        fixedId: investment.instrumentId,
      },
      companyName: investment.productLabel,
      currentValue: investment.carryingValue.amount,
      currency: investment.carryingValue.currency,
      portfolioPercentage: investment.interestRate,
    })),
    ...investmentValuationData.treasuryBills.map((investment) => ({
      navigationPayload: {
        investmentId: investment.typeId,
        tBillId: investment.id,
      },
      companyName: investment.typeLabel,
      currentValue: investment.valueAsAtDate.amount,
      currency: 'NGN',
      portfolioPercentage: investment.interestRate,
    })),
  ] : [];

  return (
    !investmentValuationLoading && (
      <div className="flex flex-row flex-wrap scroll-container ps">
        <div className="grid grid-cols-2 gap-5 mb-10">
          <Link
            to={`/dashboard/investment/add-investment`}
            className="md:w-full md:mr-0"
          >
            <div className="card flex flex-col h-full py-5 justify-center p-12 content-center items-center border-gray-100">
              <img src={PlusIcon} alt="" />
              <p className="text-sm text-black mt-3 text-center">
                Add Investment
              </p>
            </div>
          </Link>

          {investments.map((investment, index) => (
            <InvestmentCard
              key={index}
              navigationPayload={investment.navigationPayload}
              companyName={investment.companyName}
              currentValue={investment.currentValue}
              currency={investment.currency}
              portPercentage={investment.portfolioPercentage}
            />
          ))}
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  investmentValuationData: state.investments.investmentValuationData,
  investmentValuationLoading: state.investments.investmentValuationLoading,
});

export default connect(mapStateToProps)(PrimaryAccountInvestments);
