import React from "react";

const DashboardIcon = (props) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <g fill="#fff">
        <path d="M7.984 5.71H.71C.203 5.71 0 5.505 0 5V1.794C0 .74.74 0 1.788 0h12.418c.869 0 1.516.493 1.732 1.332.037.142.05.296.05.444.006 1.091 0 2.176.006 3.267 0 .377-.216.679-.679.673-2.441-.013-4.883-.007-7.33-.007zM11.425 6.85h3.866c.487 0 .702.222.702.71v6.658c0 .863-.505 1.517-1.338 1.732-.141.038-.295.05-.443.05h-6.69c-.444 0-.678-.228-.678-.672v-7.8c0-.444.228-.678.672-.678h3.909zM5.71 11.419c0 1.294-.013 2.59.006 3.884.006.48-.303.697-.703.697-1.067-.007-2.14 0-3.206-.007C.74 15.993 0 15.26 0 14.193V7.534c0-.462.222-.69.69-.69 1.443 0 2.886.006 4.329 0 .376 0 .697.203.69.69-.012 1.295 0 2.59 0 3.885z" />
      </g>
    </svg>
  );
};

export default DashboardIcon;
