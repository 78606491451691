import React from "react";
import Trash from "assets/img/trash.svg";

const BankListItem = ({
  bankAccount,
  deleteBankAccount,
  requestVerification,
}) => (
  <div className="single-user--card single-bank" key={bankAccount.id}>
    <div className="flex flex-row justify-between items-center">
      {bankAccount.isVerified ? (
        <div className="px-2 bg-green-500 text-white rounded-full font-normal text-xs leading-5">
          Verified
        </div>
      ) : (
        <div className="flex flex-row space-x-4">
          <div className="px-2 bg-red-400 text-white rounded-full font-normal text-xs leading-5">
            Unverified
          </div>
          <button
            type="button"
            className="appearance-none focus:outline-none font-normal text-xs leading-5"
            onClick={() => requestVerification(bankAccount.id)}
          >
            Click to verify
          </button>
        </div>
      )}

      <a
        href="#/"
        className="single-card--action flex justify-end"
        onClick={(e) => deleteBankAccount(e, bankAccount.id)}
      >
        <img src={Trash} alt="" />
      </a>
    </div>
    <div className="single-card--body flex justify-between color-black">
      <div className="user-bank--name">{bankAccount.accountName}</div>
    </div>
    <div className="single-card--excerpt flex justify-between items-center">
      <div className="flex items-center color-black">
        <span className="mr-2">{bankAccount.bankName}</span>
      </div>
      <div className="color-black">
        <span className="">{bankAccount.bankAccountNumber}</span>
      </div>
    </div>
  </div>
);

export default BankListItem;
