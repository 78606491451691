import React from "react";
import CloseModalIcon from "shared-components/svgs/CloseModalIcon";
import PaymentMethodIcon from "assets/img/paymentMethodIcon.svg";
import Select from "react-select";

const RecurringModal = (props) => {
  const { setShow, setChecked, checked, show } = props;

  const handleClose = () => {
    setShow(false);
  };

  const handleSetRecurring = () => {
    setShow(!show);
    setChecked(!checked);
  };
  const options = [
    {
      value: "chocolate",
      label: <div className="flex items-center color-gray-500">Monthly</div>,
    },
  ];

  return (
    <div className="modal fixed inset-0 bg-wb-overlay flex justify-center items-center modal-active">
      <div className="flex flex-col w-full max-w-md bg-white p-16 fadeIn rounded-lg relative">
        <span className="closeModal" onClick={handleClose}>
          <CloseModalIcon />
        </span>

        <>
          <div className="my-8 text-center">
            <h1 className="text-2xl font-medium mb-2">
              {checked ? "Disable Recurring Bill" : "Set recurring bill"}
            </h1>
            <p className="text-center text-gray-500 leading-normal">
              {checked ? (
                <span className="inline-block my-10">Are you sure?</span>
              ) : (
                "Set an auto bill payment on this bill\nfor subsequent charges."
              )}
            </p>
          </div>
          {!checked && (
            <>
              <div className="mt-4">
                <div>
                  <div>
                    <p className="font-medium mb-2">Set recurring interval</p>
                  </div>
                  <Select
                    options={options}
                    //   onChange={() => setPaymentSuccess(true)}
                  />
                </div>

                <div className="py-4"></div>
                <div>
                  <p className="text-center text-gray-500 leading-normal mb-4">
                    Next payment date is 10th Feb, 2020
                  </p>
                </div>
              </div>
            </>
          )}
          <div className="flex justify-center">
            <button
              onClick={() => {
                handleSetRecurring();
              }}
              className="w-40 text-center wealth-buddy--cta leading-loose bg-wb-primary text-white rounded-sm mt-8 center-button"
            >
              Done
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

export default RecurringModal;
