import React, { Fragment, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import { changePassword } from "state/slices/account";
import { store } from "react-notifications-component";
import notification from "node -v/notification";
import classNames from "classnames";
import PasswordStrengthMeter from "../../auth/SignUp/SetPassword/components/PasswordStrengthMeter";

const ChangePassword = () => {
  const dispatch = useDispatch();

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .label("Password")
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password should contain an uppercase, special character, and a minimum of 6 characters"
      ),
    confirmPassword: yup
      .string()
      .label("Password confirmation")
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Password confirmation is required"),
  });

  const [isChanging, setIsChanging] = useState(false);

  const handleSubmit = async (formValues) => {
    setIsChanging(true);

    const resultAction = await dispatch(changePassword(formValues));
    if (changePassword.fulfilled.match(resultAction)) {
      setIsChanging(false);

      store.addNotification({
        ...notification,
        title: "Success",
        message: "Password changed successfully",
        type: "success",
      });
    } else {
      if (resultAction.payload) {
        setIsChanging(false);

        store.addNotification({
          ...notification,
          title: "Error",
          message: resultAction.payload.message,
          type: "error",
        });
      } else {
        setIsChanging(false);

        store.addNotification({
          ...notification,
          title: "Error",
          message: resultAction.error.message,
          type: "error",
        });
      }
    }
  };

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isValid }) => {
          return (
            <Fragment>
              <Form
                className="account-content fadeIn w-1/2 mx-auto"
                onSubmit={handleSubmit}
              >
                <fieldset>
                  <label className="block text-xs mb-3" htmlFor="password">
                    Password
                  </label>
                  <Field
                    placeholder="Password"
                    type="password"
                    id="password"
                    name="password"
                    className="block w-full text-xs p-3 border border-gray-400 rounded"
                  />
                  <span className="text-xs text-gray-500 leading-4 mt-2 block">
                    Password should contain Uppercase,Special character, and
                    number Minimum character 6
                  </span>
                  <PasswordStrengthMeter />
                  <ErrorMessage
                    name="password"
                    render={(errorMessage) => (
                      <p className="label-error--text mt-3 text-xs color-red font-medium text-center bg-red-200">
                        {errorMessage}
                      </p>
                    )}
                  />
                </fieldset>

                <fieldset className="mt-6">
                  <label
                    className="block text-xs mb-3"
                    htmlFor="confirmPassword"
                  >
                    Confirm Password
                  </label>
                  <Field
                    placeholder="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    className="block w-full text-xs p-3 border border-gray-400 rounded"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    render={(errorMessage) => (
                      <p className="label-error--text mt-3 text-xs color-red font-medium text-center bg-red-200">
                        {errorMessage}
                      </p>
                    )}
                  />
                </fieldset>

                <div className="w-full flex justify-end mt-12">
                  <button
                    type="submit"
                    className={classNames({
                      "px-10 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm": true,
                      "loading opaque": isChanging,
                    })}
                    disabled={!isValid}
                    onClick={handleSubmit}
                  >
                    <span className="mr-3">Change Password</span>{" "}
                    <span className="loader" />
                  </button>
                </div>
              </Form>
            </Fragment>
          );
        }}
      </Formik>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  cards: state.cards.data,
});

export default connect(mapStateToProps)(ChangePassword);
