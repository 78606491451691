export const GET_BANK_ACCOUNTS_DATA_START = "GET_BANK_ACCOUNTS_DATA_START";
export const GET_BANK_ACCOUNTS_DATA_SUCCESS = "GET_BANK_ACCOUNTS_DATA_SUCCESS";
export const GET_BANK_ACCOUNTS_DATA_FAIL = "GET_BANK_ACCOUNTS_DATA_FAIL";
export const GET_BANK_ACCOUNTS_DATA = "GET_BANK_ACCOUNTS_DATA";

export default {
  GET_BANK_ACCOUNTS_DATA_START,
  GET_BANK_ACCOUNTS_DATA_SUCCESS,
  GET_BANK_ACCOUNTS_DATA_FAIL,
  GET_BANK_ACCOUNTS_DATA,
};
