import React, { Fragment, useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import dollBox from "assets/img/funds_img.jpg";
import { getAllInvestments, getInvestmentValuation } from "state/slices/investments";
import { formatCurrency } from "utils";
import FundExistingModal from "../viewAnInvestment/component/fundExistingInvestment/FundExistingModal";

const FundInvestment = (props) => {
  const [amount, setAmount] = useState(null);
  const [modal, changeModal] = useState(false);
  const [view, setView] = useState(false);
  const [itemView, setItem] = useState("choose from running investments");
  const [itemView2, setItem2] = useState("choose from running investments");
  const [itemView3, setItem3] = useState("choose from running investments");
  const [itemPerc, setPerc] = useState("");
  const [Id, setId] = useState("");
  const [investName, setInvestName] = useState("");
  const [investType, setInvestType] = useState(null);
  const [investCurrency, setInvestCurrency] = useState("");
  const [investSymbol, setInvestSymbol] = useState("");
  const [investCurrent, setInvestCurrent] = useState("");
  const [showList, setShowList] = useState(false);
  const [showList2, setShowList2] = useState(false);
  const [showList3, setShowList3] = useState(false);

  const dispatch = useDispatch();

  const tegaSum = Object.keys(props.investmentValuationData).length;
  useEffect(() => {
    if (tegaSum === 0) {
      dispatch(getInvestmentValuation());
    }
    if (!props.getAllInvestmentsData) {
      dispatch(getAllInvestments());
    }
  }, []);

  const onclose = (val) => {
    changeModal(val);
  };

  const specificData = props.investmentValuationData.portfolioHoldings;

  const fundData = {
    transAmount: parseInt(amount),
    securityId: Id,
    description: `${investSymbol}`,
    currency: `${investCurrency == "undefined" ? "NGN" : investCurrency}`,
    fundName: `${investName}`,
    itemId: investType,
  };

  const onSubmitFund = () => { changeModal(true) };

  const clickView = (value) => { setView(!value) };

  return (
    <div className="px-4 sm:px-12  flex flex-col fadeIn mt-10">
      <h2 className="text-black font-bold text-2xl text-left">
        Fund Investment
      </h2>

      <div className="flex flex-col sm:flex-row">
        <div
          style={{ border: "1px solid #F1F1F1" }}
          className="card sm:w-1/2 pt-24 pb-56 w-auto mb-20 flex flex-col justify-center content-center mt-6 sm:mr-4"
        >
          <fieldset className="mb-4 w-full px-6 mx-auto">
            <label className="block text-xs font-medium">
              How much do you want to add to your investment?
            </label>
            <NumberFormat
              thousandSeparator={true}
              placeholder="ex. 10,000"
              autoComplete="off"
              type="text"
              id="amount"
              name="amount"
              min='1000'
              className="block w-full text-xs mt-2 p-3 border border-gray-400 rounded"
              value={amount}
              onValueChange={({ value }) => {
                (value > 0) ? setAmount(value) : setAmount(value*(-1))

              }}
            />
          </fieldset>

          <fieldset className="mb-4 w-full px-6 mx-auto">
            <label className="block text-xs font-medium">
              Choose investment from mutual Funds
            </label>

            <div className="fieldset w-11/12 mt-2 w-full">
              {props.investmentValuationLoading ? null : (
                <React.Fragment>
                  <div className="fund-dropdown">
                    <div
                      className="select-option"
                      onClick={() => clickView(view)}
                    >
                      <div className="buddy-dropdown-title flex flex-row">
                        {" "}
                        {itemView}
                      </div>
                      <div
                        className="buddy-dropdown-icon"
                        dangerouslySetInnerHTML={{
                          __html:
                            '<svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 7L13.9282 0.25H0.0717969L7 7Z" fill="black"/></svg>',
                        }}
                      />
                    </div>
                  </div>
                  {view ? (
                    <ul className="buddy-dropdown-list basic-dropdown">
                      {specificData.length < 1
                        ? null
                        : specificData.map((item, i) => (
                            <Fragment key={i}>
                              <li
                                key={i}
                                className="buddy-dropdown-item flex hover:bg-gray-100 flex-row items-center"
                                onClick={() => {
                                  setItem(`${item.companyName}`);
                                  setId(`${item.securityId}`);
                                  setPerc(`${item.portPercentage.toFixed(1)}`);
                                  setView(false);
                                  setInvestName(`${item.symbol}`);
                                  setInvestSymbol(`${item.companyName}`);
                                  setInvestCurrent(`${item.currentValue}`);
                                  setShowList2(false);
                                  setShowList(true);
                                  setShowList3(false);
                                  setInvestCurrency(`${item.currency}`);
                                  setInvestType(item.securityId);
                                }}
                              >
                                <div className="flex flex-col sm:flex-row justify-center mt-4">
                                  {/* <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ8CyHlXfQ0X5KJ_kj1pRohugCUtBom9Qk1wg&usqp=CAU"
                                    alt=""
                                    className="rounded sm:h-12 sm:w-12  self-center"
                                  /> */}
                                  <div className="ml-4 self-center">
                                    <p className="text-sm font-medium text-black">
                                      {item.companyName}
                                    </p>
                                    <p
                                      style={{ color: "#8CB13D" }}
                                      className="text-sm font-medium"
                                    >
                                      {/* {item.portPercentage.toFixed(1)}% returns */}
                                      { "Returns - Open"}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </Fragment>
                          ))}
                    </ul>
                  ) : null}
                </React.Fragment>
              )}
            </div>
          </fieldset>

          {showList ? (
            <div className="flex flex-col sm:flex-row justify-center mt-4 px-10">
              {/* <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ8CyHlXfQ0X5KJ_kj1pRohugCUtBom9Qk1wg&usqp=CAU"
                alt=""
                className="rounded sm:h-12 sm:w-12 self-center"
              /> */}
              <div className="ml-4">
                <p className="text-lg font-medium text-black">{itemView}</p>
                <p style={{ color: "#8CB13D" }} className="text-lg font-medium">
                  {/* {itemPerc}% returns */}
                  { "Returns - Open"}
                </p>
              </div>
            </div>
          ) : null}

          {showList2 ? (
            <div className="flex flex-col sm:flex-row justify-center mt-4 px-10">
              {/* <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ8CyHlXfQ0X5KJ_kj1pRohugCUtBom9Qk1wg&usqp=CAU"
                alt=""
                className="rounded sm:h-12 sm:w-12 self-center"
              /> */}
              <div className="ml-4">
                <p className="text-lg font-medium text-black">{itemView2}</p>
                <p style={{ color: "#8CB13D" }} className="text-lg font-medium">
                  {/* {itemPerc}% returns */}
                  { "Returns - Open"}
                </p>
              </div>
            </div>
          ) : null}

          {showList3 ? (
            <div className="flex flex-col sm:flex-row justify-center mt-4 px-10">
              {/* <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ8CyHlXfQ0X5KJ_kj1pRohugCUtBom9Qk1wg&usqp=CAU"
                alt=""
                className="rounded sm:h-12 sm:w-12 self-center"
              /> */}
              <div className="ml-4">
                <p className="text-lg font-medium text-black">{itemView3}</p>
                <p style={{ color: "#8CB13D" }} className="text-lg font-medium">
                  {/* {itemPerc}% returns */}
                  { "Returns - Open"}
                </p>
              </div>
            </div>
          ) : null}
        </div>

        <div
          style={{ border: "1px solid #F1F1F1" }}
          className="sm:w-1/2 w-auto card sm:w-1/2 pt-24  pb-20  flex flex-col justify-center mt-6 items-center"
        >
          <div className="w-72 shadow-lg p-2">
            <div className="h-32 w-full  border-dashed border border-gray-400 rounded flex flex-col justify-center items-center">
              <img src={dollBox} alt="" className="w-full h-full" />
            </div>
          </div>

          <p className="text-xl font-bold mb-10 mt-10 text-black text-center">
            {investName}
          </p>

          <div className="flex flex-row justify-between w-full mt-4 px-16 items-center">
            <p className="text-left text-black text-opacity-25 text-base">
              Top up value
            </p>
            <p className="text-right text-black text-base">
              ₦ {formatCurrency(amount)}
            </p>
          </div>
          <div className="flex flex-row justify-between px-16 mt-6 w-full items-center">
            <p className="text-left text-black text-opacity-25 text-base">
              Current value
            </p>
            <p className="text-right text-black text-base">
              ₦ {formatCurrency(investCurrent)}
            </p>
          </div>
          {/* <div className="flex flex-row justify-between px-16 mt-6 w-full items-center">
            <p className="text-left text-black text-opacity-25 text-base">
              Interest rate per year
            </p>
            <p className="text-right text-black text-base">{itemPerc}%</p>
          </div> */}

          <div className="nav-buttons flex justify-center">
            <Link
              className="mt-12 w-40  border-b text-center bg-white leading-loose border-wb-primary text-wb-primary mr-3 border wealth-buddy--cta text-white rounded-sm"
              to="/dashboard/investment"
            >
              Back
            </Link>
            <button
              onClick={() => {
                onSubmitFund();
              }}
              disabled={amount == null || Id.length == 0 ? true : false}
              className={`mt-12 w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm`}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {modal ? (
        <FundExistingModal
          myclose={onclose}
          MycreateInvestmentData={fundData}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  getAllInvestmentsData: state.investments.getAllInvestmentsData,
  investmentValuationData: state.investments.investmentValuationData,
  investmentValuationLoading: state.investments.investmentValuationLoading,
});

export default connect(mapStateToProps)(FundInvestment);
