import axios from "services/api";
import urls from "../../constants/urls";

export const startTransferToBank = (data) => {
  return axios.post(urls.startCashTransferToBank, data);
};

export const completeTransferToBank = (data) => {
  return axios.post(urls.completeCashTransferToBank, data);
};
