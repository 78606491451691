import React from "react";

const Logout = (props) => {
  return (
    <svg width={19} height={18} viewBox="0 0 19 18" fill="none" {...props}>
      <path
        d="M9.477 18C4.178 17.974-.027 13.903 0 8.98.027 3.99 4.293-.02 9.517 0 14.775.02 19.02 4.06 19 9.032c-.027 4.999-4.306 8.962-9.523 8.968zM3.969 9.32c.034.23.047.575.128.9.5 2.149 2.288 3.753 4.543 4.085 1.235.186 2.423.013 3.503-.575 1.808-.978 2.8-2.473 2.902-4.455.074-1.342-.412-2.525-1.336-3.535a5.631 5.631 0 00-.98-.824c-.357-.243-.823-.11-1.093.249a.75.75 0 00.115 1.042c.101.09.216.172.317.262.601.518 1.02 1.144 1.175 1.898.29 1.426-.135 2.634-1.316 3.58a3.673 3.673 0 01-2.073.805c-1.154.083-2.153-.256-3.003-1.01-1.073-.952-1.458-2.62-.898-3.905.277-.633.702-1.144 1.256-1.573.378-.294.432-.812.148-1.144a.889.889 0 00-1.208-.115c-1.444 1.068-2.146 2.48-2.18 4.315zm4.67-3.158v2.013c0 .32.129.57.426.73.553.3 1.289-.033 1.289-.73v-4.02c0-.25-.074-.48-.284-.64a.878.878 0 00-.944-.127c-.331.14-.486.403-.486.741v2.033z"
        fill="#fff"
      />
    </svg>
  );
};

export default Logout;
