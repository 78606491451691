const urls = {
  login: "/api/Customer/login",
  startVerifyEmail: "/api/Customer/StartVerifyEmail",
  resendVerifyEmail: "/api/Customer/ResendVerifyEmail",
  completeVerifyEmail: "/api/Customer/CompleteVerifyEmail",
  createUser: "/api/Customer/create",
  addBvn: "/api/Customer/AddbvnToProfile",
  verifyBvn: "/api/Customer/bvn/verify",
  deleteCard: "/api/Customer/deletecards/:id",
  startFundNewCard: "/api/Customer/StartFundWalletWithNewCard",
  verifyFundNewCard: "/api/Customer/VerifyFundWalletWithNewCard",
  getCustomerSavings: "/api/Savings/getcustomersavings",
  getPendingSavingsInvitations: "/api/Savings/getpendinginvitations",
  getCustomerCards: "/api/Customer/getcards",
  createPersonalSaving: "/api/PersonalTargetSavings/CreateNewPersonalTargetSavings",
  createFixedLocked: "/api/FixedLockSavings/Create",
  createFixedFlexible: "/api/FixedFlexibleSavings/createfixedflexiblesavings",
  getDashboard: "/api/Customer/dashboard",
  getSavingsTransactions: "/api/Savings/getrecentsavingstransactions",
  getSavingsStatistics: "/api/Savings/statistics",
  changeWithdrawalLimit: "/api/customer/wallets/limit",
  changeCardOnRunningSavings: "/api/savings/card",

  //mkat savings
  createMkatSavings: "/api/savings",
  startSavingsWithdrawal: "/api/savings/startwithdraw",
  completeSavingsWithdrawal: "/api/savings/completewithdraw",
  startCancelSavings: "/api/savings/startcancel",
  completeCancelSavings: "/api/savings/completecancel",
  pauseSavings: "/api/savings/pause",
  resumeSavings: "/api/savings/resume",
  topUp: "/api/savings/topup",
  
  //Group Target Savings
  createGroupTargetSavings: "/api/GroupTargetSavings/creategroupsavings",
  getGroupTargetSavingsById: "/api/GroupTargetSavings/getgroupsavings/:id",
  startGroupTargetSavings: "/api/GroupTargetSavings/startgroupsavings",
  cancelGroupTargetSavings: "/api/GroupTargetSavings/cancelgroupsavings",
  startGroupTargetWithdraw: "/api/GroupTargetSavings/startwithdraw",
  completeGroupTargetWithdraw: "/api/GroupTargetSavings/completewithdraw",
  treatGroupTargetInvitation: "/api/GroupTargetSavings/treatgroupsavingsinvitation",
  pauseGroupTargetSavings: "/api/GroupTargetSavings/pause",
  resumeGroupTargetSavings: "/api/GroupTargetSavings/resume",

  //Group Challenge Savings
  createGroupChallengeSavings: "/api/GroupChallengeSavings/creategroupsavings",
  getGroupChallengeSavingsById: "/api/GroupChallengeSavings/getgroupsavings/:id",
  startGroupChallengeSavings: "/api/GroupChallengeSavings/startgroupsavings",
  cancelGroupChallengeSavings: "/api/GroupChallengeSavings/cancelgroupsavings",
  startGroupChallengeWithdraw: "/api/GroupChallengeSavings/startwithdraw",
  completeGroupChallengeWithdraw: "/api/GroupChallengeSavings/completewithdraw",
  treatGroupChallengeInvitation: "/api/GroupChallengeSavings/treatgroupsavingsinvitation",

  //Group Contributory Savings
  createGroupContributorySavings: "/api/GroupContributorySavings/creategroupsavings",
  getGroupContributorySavingsById: "/api/GroupContributorySavings/getgroupsavings/:id",
  startGroupContributorySavings: "/api/GroupContributorySavings/startgroupsavings",
  cancelGroupContributorySavings: "/api/GroupContributorySavings/cancelgroupsavings",
  treatGroupContributoryInvitation: "/api/GroupContributorySavings/treatgroupsavingsinvitation",

  //
  uploadProfilePicture: "/api/Customer/UploadProfilePicture",
  uploadUtilityBill: "/api/Customer/UploadUtilityBill",
  uploadIdentification: "/api/Customer/UploadIdentification",
  fundWalletWithExistingCard: "/api/Customer/FundWalletWithExistingCard",
  getAllSavings: "/api/Savings/getallsavingstransactions",
  startPersonalWithdrawal: "/api/PersonalTargetSavings/startwithdraw",
  startFixedFlexibleWithdraw: "/api/FixedFlexibleSavings/startwithdraw",
  startFixedLockWithdraw: "/api/FixedLockSavings/startwithdraw",
  completePersonalWithdrawal: "/api/PersonalTargetSavings/completewithdraw",
  completeFixedFlexibleWithdraw: "/api/FixedFlexibleSavings/completewithdraw",
  completeFixedLockWithdraw: "/api/FixedLockSavings/completewithdraw",
  resetPassword: "/api/Customer/ResetCustomerPassword",
  completeResetPassword: "/api/Customer/completeresetpassword",
  changePassword: "/api/Customer/ChangeCustomerPassword",
  startCancelPersonalSavings: "/api/PersonalTargetSavings/startcancel",
  completeCancelPersonalSavings: "/api/PersonalTargetSavings/completecancel",
  pausePersonalTargetSavings: "/api/PersonalTargetSavings/pause",
  resumePersonalTargetSavings: "/api/PersonalTargetSavings/resume",
  startTopUpPersonalTargetSavings: "/api/PersonalTargetSavings/topup",
  startCancelFixedFlexibleSavings: "/api/FixedFlexibleSavings/startcancel",
  completeCancelFixedFlexibleSavings: "/api/FixedFlexibleSavings/completecancel",
  startCancelFixedLockSavings: "/api/FixedLockSavings/startcancel",
  completeCancelFixedLockSavings: "/api/FixedLockSavings/completecancel",
  getSavingsConfiguration: "/api/Savings/getsavingsconfiguration",
  updateProfile: "/api/Customer/updateothercustomerinfo",
  getBankList: "/api/Customer/getbanklist",
  getUserBanks: "/api/Customer/getbankaccount",
  deleteBankAccount: "/api/Customer/deletebankaccount/:id",
  addBankAccount: "/api/Customer/AddBankAccount",
  enquireBankAccount: "/api/Customer/bank/enquiry",
  sendToken: "/api/customer/bank/account/verify/:id",
  verifyAddBankAccount: "/api/customer/bank/account/verify/complete",

  // Investments
  createFundInvestment: "/api/Investments/funds/create",
  createTbillsInvestment: "/api/Investments/tbills/create",
  createTerminstrumentsInvestment: "/api/Investments/terminstruments/create",
  investmentConfiguration: "/api/Investments/configurations",
  getAllInvestments: "/api/Investments",
  getAllInvetstmentTransactions: "/api/Investments/transactions/all",
  getPersonalInvestments: "/api/Investments/me",
  investmentTransactionsForFunds: "/api/Investments/funds/transactions",
  fundMutualFund: "/api/Investments/funds/topup",
  fundInvestmentTbills: "/api/Investments/tbills/topup",
  fundInvestmentFixed: "/api/Investments/terminstruments/topup",
  investmentValuation: "/api/Investments/valuation",
  subAccountInvestmentValuation: "/api/investments/portfolio/:id/valuation",
  investmentRates: "api/investments/investments/rates",
  fundsWithdraw: "/api/Investments/funds/withdraw",
  tbillsTerminate: "/api/Investments/tbills/terminate",
  fixedTerminate: "/api/Investments/terminstruments/terminate",
  fundsTransactionsList: "/api/Investments/funds/transactions",
  tBillsTransactionsList: "/api/Investments/tbills/transactions",
  fixedTransactionsList: "/api/Investments/terminstruments/transactions",
  dollarInvestmentUpload: "/api/FileUpload/upload/10",
  createDollarInvestment: "/api/investments/dollar/create",
  toggleRecurringFunds: "/api/investments/funds/recurrence/toggle",
  createRecurringFundsConfig: "/api/investments/funds/recurrence/config",

  // Bills
  getAllBillers: "/api/billspayment/billers",
  getBillCategories: "/api/billspayment/categories",
  getBillersPerCategory: "/api/billspayment/categories/:id/billers",
  getBillerPaymentItems: "/api/billspayment/billers/:billerId/paymentitems",
  makePurchase: "/api/billspayment/purchases",

  // Cash Transfer
  startCashTransferToBank: "/api/cashtransfer/starttransfertobank",
  completeCashTransferToBank: "/api/cashtransfer/completetransfertobank",

  //Sub-accounts
  getAllSubAccounts: "/api/investments/subaccount",
  createSubAccount: "/api/investments/subaccount",
};

export default urls;
