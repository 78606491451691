import {
  ENQUIRE_BANK_ACCOUNT_START,
  ENQUIRE_BANK_ACCOUNT_SUCCESS,
  ENQUIRE_BANK_ACCOUNT_FAIL,
  ENQUIRE_BANK_ACCOUNT,
} from "./types";

export const enquireBankAccountStart = () => ({
  type: ENQUIRE_BANK_ACCOUNT_START,
});

export const enquireBankAccountSuccess = (payload) => ({
  type: ENQUIRE_BANK_ACCOUNT_SUCCESS,
  payload,
});

export const enquireBankAccountFail = (payload) => ({
  type: ENQUIRE_BANK_ACCOUNT_FAIL,
  payload,
});

export const enquireBankAccount = (payload, meta) => ({
  type: ENQUIRE_BANK_ACCOUNT,
  payload,
  meta,
});

export default {
  enquireBankAccountStart,
  enquireBankAccountSuccess,
  enquireBankAccountFail,
  enquireBankAccount,
};
