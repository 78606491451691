import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Customer } from "services/network";
import { getBankAccountsData } from "../ducks/bankAccounts/actions";
import { getCardsData } from "../ducks/cards/actions";

const initialState = {
  resendVerifyEmailLoading: false,
  resendVerifyEmailError: null,
};

export const resendVerifyEmail = createAsyncThunk(
  "customer/resendVerifyEmail",
  async (payload) => {
    const response = await Customer.resendVerifyEmail(payload);
    return response.data.data;
  }
);

export const completePasswordReset = createAsyncThunk(
  "customer/completePasswordReset",
  async (payload) => {
    const response = await Customer.completeResetPassword(payload);
    return response.data.data;
  }
);

export const deleteBankAccount = createAsyncThunk(
  "customer/deleteBankAccount",
  async (payload, thunkAPI) => {
    const response = await Customer.deleteBankAccount(payload);
    thunkAPI.dispatch(getBankAccountsData());
    return response.data.data;
  }
);

export const deleteCard = createAsyncThunk(
  "customer/deleteCard",
  async (payload, thunkAPI) => {
    const response = await Customer.deleteCard(payload);
    thunkAPI.dispatch(getCardsData());
    return response.data.data;
  }
);

export const changeWithdrawalLimit = createAsyncThunk(
  "customer/changeWithdrawalLimit",
  async (payload) => {
    const response = await Customer.changeWithdrawalLimit(payload);
    return response.data.data;
  }
);

const customer = createSlice({
  name: "customer",
  initialState,
  reducers: {},
  extraReducers: {},
});

export default customer.reducer;
