import React, { Fragment, useContext, useLayoutEffect, useState } from "react";
import PrimaryAccountSummaryCard from "./components/SummaryCard/PrimaryAccountSummaryCard";
import { logo } from "assets/exports";
import TransactHistory from "./components/transactHistory/TransactHistory";
import { connect, useDispatch } from "react-redux";
import {
  getInvestmentTransactionsForFund,
  getAllInvestments,
  getAllFixedTransactions,
  getAllTbillsTransactions,
  getAllSubAccounts,
  getInvestmentRates,
} from "state/slices/investments";
import PrimaryAccountInvestments from "./components/userInvestments/PrimaryAccountInvestments";
import Loading from "shared-components/Loading";
import "./style.scss";
import produce from "immer";
import SubAccountPane from "./components/SubAccountPane";
import AddSubAccountModal from "./components/AddSubAccountModal";
import AddSubAccountSuccessModal from "./components/AddSubAccountSuccessModal";
import InvestmentPortfolioContext from "../../contexts/InvestmentPortfolioContext";
import SubAccountInvestments from "./components/userInvestments/SubAccountInvestments";
import StickyBox from "react-sticky-box";
import { AccountsTab } from "constants/enums";
import SubAccountSummaryCard from "./components/SummaryCard/SubAccountSummaryCard";

const InvestHome = ({ investmentValuationLoading }) => {
  const {
    portfolioId,
    setPortfolio,
    activeAccountTab,
    setActiveAccountTab,
  } = useContext(InvestmentPortfolioContext);

  const dispatch = useDispatch();

  const [state, setState] = useState({
    isAddSubAccountModalVisible: false,
    isAddSubAccountSuccessModalVisible: false,
  });

  const setIsSubAccountModalVisible = (status) => {
    setState(
      produce((draft) => {
        draft.isAddSubAccountModalVisible = status;
      })
    );
  };

  const setIsSubAccountSuccessModalVisible = (status) => {
    setState(
      produce((draft) => {
        draft.isAddSubAccountSuccessModalVisible = status;
      })
    );
  };

  const openAddSubAccountModal = () => setIsSubAccountModalVisible(true);
  const closeAddSubAccountModal = () => setIsSubAccountModalVisible(false);

  const openAddSubAccountSuccessModal = () => {
    setIsSubAccountSuccessModalVisible(true);
  };

  const closeAddSubAccountSuccessModal = () => {
    setIsSubAccountSuccessModalVisible(false);
  };

  useLayoutEffect(() => {
    dispatch(getInvestmentTransactionsForFund());
    dispatch(getAllInvestments());
    dispatch(getAllTbillsTransactions());
    dispatch(getInvestmentTransactionsForFund());
    dispatch(getAllFixedTransactions());
    dispatch(getAllSubAccounts());
    dispatch(getInvestmentRates());
  }, []);

  return investmentValuationLoading ? (
    <div className="flex flex-col justify-center min-screen items-center">
      <div className="flex flex-col justify-center items-center">
        <i className="w-10 mb-4" dangerouslySetInnerHTML={{ __html: logo }} />
        <Loading text="" />
      </div>
    </div>
  ) : (
    <Fragment>
      <div className="px-12">
        <h1 className="text-4xl py-8 font-medium content-header--border content-header--title">
          Investments
          </h1><br />
          {/* <marquee direction = "left">
            <b>Meristem Equity Market Fund</b> - Bid(N): 12.6687, Offer(N): 12.7214, YTD Return: 15.75% &nbsp;&nbsp;
            <b>Meristem Money Market Fund</b> - Bid(N): 10.00, Offer(N): 10.00, Effective Yield: 6.11%, Gross Yield: 7.79%
          </marquee> */}

        <div className="md:flex md:flex-shrink-0 items-start savings-home--wrap justify-between fadeIn mt-10">
          <div className="flex flex-col w-full mr-5 overflow-y-scroll hide-scroll overscroll-contain  h-auto md:min-h-screen">
            {!portfolioId &&
            activeAccountTab === AccountsTab.PRIMARY_ACCOUNT ? (
              <PrimaryAccountSummaryCard />
            ) : (
              <SubAccountSummaryCard />
            )}

            <div className="flex flex-row justify-start items-center space-x-5 mt-3 py-2">
              <button
                type="button"
                className={`tab-button relative text-black mb-4 text-sm text-gray-600 hover:text-gray-500 font-medium py-2 focus:outline-none transition-colors duration-200 ${
                  activeAccountTab === AccountsTab.PRIMARY_ACCOUNT
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  setActiveAccountTab(AccountsTab.PRIMARY_ACCOUNT);
                  setPortfolio(null);
                }}
              >
                Primary Account
              </button>

              <button
                type="button"
                className={`tab-button relative text-black mb-4 text-sm text-gray-600 hover:text-gray-500 font-medium py-2 focus:outline-none transition-colors duration-200 ${
                  activeAccountTab === AccountsTab.SUB_ACCOUNTS ? "active" : ""
                }`}
                onClick={() => setActiveAccountTab(AccountsTab.SUB_ACCOUNTS)}
              >
                Sub Accounts
              </button>
            </div>

            <div className="has-scrollbar">
              {activeAccountTab === AccountsTab.PRIMARY_ACCOUNT ? (
                <PrimaryAccountInvestments />
              ) : activeAccountTab === AccountsTab.SUB_ACCOUNTS ? (
                <SubAccountInvestments />
              ) : null}
            </div>
          </div>

          <StickyBox offsetTop={30} offsetBottom={0} className="w-full">
            {activeAccountTab === AccountsTab.SUB_ACCOUNTS ? (
              <SubAccountPane openAddSubAccountModal={openAddSubAccountModal} />
            ) : (
              <TransactHistory />
            )}
          </StickyBox>
        </div>
      </div>

      <AddSubAccountModal
        onClose={closeAddSubAccountModal}
        onSuccess={openAddSubAccountSuccessModal}
        isVisible={state.isAddSubAccountModalVisible}
      />

      <AddSubAccountSuccessModal
        isVisible={state.isAddSubAccountSuccessModalVisible}
        onClose={closeAddSubAccountSuccessModal}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  investmentValuationLoading: state.investments.investmentValuationLoading,
  investmentValuationData: state.investments.investmentValuationData,
});

export default connect(mapStateToProps)(InvestHome);
