import React, { useState } from "react";
import classNames from "classnames";

const RadioCard = (props) => {
    const { checked, name, label, value, handlePaymentMethodChange } = props;

    const onChange = (e) => {
        if (e.target.checked) {
            handlePaymentMethodChange(e.target.value);
        }
    };

    const radioCardClasses = classNames({
        "border rounded-md font-semibold radio-card": true,
        "radio-card--checked": checked,
    });

    return (
        <label className={radioCardClasses}>
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            <p>{label}</p>
        </label>
    );
};

export default RadioCard;
