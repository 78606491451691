// import React, { useState } from "react";
// import { add, master } from "../../imageLinks";
// import cardBrandIcons from "constants/cardBrandIcons";
// import { ADD_NEW_CARD } from "constants/strings";

// const items = [
//   {
//     img: add,
//     text: "Add New Card",
//   },
//   {
//     img: master,
//     text: "Mastercard - 0233",
//   },
// ];
// const InvestmentDropdown = (props) => {
//   const [view, setView] = useState(false);
//   const [itemView, setItem] = useState("Select a card");

//   const clickView = (value) => {
//     setView(!value);
//   };

//   const settingOfMyCard = (val) => {
//     props.settingCard(val);
//   };

//   return (
//     <React.Fragment>
//       <div className="fund-dropdown">
//         {/* <div className="select-option" onClick={() => toggleList()}> */}
//         <div className="select-option" onClick={() => clickView(view)}>
//           <div className="buddy-dropdown-title flex flex-row"> {itemView}</div>
//           <div
//             className="buddy-dropdown-icon"
//             dangerouslySetInnerHTML={{
//               __html:
//                 '<svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 7L13.9282 0.25H0.0717969L7 7Z" fill="black"/></svg>',
//             }}
//           ></div>
//         </div>
//       </div>
//       {/* listOpen && */}
//       {view ? (
//         <ul className="buddy-dropdown-list basic-dropdown">
//           {/* <ul className="buddy-dropdown-list basic-dropdown" onClick={e => e.stopPropagation()}> */}
//           {props.cardsData.map((item, i) => (
//             <React.Fragment key={i}>
//               <li
//                 className="buddy-dropdown-item flex hover:bg-gray-100 flex-row items-center"
//                 onClick={() => {
//                   setItem(`${item.bank}`);
//                   setView(false);
//                   settingOfMyCard(item.id);
//                 }}
//                 key={"newCard"}
//               >
//                  <p className="dropdown-details">
//                   <span className="dropdown-item--title text-black text-opacity-25">
//                     {item.bank}
//                   </span>
//                 </p>
//               </li> 
//                {/* <li
//                   className="buddy-dropdown-item flex hover:bg-gray-100 flex-row items-center"
//                   onClick={() => {
//                     setItem(`${item.bank}`);
//                     setView(false);
//                     settingOfMyCard(item.id);
//                   }}
//                   key={"newCard"}
//                 >
              
//                   <p className="flex flex-row dropdown-details items-center">
//                     {item.value !== ADD_NEW_CARD && (
                    
//                       <img src={cardBrandIcons['placeholder']} className="brand-icon" />
//                     )}
//                     <span className="dropdown-item--title text-semi-bold ml-2">
//                       {item.label}
//                     </span>
//                   </p>
//                 </li>  */}
//             </React.Fragment>
//           ))}
//           {items.length === 0 ? (
//             <li className="no-result">No results found</li>
//           ) : null}
//         </ul>
//       ) : null}
//     </React.Fragment>
//   );
// };
// export default InvestmentDropdown;



// // import React, { Fragment, useEffect, useRef, useState } from "react";
// // import DropdownArrowDownIcon from "shared-components/svgs/DropdownArrowDownIcon";
// // import { connect } from "react-redux";
// // import PropTypes from "prop-types";
// // import { ADD_NEW_CARD } from "constants/strings";
// // import cardBrandIcons from "constants/cardBrandIcons";


// // const InvestmentDropdown = ({
// //   cards,
// //   onSelectItem: onSelectItemUserDefined,
// //   selectedItemId,
// // }) => {
// //   const mounted = useRef();
// //   const [isDropdownOpen, setDropdownOpen] = useState(false);
// //   const openDropdown = () => setDropdownOpen(true);
// //   const closeDropdown = () => setDropdownOpen(false);

// //   useEffect(() => {
// //     if (!mounted.current) {
// //       mounted.current = true;
// //     } else {
// //       setTimeout(() => {
// //         if (isDropdownOpen) {
// //           window.addEventListener("click", closeDropdown);
// //         } else {
// //           window.removeEventListener("click", closeDropdown);
// //         }
// //       }, 0);
// //     }
// //     return () => {
// //       window.removeEventListener("click", closeDropdown);
// //     };
// //   });

// //   const mappedCards = cards.map((card) => ({
// //     label: card.bank,
// //     value: card.id,
// //     brand: card.brand,
// //   }));
// //   const addNewCard = { label: "Add New Card", value: ADD_NEW_CARD };
// //   // const otherPaymentOptions = {  label: "Other Payment Options ", value: ADD_NEW_CARD  };
// //   //const items = [].concat(otherPaymentOptions).concat(addNewCard).concat(mappedCards);

// //   const items = [].concat(addNewCard).concat(mappedCards);
// //   const selectedItem = items.find((item) => item.value === selectedItemId);
// //   const dropdownTitle = selectedItem ? selectedItem.label : "Select Option";

// //   const onSelectItem = (item) => {
// //     onSelectItemUserDefined(item);
// //     closeDropdown();
// //   };

// //   const DropdownItem = ({ item }) => (
// //     <li
// //       className="buddy-dropdown-item payment-card-dropdown"
// //       onClick={() => onSelectItem(item)}
// //     >
  
// //       <p className="flex flex-row dropdown-details items-center">
// //         {item.value !== ADD_NEW_CARD && (
         
// //           <img src={cardBrandIcons['placeholder']} className="brand-icon" />
// //         )}
// //         <span className="dropdown-item--title text-semi-bold ml-2">
// //           {item.label}
// //         </span>
// //       </p>
// //     </li>
// //   );

// //   return (
// //     <Fragment>
// //       <div className="fund-dropdown">
// //         <div className="select-option" onClick={openDropdown}>
// //           <div className="buddy-dropdown-title">{dropdownTitle}</div>
// //           <div className="buddy-dropdown-icon">
// //             <DropdownArrowDownIcon />
// //           </div>
// //         </div>
// //       </div>

// //       {isDropdownOpen && (
// //         <ul
// //           className="buddy-dropdown-list basic-dropdown"
// //           onClick={(e) => e.stopPropagation()}
// //         >
// //           <Fragment>
// //             {items.map((item, i) => (
// //               <Fragment key={i}>
// //                 <DropdownItem item={item} />
// //               </Fragment>
// //             ))}
// //           </Fragment>
// //         </ul>
// //       )}
// //     </Fragment>
// //   );
// // };

// // InvestmentDropdown.propTypes = {
// //   onSelectItem: PropTypes.func.isRequired,
// //   selectedItemId: PropTypes.string.isRequired,
// // };

// // const mapStateToProps = (state) => ({
// //   cards: state.cards.data,
// // });

// // export default connect(mapStateToProps)(InvestmentDropdown);


import React, { Fragment, useEffect, useRef, useState } from "react";
import DropdownArrowDownIcon from "shared-components/svgs/DropdownArrowDownIcon";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ADD_NEW_CARD } from "constants/strings";
import cardBrandIcons from "constants/cardBrandIcons";

const InvestmentDropdown = ({
  cards,
  onSelectItem: onSelectItemUserDefined,
  selectedItemId,
}) => {
  const mounted = useRef();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const openDropdown = () => setDropdownOpen(true);
  const closeDropdown = () => setDropdownOpen(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      setTimeout(() => {
        if (isDropdownOpen) {
          window.addEventListener("click", closeDropdown);
        } else {
          window.removeEventListener("click", closeDropdown);
        }
      }, 0);
    }
    return () => {
      window.removeEventListener("click", closeDropdown);
    };
  });

  const mappedCards = cards.map((card) => ({
    label: card.bank,
    value: card.id,
    brand: card.brand,
  }));
 // const addNewCard = { label: "Add New Card", value: ADD_NEW_CARD };
 //  const otherPaymentOptions = {  label: "Other Payment Options ", value: ADD_NEW_CARD  };
 // const items = [].concat(otherPaymentOptions).concat(mappedCards);
  const items = [].concat(mappedCards);

 // const items = [].concat(addNewCard).concat(mappedCards);
  const selectedItem = items.find((item) => item.value === selectedItemId);
  const dropdownTitle = selectedItem ? selectedItem.label : "Select Option";

  const onSelectItem = (item) => {
    onSelectItemUserDefined(item);
    closeDropdown();
  };




  const DropdownItem = ({ item }) => (
    <li
      className="buddy-dropdown-item payment-card-dropdown"
      onClick={() => onSelectItem(item)}
    >
  
      <p className="flex flex-row dropdown-details items-center">
        {item.value !== ADD_NEW_CARD && (
         
          <img src={cardBrandIcons['placeholder']} className="brand-icon" />
        )}
        <span className="dropdown-item--title text-semi-bold ml-2">
          {item.label}
        </span>
      </p>
    </li>
  );

  return (
    <Fragment>
      <div className="fund-dropdown">
        <div className="select-option" onClick={openDropdown}>
          <div className="buddy-dropdown-title">{dropdownTitle}</div>
          <div className="buddy-dropdown-icon">
            <DropdownArrowDownIcon />
          </div>
        </div>
      </div>

      {isDropdownOpen && (
        <ul
          className="buddy-dropdown-list basic-dropdown"
          onClick={(e) => e.stopPropagation()}
        >
          <Fragment>
            {items.map((item, i) => (
              <Fragment key={i}>
                <DropdownItem item={item} />
              </Fragment>
            ))}
          </Fragment>
        </ul>
      )}
    </Fragment>
  );
};

InvestmentDropdown.propTypes = {
  onSelectItem: PropTypes.func.isRequired,
  selectedItemId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  cards: state.cards.data,
});

export default connect(mapStateToProps)(InvestmentDropdown);
