import React, { useContext } from "react";
import { walletIcon, outflowIcon, inflowIcon } from "assets/exports";
import classNames from "classnames";

import { connect } from "react-redux";
import { formatCurrency } from "utils";
import AddBankContext from "contexts/AddBankContext";
import Toggle from "shared-components/Toggle/Toggle";

const WalletSummary = ({ dashboard }) => {
  const { account, handleAccount } = useContext(AddBankContext);

  return (
    <div
      className={classNames({
        "flex flex-col card card-black flex-summary margin-x--zero": true,
        "bg-dollar": account,
      })}
    >
      <div className="flex justify-between items-center card-margin--x">
        <div className="text-white">
          <h5 className="text-xs mb-2 font-medium text-faded">
            Wallet Balance
          </h5>
          <h2 className="summary-balance font-medium">
            {account
              ? `$${formatCurrency(dashboard.netAssetValueDollar)}`
              : `₦${formatCurrency(dashboard.walletBalance)}`
              }
          </h2>
        </div>
        <div className="flex flex-col items-center text-white">
          <span
            className="wallet-main--icon"
            dangerouslySetInnerHTML={{ __html: walletIcon }}
          />
        </div>
      </div>
      <div className="flex items-center balance-card">
        <span className="pdr-right-10 text-sm">Naira Account</span>

        <Toggle onClick={handleAccount} className={account ? "toggle" : ""} />
        <span className="pdr-left-10 text-sm">Dollar Account</span>
      </div>
      {/* <div className="flex justify-between items-center card-margin--x mini-summary">
        <div className="text-white flex">
          <span
            className="mr-2"
            dangerouslySetInnerHTML={{ __html: inflowIcon }}
          />
          <div>
            <h5 className="text-xs mb-2 font-medium text-faded">
              Monthly Inflow
            </h5>
            <h2 className="summary-balance font-medium">
              {account
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(0)
                : new Intl.NumberFormat("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  }).format(dashboard.totalMonthlyInflow)}
            </h2>
          </div>
        </div>
        <div className="text-white flex">
          <span
            className="mr-2"
            dangerouslySetInnerHTML={{ __html: outflowIcon }}
          />
          <div>
            <h5 className="text-xs mb-2 font-medium text-faded">
              Monthly Outflow
            </h5>
            <h2 className="summary-balance font-medium">
              {account
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(0)
                : new Intl.NumberFormat("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  }).format(dashboard.totalMonthlyOutFlow)}
            </h2>
          </div>
        </div>
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard.data,
});

export default connect(mapStateToProps)(WalletSummary);
