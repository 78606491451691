import React, { useContext } from "react";
import Loading from "shared-components/Loading";
import MeristemLogo from "assets/opengraph.png";
import EmptyCard from "pages/dashboard/components/DashboardHome/components/EmptyCard";
import { MdCreateNewFolder, MdPerson } from "react-icons/md";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./style.scss";
import { useSelector } from "react-redux";
import InvestmentPortfolioContext from "contexts/InvestmentPortfolioContext";

const SubAccountPane = ({ openAddSubAccountModal }) => {
  const { setPortfolio } = useContext(InvestmentPortfolioContext);

  const { subAccountsLoading, subAccountsData } = useSelector(
    (state) => state.investments
  );

  const handleSetPortfolio = (e, subAccount) => {
    e.preventDefault();
    setPortfolio(subAccount);
  };

  return (
    <div className="card-shadow-radius w-full bg-white border px-6 sm:px-8 py-5 mb-10 overflow-hidden border border-gray-200">
      <div className="flex flex-row justify-between items-start mb-6">
        <h1 className="text-base font-medium">Sub Accounts</h1>
        <button
          type="button"
          className="inline-flex flex-col items-center hover:opacity-75 py-1 px-4 text-center leading-loose focus:outline-none text-xs text-black rounded transition-opacity duration-200 ease-in"
          onClick={openAddSubAccountModal}
        >
          <MdCreateNewFolder className="text-4xl text-black" />
          <span className="bg-gray-900 text-white leading-4 px-1 mt-1 rounded transition-colors duration-200 ease-in">
            Add Sub-accounts
          </span>
        </button>
      </div>

      <div className="hide-scroll overflow-y-scroll hide-scroll pb-64 h-screen">
        <div className="sub-account-pane h-full w-full">
          {subAccountsLoading ? (
            <div className="flex flex-col justify-center items-center data-loader-component">
              <img className="w-16 h-16 mb-2" src={MeristemLogo} alt="" />
              <Loading text="" />
            </div>
          ) : subAccountsData.length === 0 ? (
            <EmptyCard
              title="Nothing here yet"
              message="Create a sub account today"
            />
          ) : (
            <div>
              {subAccountsData.map((subAccount) => (
                <a
                  href="#/"
                  key={subAccount.portfolioName}
                  className="flex flex-row justify-between items-center py-1 my-3"
                  onClick={(e) => handleSetPortfolio(e, subAccount)}
                >
                  <div className="flex flex-row justify-start items-center">
                    <div className="inline-flex justify-center items-center w-8 h-8 mr-2 rounded-full bg-pink-400 mr-3 overflow-hidden">
                      <MdPerson
                        size={50}
                        className="text-white text-opacity-50 -m-3 mt-0"
                      />
                    </div>

                    <div className="flex flex-col">
                      <h2 className="font-medium">{subAccount.name}</h2>
                      <h4 className="text-gray-200 text-xs  pt-1">
                        {subAccount.portfolioName}
                      </h4>
                    </div>
                  </div>

                  <div className="h-1 w-3 bg-pink-400 rounded" />
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubAccountPane;
