import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import produce from "immer";
import ReactApexChart from "react-apexcharts";
import numeral from "numeral";

const InvestmentStatistics = ({ investmentValuation }) => {
  const fundCost = investmentValuation !== null ? investmentValuation.fundCost : 0
  const fundValue = investmentValuation !== null ? investmentValuation.fundValue : 0
  const fundReturn = investmentValuation !== null ? investmentValuation.fundReturn : 0
  const totalFixedDepositPrincipal = investmentValuation !== null ? investmentValuation.totalFixedDepositPrincipal : 0
  const totalFixedDepositIncome = investmentValuation !== null ? investmentValuation.totalFixedDepositIncome : 0
  const totalTBillDiscountedValue = investmentValuation !== null ? investmentValuation.totalTBillDiscountedValue : 0
  const totalTBillInterestValue = investmentValuation !== null ? investmentValuation.totalTBillInterestValue : 0
  const equityCost = investmentValuation !== null ? investmentValuation.equityCost : 0
  const equityReturn = investmentValuation !== null ? investmentValuation.equityReturn : 0
  const bondCost = investmentValuation !== null ? investmentValuation.bondCost : 0
  const bondReturn = investmentValuation !== null ? investmentValuation.bondReturn : 0

  

  const principal = [
    fundCost,
    totalFixedDepositPrincipal,
    totalTBillDiscountedValue,
    equityCost,
    bondCost,
  ];

  const returns = [
    fundValue-fundCost,
    totalFixedDepositIncome,
    totalTBillInterestValue,
    equityReturn,
    bondReturn
  ];

  const [state, setState] = useState({
    series: [
      {
        name: "Principal",
        data: principal,
      },
      {
        name: "Returns",
        data: returns,
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 10,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => numeral(value).format("0,0.00"),
        },
      },
      xaxis: {
        type: "string",
        categories: ["Fund", "Fixed Deposit", "Treasury Bills", "Bonds", "Equities"],
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  });

  useEffect(() => {
    const fundCost = investmentValuation !== null ? investmentValuation.fundCost : 0
    const fundValue = investmentValuation !== null ? investmentValuation.fundValue : 0
    const fundReturn = investmentValuation !== null ? investmentValuation.fundReturn : 0
    const totalFixedDepositPrincipal = investmentValuation !== null ? investmentValuation.totalFixedDepositPrincipal : 0
    const totalFixedDepositIncome = investmentValuation !== null ? investmentValuation.totalFixedDepositIncome : 0
    const totalTBillDiscountedValue = investmentValuation !== null ? investmentValuation.totalTBillDiscountedValue : 0
    const totalTBillInterestValue = investmentValuation !== null ? investmentValuation.totalTBillInterestValue : 0
    const equityCost = investmentValuation !== null ? investmentValuation.equityCost : 0
    const equityReturn = investmentValuation !== null ? investmentValuation.equityReturn : 0
    const bondCost = investmentValuation !== null ? investmentValuation.bondCost : 0
    const bondReturn = investmentValuation !== null ? investmentValuation.bondReturn : 0
  

    setState(
      produce((draft) => {
        draft.series[0].data = [
          fundCost,
          totalFixedDepositPrincipal,
          totalTBillDiscountedValue,
          equityCost,
          bondCost
        ];
        draft.series[1].data = [
          fundValue - fundCost,
          totalFixedDepositIncome,
          totalTBillInterestValue,
          equityReturn,
          bondReturn
        ];
      })
    );
  }, [investmentValuation]);

  return (
    <ReactApexChart
      className="w-full"
      options={state.options}
      series={state.series}
      type="bar"
      height={250}
    />
  );
};

const mapStateToProps = (state) => ({
  investmentValuation: state.investments.investmentValuationData,
});

export default connect(mapStateToProps)(InvestmentStatistics);
