import React from "react";
import { emptyBank } from "assets/exports";

const EmptyBank = ({ showAddBankModal }) => {
  return (
    <div className="auth-modal flex flex-col items-center bg-white fadeIn login-fieldset empty-modal">
      <div className="flex flex-col items-center mb-5">
        <i className="mb-4" dangerouslySetInnerHTML={{ __html: emptyBank }} />
      </div>
      <p className="text-center card-header text-gray-500 font-normal leading-normal">
        Saving and investing money is important, and so is cashing out. Enter
        your bank details for withdrawals.
      </p>

      <div className="nav-buttons flex justify-center">
        <button
          className="px-6 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm"
          onClick={showAddBankModal}
        >
          Add Bank Details
        </button>
      </div>
    </div>
  );
};

export default EmptyBank;
