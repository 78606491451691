// import React, { useEffect } from "react";
// import CloseModalIcon from "shared-components/svgs/CloseModalIcon";
// import PaymentMethodIcon from "assets/img/paymentMethodIcon.svg";
// import SuccessIcon from "assets/img/success.svg";
// import FailedIcon from "assets/img/failedDoc.svg";
// import MasterCardLogo from "assets/img/mastercard.svg";
// import VisaLogo from "assets/img/visa.svg";
// import VerveLogo from "assets/img/verve.svg";
// import RadioCard from "./RadioCard";
// import { useState } from "react";

// import Select from "react-select";
// import { formatCurrency } from "utils";
// import InvestmentDropdown from "pages/investment/components/investmentDropdown/InvestmentDropdown";
// import Loading from "shared-components/Loading";
// import { useHistory } from "react-router-dom";
// import { selectStyles } from "./ProviderForms/ProviderForm";

// const ChoosePaymentMethodModal = (props) => {
//   // PROPS
//   const {
//     setVisible,
//     cards,
//     card: cardId,
//     setCard,
//     loading,
//     paymentError,
//     paymentSuccess,
//     handlePayment,
//     resetPurchase,
//   } = props;

//   // STATE
//   const [paymentMethod, setPaymentMethod] = useState(null);

//   const [modalPageIndex, setModalPageIndex] = useState(1);
//   const [error, setError] = useState("");

//   const history = useHistory();

//   const cardIconMap = {
//     visa: VisaLogo,
//     mastercard: MasterCardLogo,
//     verve: VerveLogo,
//   };

//   // CONTROLLERS

//   const closeModal = () => {
//     setVisible(false);
//   };

//   const handleClose = () => {
//     resetPurchase();
//     history.replace("/dashboard/wallet/bill-payment/1");
//   };

//   const handlePrevClick = () => {
//     setModalPageIndex(modalPageIndex - 1);
//   };

//   const handleCardChange = (card) => {
//     setCard(card.value);
//   };

//   const handlePaymentMethodChange = (paymentMethod) => {
//     setPaymentMethod(paymentMethod);
//     setModalPageIndex(modalPageIndex + 1);
//   };

//   const renderErrorMessage = () => {
//     const errorMessage = paymentError?.message;

//     if (errorMessage) {
//       setError(errorMessage);

//       setTimeout(() => {
//         setError("");
//       }, 5000);
//     }
//   };

//   useEffect(() => {
//     renderErrorMessage();
//   }, [paymentError]);

//   // RENDER HELPERS
//   const renderFirstPage = () => {
//     return (
//       <div className="mt-16">
//         <RadioCard
//           name="payment-method"
//           label="Use Debit Card"
//           value="card"
//           handlePaymentMethodChange={handlePaymentMethodChange}
//         />
//         <div className="py-4"></div>
//         <RadioCard
//           name="payment-method"
//           label="Use Wallet"
//           value="wallet"
//           handlePaymentMethodChange={handlePaymentMethodChange}
//         />
//       </div>
//     );
//   };

//   const renderSecondPage = () => {
//     const options = props.cards.map((card) => {
//       const cardIcon = cardIconMap[card.brand];

//       return {
//         value: card.id,
//         label: (
//           <div className="flex items-center color-gray-500 font-bold">
//             <img className="mr-4 h-6" src={cardIcon} />
//             {card.brand} - {card.lastFourDigit}
//           </div>
//         ),
//       };
//     });

//     return (
//       <div className="mt-12">
//         {paymentMethod === "card" && (
//           <RadioCard
//             checked={paymentMethod === "card"}
//             name="payment-method"
//             label="Use Debit Card"
//             value="card"
//             handlePaymentMethodChange={handlePaymentMethodChange}
//           />
//         )}
//         <div className="pt-4"></div>
//         {paymentMethod === "wallet" && (
//           <RadioCard
//             checked={paymentMethod === "wallet"}
//             name="payment-method"
//             label="Use Wallet"
//             value="wallet"
//             handlePaymentMethodChange={handlePaymentMethodChange}
//           />
//         )}
//         {paymentMethod === "card" && (
//           <div>
//             <div>
//               <small>Select card</small>
//             </div>
//             <Select
//               styles={selectStyles}
//               options={options}
//               onChange={handleCardChange}
//             />
//             {error ? (
//               <div className="flex justify-center my-4">
//                 <small className="text-red-700 bg-red-200 px-3 py-2">
//                   {error}
//                 </small>
//               </div>
//             ) : null}
//             <div className="mt-8 flex justify-center">
//               {loading ? (
//                 <Loading />
//               ) : (
//                 <button
//                   disabled={loading || !Boolean(cardId)}
//                   className="mt-6 w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm"
//                   onClick={handlePayment}
//                 >
//                   Pay Now
//                 </button>
//               )}
//             </div>
//           </div>
//         )}
//         {paymentMethod === "wallet" && (
//           <div className="mt-4 text-center">
//             <small>
//               You have{" "}
//               <span className="inline-block text-orange-700 mx-2">
//                 ₦{formatCurrency(props.dashboard.walletBalance)}
//               </span>
//               in your wallet
//             </small>
//             <div className="mt-8 flex justify-center">
//               {loading ? (
//                 <Loading />
//               ) : (
//                 <button
//                   disabled={loading}
//                   className="mt-6 w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm"
//                   onClick={handlePayment}
//                 >
//                   Pay Now
//                 </button>
//               )}
//             </div>
//           </div>
//         )}
//       </div>
//     );
//   };

//   const renderSuccess = () => {
//     return (
//       <>
//         <div className="flex flex-col items-center mb-0">
//           <i className="w-20 mb-4">
//             <img src={SuccessIcon} alt="" />
//           </i>

//           <h1 className="text-2xl font-medium mb-2">Success</h1>
//           <p className="text-center text-gray-500 leading-normal">
//             Payment successful.
//           </p>
//         </div>

//         <div
//           className="w-40 text-center wealth-buddy--cta leading-loose bg-wb-primary text-white rounded-sm mt-8"
//           onClick={() => {
//             handleClose();
//             setModalPageIndex(1);
//           }}
//         >
//           Done
//         </div>
//       </>
//     );
//   };

//   const renderFailure = () => {
//     return (
//       <>
//         <div className="flex flex-col items-center mb-0">
//           <i className="w-20 mb-4">
//             <img src={FailedIcon} alt="" />
//           </i>

//           <h1 className="text-2xl font-medium mb-2">Failure</h1>
//           <p className="text-center text-gray-500 leading-normal">
//             Payment was not successful.
//           </p>
//         </div>

//         <div
//           className="w-40 text-center wealth-buddy--cta leading-loose bg-wb-primary text-white rounded-sm mt-8"
//           onClick={() => {
//             handleClose();
//             setModalPageIndex(1);
//           }}
//         >
//           Done
//         </div>
//       </>
//     );
//   };

//   return (
//     <div
//       className="modal fixed inset-0 bg-wb-overlay flex justify-center items-center modal-active"
//       onClick={closeModal}
//     >
//       <div
//         className="flex flex-col items-center bg-white p-16 fadeIn rounded-lg relative w-3/5 lg:w-2/5"
//         onClick={(e) => e.stopPropagation()}
//       >
//         <span
//           className="closeModal"
//           onClick={paymentSuccess ? handleClose : closeModal}
//         >
//           <CloseModalIcon />
//         </span>
//         {modalPageIndex !== 1 && !paymentSuccess && (
//           <span className="prev" onClick={handlePrevClick}>
//             Prev
//           </span>
//         )}

//         {paymentSuccess && modalPageIndex !== 1 ? 
//           renderSuccess()
//          : !paymentSuccess && modalPageIndex !== 1 ? renderFailure() :(
//           <>
//             <div className="mt-8 mb-4">
//               <img src={PaymentMethodIcon} alt="Payment Method Icon" />
//             </div>
//             <div className="mt-4 mb-4 text-center">
//               <p className="text-2xl font-medium mb-2">Choose Payment Method</p>
//               <p className="text-center lg:w-3/4 mx-auto text-gray-500 leading-normal">
//                 Select a payment method (wallet or card).
//               </p>
//             </div>

//             {modalPageIndex === 1 && renderFirstPage()}
//             {modalPageIndex === 2 && renderSecondPage()}
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ChoosePaymentMethodModal;

import React, { useEffect } from "react";
import CloseModalIcon from "shared-components/svgs/CloseModalIcon";
import PaymentMethodIcon from "assets/img/paymentMethodIcon.svg";
import SuccessIcon from "assets/img/success.svg";
import FailedIcon from "assets/img/failedDoc.svg";
import MasterCardLogo from "assets/img/mastercard.svg";
import VisaLogo from "assets/img/visa.svg";
import VerveLogo from "assets/img/verve.svg";
import RadioCard from "./RadioCard";
import { useState } from "react";

import Select from "react-select";
import { formatCurrency } from "utils";
import InvestmentDropdown from "pages/investment/components/investmentDropdown/InvestmentDropdown";
import Loading from "shared-components/Loading";
import { useHistory } from "react-router-dom";
import { selectStyles } from "./ProviderForms/ProviderForm";

const ChoosePaymentMethodModal = (props) => {
  // PROPS
  const {
    setVisible,
    cards,
    card: cardId,
    setCard,
    loading,
    paymentError,
    paymentSuccess,
    handlePayment,
    resetPurchase,
  } = props;

  // STATE
  const [paymentMethod, setPaymentMethod] = useState(null);

  const [modalPageIndex, setModalPageIndex] = useState(1);
  const [error, setError] = useState("");

  const history = useHistory();

  const cardIconMap = {
    visa: VisaLogo,
    mastercard: MasterCardLogo,
    verve: VerveLogo,
  };

  // CONTROLLERS

  const closeModal = () => {
    setVisible(false);
  };

  const handleClose = () => {
    resetPurchase();
    history.replace("/dashboard/wallet/bill-payment/1");
  };

  const handlePrevClick = () => {
    setModalPageIndex(modalPageIndex - 1);
  };

  const handleCardChange = (card) => {
    setCard(card.value);
  };

  const handlePaymentMethodChange = (paymentMethod) => {
    setPaymentMethod(paymentMethod);
    setModalPageIndex(modalPageIndex + 1);
  };

  const renderErrorMessage = () => {
    const errorMessage = paymentError?.message;

    if (errorMessage) {
      setError(errorMessage);

      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  useEffect(() => {
    renderErrorMessage();
  }, [paymentError]);

  // RENDER HELPERS
  const renderFirstPage = () => {
    return (
      <div className="mt-16">
        <RadioCard
          name="payment-method"
          label="Payment Options"
          value="card"
          handlePaymentMethodChange={handlePaymentMethodChange}
        />
        <div className="py-4"></div>
        <RadioCard
          name="payment-method"
          label="Use Wallet"
          value="wallet"
          handlePaymentMethodChange={handlePaymentMethodChange}
        />
      </div>
    );
  };

  const renderSecondPage = () => {
    const options = props.cards.map((card) => {
      const cardIcon = cardIconMap[card.brand];

      return {
        value: card.id,
        label: (
          <div className="flex items-center color-gray-500 font-bold">
            <img className="mr-4 h-6" src={cardIcon} />
            {card.brand} - {card.lastFourDigit}
          </div>
        ),
      };
    });

    return (
      <div className="mt-12">
        {paymentMethod === "card" && (
          <RadioCard
            checked={paymentMethod === "card"}
            name="payment-method"
            label="Payment Options"
            value="card"
            handlePaymentMethodChange={handlePaymentMethodChange}
          />
        )}
        <div className="pt-4"></div>
        {paymentMethod === "wallet" && (
          <RadioCard
            checked={paymentMethod === "wallet"}
            name="payment-method"
            label="Use Wallet"
            value="wallet"
            handlePaymentMethodChange={handlePaymentMethodChange}
          />
        )}
        {paymentMethod === "card" && (
          <div>
            <div>
              <small>Select card</small>
            </div>
            <Select
              styles={selectStyles}
              options={options}
              onChange={handleCardChange}
            />
            {error ? (
              <div className="flex justify-center my-4">
                <small className="text-red-700 bg-red-200 px-3 py-2"> {error} </small>
              </div>
            ) : null }
            <div className="mt-8 flex justify-center">
              {loading ? (
                <Loading />
              ) : (
                <button
                  disabled={loading || !Boolean(cardId)}
                  className="mt-6 w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm"
                  onClick={handlePayment}
                >
                  Pay Now
                </button>
              )}
            </div>
          </div>
        )}
        {paymentMethod === "wallet" && (
          <div className="mt-4 text-center">
            <small>
              You have{" "}
              <span className="inline-block text-orange-700 mx-2">
                ₦{formatCurrency(props.dashboard.walletBalance)}
              </span>
              in your wallet
            </small>
            <div className="mt-8 flex justify-center">
              {loading ? (
                <Loading />
              ) : (
                <button
                  disabled={loading}
                  className="mt-6 w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm"
                  onClick={handlePayment}
                >
                  Pay Now
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderSuccess = () => {
    return (
      <>
        <div className="flex flex-col items-center mb-0">
          <i className="w-20 mb-4">
            <img src={SuccessIcon} alt="" />
          </i>

          <h1 className="text-2xl font-medium mb-2">Payment Success</h1>
          <p className="text-center text-gray-500 leading-normal">
            Transaction successfully completed.
          </p>
        </div>

        <div
          className="w-40 text-center wealth-buddy--cta leading-loose bg-wb-primary text-white rounded-sm mt-8"
          onClick={() => {
            handleClose();
            setModalPageIndex(1);
          }}
        >
          Done
        </div>
      </>
    );
  };

  const renderFailure = () => {
    return (
      <>
        <div className="flex flex-col items-center mb-0">
          <i className="w-20 mb-4"><img src={FailedIcon} alt="" /></i>
          <h1 className="text-2xl font-medium mb-2">Failed</h1>
          <p className="text-center text-gray-500 leading-normal">
           {paymentError.message || `Sorry this transaction could not be completed at this time.`}
          </p>
          {/* {console.log(`${JSON.stringify(paymentError)}`)} */}
        </div>
        <div
          className="w-40 text-center wealth-buddy--cta leading-loose bg-wb-primary text-white rounded-sm mt-8"
          onClick={() => {
            handleClose();
          }}
        > Ok</div>
      </>
    );
  };
  

  return (
    <div
      className="modal fixed inset-0 bg-wb-overlay flex justify-center items-center modal-active"
      onClick={closeModal}
    >
      <div
        className="flex flex-col items-center bg-white p-16 fadeIn rounded-lg relative w-3/5 lg:w-2/5"
        onClick={(e) => e.stopPropagation()}
      >
        <span
          className="closeModal"
          onClick={paymentSuccess ? handleClose : closeModal}
        >
          <CloseModalIcon />
        </span>
        {modalPageIndex !== 1 && !paymentSuccess &&
        (
          <span className="prev" onClick={handlePrevClick}>back</span>
        )}

        {paymentSuccess && modalPageIndex !== 1 ? (
          renderSuccess()
        )  : paymentError && modalPageIndex !== 1  && !paymentSuccess ? (
          renderFailure() 
        ): (
          <>
            <div className="mt-8 mb-4">
              <img src={PaymentMethodIcon} alt="Payment Method Icon" />
            </div>
            <div className="mt-4 mb-4 text-center">
              <p className="text-2xl font-medium mb-2">Choose Payment Method</p>
              <p className="text-center lg:w-3/4 mx-auto text-gray-500 leading-normal">
                Select a payment method (wallet or card).
              </p>
            </div>

            {modalPageIndex === 1 && renderFirstPage()}
            {modalPageIndex === 2 && renderSecondPage()}
          </>
        )}
      </div>
    </div>
  );
};

export default ChoosePaymentMethodModal;
