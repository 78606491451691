export const ENQUIRE_BANK_ACCOUNT_START = "ENQUIRE_BANK_ACCOUNT_START";
export const ENQUIRE_BANK_ACCOUNT_SUCCESS = "ENQUIRE_BANK_ACCOUNT_SUCCESS";
export const ENQUIRE_BANK_ACCOUNT_FAIL = "ENQUIRE_BANK_ACCOUNT_FAIL";
export const ENQUIRE_BANK_ACCOUNT = "ENQUIRE_BANK_ACCOUNT";

export default {
  ENQUIRE_BANK_ACCOUNT_START,
  ENQUIRE_BANK_ACCOUNT_SUCCESS,
  ENQUIRE_BANK_ACCOUNT_FAIL,
  ENQUIRE_BANK_ACCOUNT,
};
