import React from "react";
import { Link } from "react-router-dom";
import InvestmentBarsIcon from "assets/img/investbars.svg";
import { formatCurrency } from "utils";

const InvestmentCard = ({
  navigationPayload,
  companyName,
  currentValue,
  currency,
  portPercentage,
}) => (
  <Link
    to={{
      pathname: "/dashboard/investment/view-investment",
      ...navigationPayload,
    }}
    className="card flex flex-col h-full m-0 mb-0 py-5 justify-center px-4 content-center items-center border-gray-100"
  >
    <div className="flex flex-row content-center items-center">
      <img src={InvestmentBarsIcon} alt="" className="rounded-sm w-16 h-16" />

      <div className="text-black font-bold text-base ml-2 w-100">
        {companyName}
      </div>
    </div>

    <div className="flex flex-row mt-2 pt-2 justify-between border-t border-gray-200 w-full"  >
      <div>
        <p className="text-black text-sm">Current Value</p>
        <p className="text-black mt-1 font-bold text-base">
        {currency} {formatCurrency(currentValue.toFixed(2))}
        </p>
      </div>
      <div>
        <div className="text-black text-sm">Returns</div>
        <div className="font-bold mt-1 text-right text-base text-gray-200">
          { 
            companyName == "MERISTEM MONEY MARKET FUND" ||  companyName == "MERISTEM EQUITY MARKET FUND"
              ? `Open`
              : `${portPercentage.toFixed(2)}%`
              
              }   
          
        </div>
      </div>



    </div>
  </Link>
);

export default InvestmentCard;
