import React, { Fragment, useEffect } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { AddNewCard } from "assets/exports";
import CardIcon from "assets/img/cardIcon.png";
import PaymentCardDropdown from "./PaymentCardDropDown";
import CloseModalIcon from "shared-components/svgs/CloseModalIcon";
import { closeModalOnOutsideClick } from "utils";
import { Link } from "react-router-dom";

const ChangeRunningSavingsCardModal = ({
  formValues,
  isVisible,
  onSubmit: handleOnSubmit,
  close,
}) => {
  useEffect(() => {
    isVisible && closeModalOnOutsideClick(close);
  }, [isVisible]);

  const initialValues = {
    cardId : formValues.cardId,
    savingsID : formValues.savingsID,
    savingsType : formValues.savingsType,
  };

  const validationSchema = yup.object().shape({
    cardId: yup.string().label("Card").required(),
  });

  return (
    isVisible && (
      <div className="modal fixed inset-0 bg-wb-overlay flex justify-center items-center modal-active">
        <div className="auth-modal flex flex-col items-center bg-white fadeIn login-fieldset">
          <span className="closeModal" onClick={close}>
            <CloseModalIcon />
          </span>

          <div className="flex flex-col items-center mb-0">
            <i className="w-20 mb-4">
              <img src={CardIcon} alt="" />
            </i>
            <h1 className="text-2xl font-medium mb-2">Change Savings Card</h1>
            <p className="text-center text-gray-500 leading-normal">
              Update to a new card on this recurrent savings.
            </p>
          </div>

          <Fragment>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnMount={true}
              onSubmit={handleOnSubmit}
            >
              {({ handleSubmit, isValid, setFieldValue, values }) => {
                return (
                  <Form
                    className="flex flex-col items-center"
                    onSubmit={handleSubmit}
                  >
                    <div className="mt-6 w-full">
                      <label className="block text-xs mb-2">Select Card</label>
                      <div className="fieldset">
                        <PaymentCardDropdown
                          selectedItemId={values.cardId}
                          onSelectItem={(item) =>
                            setFieldValue("cardId", item.value)
                          }
                        />
                      </div>
                    </div>

                    <Link
                      to="/dashboard/settings?page=cards"
                      className="add-new--card flex items-center justify-center"
                    >
                      <span
                        className="add-new--icon"
                        dangerouslySetInnerHTML={{ __html: AddNewCard }}
                      />
                      Add a New Card
                    </Link>

                    <div className="nav-buttons flex justify-center">
                     

                      <button
                        type="submit"
                        className="w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm"
                        onSubmit={handleSubmit}
                        disabled={!isValid}
                      >
                        Proceed
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Fragment>
        </div>
      </div>
    )
  );
};

export default ChangeRunningSavingsCardModal;
