import axios from "services/api";
import urls from "../../constants/urls";

export const getAllBillers = () => {
    return axios.get(urls.getAllBillers);
};

export const getBillCategories = () => {
    return axios.get(urls.getBillCategories);
};
export const getBillersPerCategory = (id) => {
    return axios.get(urls.getBillersPerCategory.replace(":id", id));
};

export const getBillPaymentItems = (billerId) => {
    return axios.get(urls.getBillerPaymentItems.replace(":billerId", billerId));
};

export const makePurchase = (data) => {
    return axios.post(urls.makePurchase, data);
};
