
import React from "react";
import { useSelector } from "react-redux";
import SummaryCard from "./SummaryCard";
import _, { forEach } from "lodash";

const dollarCurrencyRate = 415.31
const PrimaryAccountSummaryCard = () => {
  const { investmentValuationData } = useSelector((state) => state.investments);


  // const fundReturns =
  //   investmentValuationData.fundValue ||
  //   investmentValuationData.fundValue - investmentValuationData.fundCost ||
  //   investmentValuationData.fundCost;


  const totalFixedDepositCarryingtValueUSDInvestments = 
   _.sumBy(
    investmentValuationData.fixedDeposits,
    (item) => {
      if(item.carryingValue.currency.toString() === "USD"){
        return item.carryingValue.amount
      }
    }
  ) ||  0; 

  const totalFixedDepositCarryingValueNGNInvestments = 
  _.sumBy(
    investmentValuationData.fixedDeposits,
    (item) => {
      if(item.carryingValue.currency.toString() === "NGN"){
        return item.carryingValue.amount
      }
    }
  ) ||  0; 

  const totalFixedDepositPrincipalUSDInvestments = 
  _.sumBy(
    investmentValuationData.fixedDeposits,
    (item) => {
      if(item.carryingValue.currency.toString() === "USD"){
        return (item.principalBalance.amount/dollarCurrencyRate)
      }
    }
  ) || 0; 
  
  const totalFixedDepositPrincipalNGNInvestments = 
  _.sumBy(
    investmentValuationData.fixedDeposits,
    (item) => {
      if(item.carryingValue.currency.toString() === "NGN"){
        return item.principalBalance.amount
      }
    }
  ) || 0;

  const totalFixedDeposistReturnsUSDInvestments = 
   _.sumBy(
    investmentValuationData.fixedDeposits,
    (item) => {
      if(item.carryingValue.currency.toString() === "USD"){
        return (item.interestLessTaxes.amount/dollarCurrencyRate)
      }
    }
  ) || 0;

  const totalFixedDeposistReturnsNGNInvestments = 
 _.sumBy(
    investmentValuationData.fixedDeposits,
    (item) => {
      if(item.carryingValue.currency.toString() === "NGN"){
        return item.interestLessTaxes.amount
      }else{
        return 0
      }
    }
  ) ||  0


  const totalCarryingValueNGN = 
    investmentValuationData.fundCost + (investmentValuationData.fundValue - investmentValuationData.fundCost)
   + totalFixedDepositCarryingValueNGNInvestments 
   + (investmentValuationData.totalTBillDiscountedValue + investmentValuationData.totalTBillInterestValue)
   + investmentValuationData.equityValue
   + investmentValuationData.bondValue
    

  const totalCarryingValueUSD = totalFixedDepositCarryingtValueUSDInvestments;


  const fundReturns =
    investmentValuationData.fundValue - investmentValuationData.fundCost
    || 0

    const totalCapital = 
    ( investmentValuationData.fundCost +
        investmentValuationData.totalFixedDepositPrincipal +
        investmentValuationData.totalTBillDiscountedValue +
        investmentValuationData.equityCost +
        investmentValuationData.bondCost
    ) || 0

  const totalCapitalNGN = 
   ( investmentValuationData.fundCost +
    totalFixedDepositCarryingValueNGNInvestments +
    investmentValuationData.totalTBillDiscountedValue +
      investmentValuationData.equityCost
  ) || 0

    const totalCapitalUSD =  totalFixedDepositPrincipalUSDInvestments;

  const totalReturns = 
     (fundReturns + totalCapital +
      investmentValuationData.totalFixedDepositIncome +
      investmentValuationData.totalTBillInterestValue) +
      investmentValuationData.equityReturn +
      investmentValuationData.bondReturn
    || 0

    const totalReturnsNGN = 
     ( fundReturns + totalFixedDeposistReturnsNGNInvestments +
        investmentValuationData.totalTBillInterestValue) +
       investmentValuationData.equityReturn +
       investmentValuationData.bondReturn
    || 0

    const totalReturnsUSD = totalFixedDeposistReturnsUSDInvestments;


  const totalInterests = 
   ( fundReturns + investmentValuationData.totalFixedDepositIncome +
      investmentValuationData.totalTBillInterestValue
      + investmentValuationData.equityReturn
      + investmentValuationData.bondReturn)
  || 0

  return (
    
    <SummaryCard
      totalCapital={totalCapital}
      totalCapitalNGN={totalCapitalNGN}
      totalReturnsNGN={totalReturnsNGN}
      totalCapitalUSD={totalCapitalUSD}
      totalReturnsUSD={totalReturnsUSD}
      totalReturns={totalReturns}
      totalInterests={totalInterests}
      totalCarryingValueNGN={totalCarryingValueNGN}
      totalCarryingValueUSD={totalCarryingValueUSD}
    />
  );
};

export default PrimaryAccountSummaryCard;






