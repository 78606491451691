import EmptyCard from "../../../EmptyCard";
import React, { Fragment, useState } from "react";
import GeneralUpdateItem from "./GeneralUpdateItem";
import { connect } from "react-redux";
import NotificationUpdateDetailsModal from "./components/NotificationUpdateDetailsModal";

const GeneralUpdate = ({ updateFeeds = [] }) => {
  const [state, setState] = useState({
    isDetailsModalVisible: false,
    notification: {},
  });

  const openDetailsModal = (e, notification) => {
    e.preventDefault();
    setState({
      isDetailsModalVisible: true,
      notification: notification,
    });
  };

  const closeDetailsModal = () => {
    setState({
      isDetailsModalVisible: false,
      notification: {},
    });
  };

  return (
    <Fragment>
      {updateFeeds.length === 0 ? (
        <EmptyCard
          title="Nothing to see here yet."
          message="Find any of your savings plan to and see you your transactions history here."
        />
      ) : (
        <Fragment>
          {updateFeeds.map((notification, index) => (
            <a
              href="/#"
              key={index}
              className="transaction-wealth--padding"
              onClick={(e) => openDetailsModal(e, notification)}
            >
              <GeneralUpdateItem
                heading={notification.heading}
                content={notification.content}
                key={index}
                index={index}
              />
            </a>
          ))}
        </Fragment>
      )}

      <NotificationUpdateDetailsModal
        isVisible={state.isDetailsModalVisible}
        onClose={closeDetailsModal}
        notification={state.notification}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  updateFeeds: state.dashboard.data.updateFeeds,
});

export default connect(mapStateToProps)(GeneralUpdate);
