import React, { useContext } from "react";
import WalletBalanceCard from "./components/WalletBalanceCard/WalletBalanceCard";
import BillsList from "./components/BillsList/BillsList";
import TransactionHistory from "./components/TransactionHistory";
// import TransactionHistory from "./components/TransactionHistory/TransactionHistory";
// import { WalletContext } from "./components/WalletProvider/WalletProvider";
import AddBankContext from "contexts/AddBankContext";
import "./wallet.scss";
import WalletSummary from "./components/WalletSummary";
import { FaChevronRight } from "react-icons/fa";
import { arrowRight } from "assets/exports";

const WalletHome = (props) => {
  const {
    billCategories,
    openFundWalletModal,
    startWithdrawFundsIntent,
  } = useContext(AddBankContext);

  const chevronRight = `<svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 12.5825L1.51406 14L9 7L1.51406 0L0 1.4175L5.96719 7L0 12.5825Z" fill="#CACACA"/>
  </svg>`;

  return (
    <div className="px-12">
      <h1 className="text-4xl py-8 font-medium content-header--border content-header--title">
        Wallet and Bills Payment
      </h1>
      <div className="flex flex-col lg:flex-row  mt-10">
        <div className="sm:w-full lg:w-1/2 flex flex-col">
          {/* <WalletBalanceCard /> */}
          <WalletSummary />
          <div className="flex flex-col md:flex-row flex-summary  justify-between items-center content-center my-6">
            <div
              className="wallet-card-action p-6 bg-white shadow rounded-lg flex items-center pointer flex-1 w-full md:w-0"
              onClick={openFundWalletModal}
            >
              <span className="wallet-card-action">
                <img
                  src={require("../../assets/img/fund-investment.svg")}
                  alt="wealth-buddy"
                  className="pr-3"
                />
                <p className="text-base self-center">Fund Wallet</p>
              </span>
              <div
                className="click-chevron-icon"
                dangerouslySetInnerHTML={{
                  __html: chevronRight,
                }}
              />
            </div>
            <div
              className="wallet-card-action p-6 bg-white shadow rounded-lg flex flex-1 mt-6 md:mt-0 ml-0 md:ml-4 items-center pointer w-full md:w-0"
              onClick={startWithdrawFundsIntent}
            >
              <span className="wallet-card-action">
                <img
                  src={require("../../assets/img/withdraw.svg")}
                  alt="wealth-buddy"
                  className="pr-3"
                />
                <p className="text-base self-center">Withdraw Funds</p>
              </span>
              <div
                className="click-chevron-icon"
                dangerouslySetInnerHTML={{
                  __html: chevronRight,
                }}
              />
            </div>
          </div>
          <div>
            <p className="text-1xl mb-1 font-medium card-header">
              Bill Payment
            </p>
            <BillsList billCategories={billCategories} />
          </div>
        </div>
        <div className="lg:ml-10 sm:w-full lg:w-1/2">
          <TransactionHistory />
        </div>
      </div>
    </div>
  );
};

export default WalletHome;
