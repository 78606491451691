export const VERIFY_ADD_BANK_ACCOUNT_START = "VERIFY_ADD_BANK_ACCOUNT_START";
export const VERIFY_ADD_BANK_ACCOUNT_SUCCESS = "VERIFY_ADD_BANK_ACCOUNT_SUCCESS";
export const VERIFY_ADD_BANK_ACCOUNT_FAIL = "VERIFY_ADD_BANK_ACCOUNT_FAIL";
export const VERIFY_ADD_BANK_ACCOUNT = "VERIFY_ADD_BANK_ACCOUNT";

export default {
  VERIFY_ADD_BANK_ACCOUNT_START,
  VERIFY_ADD_BANK_ACCOUNT_SUCCESS,
  VERIFY_ADD_BANK_ACCOUNT_FAIL,
  VERIFY_ADD_BANK_ACCOUNT,
};
