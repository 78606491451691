import React from "react";
import { NavLink } from "react-router-dom";

const SidePane = (props) => {
  const { sidePaneItems, handleTitleChange, handleCategoryChange } = props;

  return (
    <div className="sidepane bg-white h-full card">
      {sidePaneItems
        ? sidePaneItems.map((item) => {
            return (
              <NavLink
                className="bg-transparent cursor-pointer"
                to={{
                  pathname: `/dashboard/wallet/bill-payment/${item.categoryid}`,
                  hash: `all-categories/${item.categoryname}`,
                }}
                onClick={() => {
                  handleCategoryChange(item.categoryid);
                  handleTitleChange(item.categoryname);
                }}
                activeClassName="active-tab"
              >
                <p
                  className="px-8 py-6 hover:bg-gray-100"
                  key={item.categoryid}
                >
                  {item.categoryname}
                </p>
              </NavLink>
            );
          })
        : ""}
    </div>
  );
};

export default SidePane;
