import React, { Fragment, useEffect, useState } from "react";
import Icon from "assets/img/passwordIcon.png";
import "../styles.css";
import Loading from "shared-components/Loading";
import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import PasswordStrengthMeter from "./components/PasswordStrengthMeter";
import produce from "immer";
import { completePasswordReset as completePasswordResetRequest } from "state/slices/customer";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPassword = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  const [state, setState] = useState({
    isResetting: false,
    isResetSuccessful: false,
    resetError: null,
  });

  const initialValues = {
    token: query.get("resetPasswordToken"),
    email: query.get("email"),
    password: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .label("Password")
      .min(6)
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password should contain an uppercase, special character, and a minimum of 6 characters"
      ),
    confirmPassword: yup
      .string()
      .label("Password")
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required(),
  });

  const handleOnSubmit = async (formValues) => {
    setState(
      produce((draft) => {
        draft.isResetting = true;
      })
    );

    const resultAction = await dispatch(
      completePasswordResetRequest(formValues)
    );
    if (completePasswordResetRequest.fulfilled.match(resultAction)) {
      setState(
        produce((draft) => {
          draft.isResetting = false;
          draft.isResetSuccessful = true;
        })
      );
    } else {
      setState(
        produce((draft) => {
          draft.isResetting = false;
          draft.resetError = resultAction.error.message;
        })
      );
    }
  };

  return (
    <section className="h-screen w-full setup-screens flex flex-col justify-center items-center leafy-bg">
      <div className="flex flex-col items-center bg-white fadeIn login-fieldset auth-modal">
        <div className="flex flex-col items-center mb-6 setup-success">
          <i className="w-10 mb-4">
            <img src={Icon} alt="" />
          </i>
          {!state.isResetSuccessful ? (
            <Fragment>
              <h1 className="text-xl font-medium mb-2">Reset Password</h1>
              <p className="text-gray-500 text-center leading-normal">
                Enter a password that will be easy for you and difficult for
                others to remember
              </p>
            </Fragment>
          ) : (
            <Fragment>
              <h1 className="text-2xl font-medium">Success</h1>
              <p className="text-center mt-3 text-gray-500 leading-normal">
                Your password has been reset successfully
              </p>
            </Fragment>
          )}
        </div>

        {!state.isResetSuccessful ? (
          <Fragment>
            {state.isResetting ? (
              <Loading text="Resetting your password" />
            ) : (
              <Fragment>
                {state.resetError && (
                  <div className="w-72 mb-8 text-xs text-left">
                    <p className="w-full p-3 bg-red-200 text-red-700 rounded text-center font-medium">
                      {state.resetError}
                    </p>
                  </div>
                )}

                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  validateOnMount={true}
                  onSubmit={handleOnSubmit}
                >
                  {({ handleSubmit, isValid }) => {
                    return (
                      <Fragment>
                        <Form
                          className="flex flex-col items-center"
                          onSubmit={handleSubmit}
                        >
                          <fieldset className="mb-5">
                            <label className="block text-xs mb-2">
                              Password
                            </label>
                            <Field
                              placeholder="Enter Password"
                              type="password"
                              id="password"
                              name="password"
                              className="block w-72 text-xs p-3 border border-gray-400 rounded"
                            />
                            <span className="text-xs text-gray-500 leading-4 mt-2 block">
                              Password should contain Uppercase,Special
                              character, and number Minimum character 6
                            </span>
                            <PasswordStrengthMeter />
                            <ErrorMessage
                              name="password"
                              render={(errorMessage) => (
                                <p className="label-error--text mt-3 text-xs color-red font-medium text-center bg-red-200">
                                  {errorMessage}
                                </p>
                              )}
                            />
                          </fieldset>

                          <fieldset className="mb-5">
                            <label className="block text-xs mb-2">
                              Confirm Password
                            </label>
                            <Field
                              placeholder="Re-Enter Password"
                              type="password"
                              id="confirmPassword"
                              name="confirmPassword"
                              className="block w-72 text-xs p-3 border border-gray-400 rounded"
                            />
                          </fieldset>

                          <button
                            type="submit"
                            className="mt-6 w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm"
                            onSubmit={handleSubmit}
                            disabled={!isValid}
                          >
                            Reset Password
                          </button>
                        </Form>

                        <div className="mt-8 ">
                          <p className="text-sm text-gray-500">
                            Remember password?{" "}
                            <Link to="/auth/login" className="text-wb-primary">
                              Sign In
                            </Link>
                          </p>
                        </div>
                      </Fragment>
                    );
                  }}
                </Formik>
              </Fragment>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <Link
              to="/auth/login"
              className="mt-4 w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm"
            >
              Proceed to Login
            </Link>

            <div className="mt-8 ">
              <p className="text-sm text-gray-500">
                Don't have an account?{" "}
                <Link to="/auth/sign-up" className="text-wb-primary">
                  Sign Up
                </Link>
              </p>
            </div>
          </Fragment>
        )}
      </div>
    </section>
  );
};

export default ResetPassword;
