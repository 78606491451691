export const GET_BANK_LIST_DATA_START = "GET_BANK_LIST_DATA_START";
export const GET_BANK_LIST_DATA_SUCCESS = "GET_BANK_LIST_DATA_SUCCESS";
export const GET_BANK_LIST_DATA_FAIL = "GET_BANK_LIST_DATA_FAIL";
export const GET_BANK_LIST_DATA = "GET_BANK_LIST_DATA";

export default {
  GET_BANK_LIST_DATA_START,
  GET_BANK_LIST_DATA_SUCCESS,
  GET_BANK_LIST_DATA_FAIL,
  GET_BANK_LIST_DATA,
};
