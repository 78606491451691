import React, { Fragment, useEffect } from "react";
import BvnIcon from "assets/img/bvn.png";
import Loading from "shared-components/Loading";
import CloseModalIcon from "shared-components/svgs/CloseModalIcon";
import { closeModalOnOutsideClick } from "utils";
import * as yup from "yup";
import { Form, Formik } from "formik";
import ReactCodeInput from "react-code-input";

const initialValues = {
  otp: "",
};

const validationSchema = yup.object().shape({
  otp: yup.string().label("OTP").length(6).required(),
});

const VerifyBvnOtpModal = ({
  isVisible,
  close,
  verifyBvnOtpLoading,
  verifyBvnOtpError,
  confirmBvnOtp: handleOnSubmit,
}) => {
  useEffect(() => {
    isVisible && closeModalOnOutsideClick(close);
  }, [0]);

  return (
    isVisible && (
      <div className="modal fixed inset-0 bg-wb-overlay flex justify-center items-center modal-active">
        <div className="auth-modal flex flex-col items-center bg-white fadeIn login-fieldset">
          <span className="closeModal" onClick={close}>
            <CloseModalIcon />
          </span>

          <Fragment>
            <div className="flex flex-col items-center mb-0">
              <i className="w-20 mb-4">
                <img src={BvnIcon} alt="" />
              </i>
              <h1 className="text-xl font-medium mb-2">Confirm your BVN</h1>
              <p className="text-gray-500 text-center leading-normal">
                We sent a 6 digit code to the phone number and email associated with your
                BVN, please enter it below
              </p>
            </div>

            {verifyBvnOtpLoading ? (
              <div className="flex flex-col items-center mt-8">
                <Loading text="Verifying" />
              </div>
            ) : (
              <Fragment>
                {verifyBvnOtpError && (
                  <div className="w-72 mb-8 text-xs text-left mt-6">
                    <p className="w-full p-3 bg-red-200 text-red-700 rounded text-center font-medium">
                      {verifyBvnOtpError}
                    </p>
                  </div>
                )}

                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  validateOnMount={true}
                  onSubmit={handleOnSubmit}
                >
                  {({
                    handleSubmit,
                    isValid,
                    handleBlur,
                    setFieldValue,
                    values,
                  }) => {
                    return (
                      <Form
                        className="flex flex-col items-center"
                        onSubmit={handleSubmit}
                      >
                        <fieldset className="mt-6 is-six--code">
                          <ReactCodeInput
                            type="number"
                            name="number"
                            placeholder="*"
                            fields={6}
                            value={values.otp}
                            onChange={(value) => setFieldValue("otp", value)}
                            onBlur={handleBlur}
                            inputMode="numeric"
                          />
                        </fieldset>

                        <div className="nav-buttons flex justify-center">
                          <div
                            onClick={close}
                            className=" w-40 border-b text-center bg-white leading-loose border-wb-primary text-wb-primary mr-3 border wealth-buddy--cta text-white rounded-sm"
                          >
                            Back
                          </div>
                          <button
                            type="submit"
                            className="w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm"
                            onSubmit={handleSubmit}
                            disabled={!isValid}
                          >
                            Confirm
                          </button>
                        </div>

                        <div className="mt-8 ">
                          <p className="text-sm text-gray-500 text-center">
                            Did not receive a SMS?{" "}
                            <a
                              className="text-wb-primary"
                              href="/"
                              onClick={() => null}
                            >
                              Resend OTP
                            </a>
                          </p><br /><br />
                          <p className="text-xs text-gray-500 text-center">
                            If you do not get an OTP in 10 minutes, send an email to crm@wealthbuddy.ng 
                          </p>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    )
  );
};

export default VerifyBvnOtpModal;
