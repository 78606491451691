import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";
import { Bills } from "services/network";
import { getDashboardData } from "../ducks/dashboard/actions";

const initialState = {
  // GET ALL BILLERS
  allBillersData: [],
  allBillersLoading: false,
  allBillersError: null,

  // GET BILL CATEGORIES
  allBillCategoriesData: [],
  allBillCategoriesLoading: false,
  allBillCategoriesError: null,

  // GET ALL BILLERS PER CATEGORY
  allBillersPerCategoryData: [],
  allBillersPerCategoryLoading: false,
  allBillersPerCategoryError: null,

  // GET ALL PAYMENT ITEMS FOR A BILLER
  allPaymentItemsPerBillerData: [],
  allPaymentItemsPerBillerLoading: false,
  allPaymentItemsPerBillerError: null,
  // GET BILL PAYMENT STATUS
  billPaymentStatusData: {},
  billPaymentStatusLoading: false,
  billPaymentStatusError: null,
  // GET ALL TRANSACTIONS
  allTransactionsData: [],
  allTransactionsLoading: false,
  allTransactionsError: null,
  // PAY FOR A BILL
  purchaseBillData: {},
  purcahseBillLoading: false,
  purcahseBillError: null,
  // VALIDATE CUSTOMER PAYMENT
};

export const getAllBillers = createAsyncThunk(
  "billspayment/billers",
  async () => {
    const response = await Bills.getAllBillers();
    return response.data.billers;
  }
);

export const getBillCategories = createAsyncThunk(
  "billspayment/categories",
  async () => {
    const response = await Bills.getBillCategories();
    return response.data.categorys;
  }
);

export const getBillersPerCategory = createAsyncThunk(
  "billspayment/categories/:id/billers",
  async (categoryId) => {
    const response = await Bills.getBillersPerCategory(categoryId);
    return response.data.billers;
  }
);

export const getBillPaymentItems = createAsyncThunk(
  "billspayment/billers/{{billerId}}/paymentitems",
  async (billerId) => {
    const response = await Bills.getBillPaymentItems(billerId);
    return response.data;
  }
);

export const makePurchase = createAsyncThunk(
  "billspayment/purchases",
  async (data, thunkAPI) => {
    const response = await Bills.makePurchase(data);
    thunkAPI.dispatch(getDashboardData());
    return response.data;
  }
);

export const resetMakePurchase = createAction("billspayment/purchase/clear");

const billPaymentSlice = createSlice({
  name: "bills",
  initialState,
  extraReducers: {
    [getAllBillers.pending]: (state) => {
      state.allBillersLoading = true;
      state.allBillersError = null;
    },
    [getAllBillers.fulfilled]: (state, action) => {
      state.allBillersLoading = false;
      state.allBillersError = null;
      state.allBillersData = action.payload;
    },
    [getAllBillers.rejected]: (state, action) => {
      state.allBillersData = [];
      state.allBillersLoading = true;
      state.allBillersError = action.error;
    },

    // GET ALL BILLS CATEGORY
    [getBillCategories.pending]: (state) => {
      state.allBillCategoriesLoading = true;
      state.allBillCategoriesError = null;
    },
    [getBillCategories.fulfilled]: (state, action) => {
      state.allBillCategoriesLoading = false;
      state.allBillCategoriesData = action.payload;
      state.allBillCategoriesError = null;
    },
    [getBillCategories.rejected]: (state, action) => {
      state.allBillCategoriesLoading = true;
      state.allBillCategoriesData = [];
      state.allBillCategoriesError = action.error;
    },

    // GET BILLERS PER CATEGORY
    [getBillersPerCategory.pending]: (state) => {
      state.allBillersPerCategoryLoading = true;
      state.allBillersPerCategoryError = null;
    },
    [getBillersPerCategory.fulfilled]: (state, action) => {
      state.allBillersPerCategoryData = action.payload;
      state.allBillersPerCategoryLoading = false;
      state.allBillersPerCategoryError = null;
    },
    [getBillersPerCategory.rejected]: (state, action) => {
      state.allBillersPerCategoryData = [];
      state.allBillersPerCategoryLoading = true;
      state.allBillersPerCategoryError = action.error;
    },

    // GET BILLER PAYMENT ITEMS
    [getBillPaymentItems.pending]: (state) => {
      state.billPaymentItemsLoading = true;
    },
    [getBillPaymentItems.fulfilled]: (state, action) => {
      state.billPaymentItemsLoading = false;
      state.billPaymentItemsData = action.payload.paymentitems;
      state.billPaymentItemsError = null;
    },
    [getBillPaymentItems.rejected]: (state, action) => {
      state.billPaymentItemsLoading = false;
      state.billPaymentItemsData = null;
      state.billPaymentItemsError = action.error;
    },

    // MAKE PURCHASES
    [makePurchase.pending]: (state) => {
      state.makePurchaseLoading = true;
    },
    [makePurchase.fulfilled]: (state, action) => {
      state.makePurchaseLoading = false;
      state.makePurchaseData = action.payload;
    },
    [makePurchase.rejected]: (state, action) => {
      state.makePurchaseLoading = false;
      state.makePurchaseData = null;
      state.makePurchaseError = action.error;
    },
    [resetMakePurchase]: (state) => {
      state.makePurchaseLoading = false;
      state.makePurchaseData = null;
      state.makePurchaseError = null;
    },
  },
});

export default billPaymentSlice.reducer;
