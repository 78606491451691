import { call, put, takeLatest } from "redux-saga/effects";
import { ENQUIRE_BANK_ACCOUNT } from "./types";
import {
  enquireBankAccountStart,
  enquireBankAccountSuccess,
  enquireBankAccountFail,
} from "./actions";
import { Customer } from "services/network";

function* operation({ payload, meta }) {
  yield put(enquireBankAccountStart());

  try {
    const response = yield call(Customer.enquireBankAccount, payload);
    let { data } = response.data;
    meta.markAsValid();
    yield put(enquireBankAccountSuccess(data));
  } catch (error) {
    yield put(enquireBankAccountFail(error.message));
  }
}

export default function* saga() {
  yield takeLatest(ENQUIRE_BANK_ACCOUNT, operation);
}
