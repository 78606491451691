import React, { useState } from "react";
import SavingsStatistics from "./components/SavingsStatistics";
import InvestmentStatistics from "./components/InvestmentStatistics";

const DashboardAnalysis = () => {
  const [showInvestment, setShowInvestment] = useState(false);

  return (
    <div className="flex flex-col card w-full">
      <div className="flex flex-row justify-between">
        <h1 className="text-4xl mb-4 font-medium card-header">Analytics</h1>

        <div className="flex flex-row justify-between">
          <span className="text-xs text-gray-300 font-semi-bold mr-2">
            Savings
          </span>
          <div className="pretty p-switch p-fill">
            <input
              type="checkbox"
              name="analyticsView"
              defaultChecked={showInvestment}
              onClick={(e) => setShowInvestment(!showInvestment)}
            />
            <div className="state">
              <label />
            </div>
          </div>
          <span className="text-xs text-gray-300 font-semi-bold ml-2">
            Investment
          </span>
        </div>
      </div>

      <div className="flex-grow flex justify-center items-center">
        {!showInvestment ? <SavingsStatistics /> : <InvestmentStatistics />}
     
      </div>
    </div>
  );
};

export default DashboardAnalysis;
