export const COMPLETE_VERIFY_EMAIL_START = "COMPLETE_VERIFY_EMAIL_START";
export const COMPLETE_VERIFY_EMAIL_SUCCESS = "COMPLETE_VERIFY_EMAIL_SUCCESS";
export const COMPLETE_VERIFY_EMAIL_FAIL = "COMPLETE_VERIFY_EMAIL_FAIL";
export const COMPLETE_VERIFY_EMAIL = "COMPLETE_VERIFY_EMAIL";

export default {
  COMPLETE_VERIFY_EMAIL_START,
  COMPLETE_VERIFY_EMAIL_SUCCESS,
  COMPLETE_VERIFY_EMAIL_FAIL,
  COMPLETE_VERIFY_EMAIL,
};
