export const SAVE_PROFILE_START = "SAVE_PROFILE_START";
export const SAVE_PROFILE_SUCCESS = "SAVE_PROFILE_SUCCESS";
export const SAVE_PROFILE_FAIL = "SAVE_PROFILE_FAIL";
export const SAVE_PROFILE = "SAVE_PROFILE";

export default {
  SAVE_PROFILE_START,
  SAVE_PROFILE_SUCCESS,
  SAVE_PROFILE_FAIL,
  SAVE_PROFILE,
};
