import axios from "services/api";
import urls from "../../constants/urls";

export const mkatSavings = (data) => {
  return axios.post(urls.createMkatSavings, data);
}; 

export const startSavingsWithdrawal = (data) => {
  return axios.post(urls.startSavingsWithdrawal, data);
};

export const completeSavingsWithdrawal = (data) => {
  return axios.post(urls.completeSavingsWithdrawal, data);
};

export const startCancelSavings = (savingsID) => {
  return axios.post(urls.startCancelSavings, { savingsID });
};

export const completeCancelSavings = (savingsID) => {
  return axios.post(urls.completeCancelSavings, { savingsID });
};



