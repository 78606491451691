import React from "react";
import { arrowIcon } from "assets/exports";
import { getHumanDate } from "utilities";
import personalSavings from "assets/img/personalIcon.png";
import fixedSavings from "assets/img/fixedIcon.png";
import groupSavings from "assets/img/groupIcon.png";
import fixedFlexSavings from "assets/img/fixedFlex.png";
import RunningSavingsCardModal from "../ChangeRunningSavingsCardModal";
import { Link } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import "toasted-notes/src/styles.css";
import { connect } from "react-redux";
import { formatCurrency } from "utils";
import classNames from "classnames";
import "./styles.scss";
import { SavingsStatus, SavingsType } from "constants/enums";
import { FaCheckCircle, FaExclamationCircle, FaPauseCircle, FaRegCreditCard, FaPlayCircle } from "react-icons/fa";
import notification from "node -v/notification";
import { store } from "react-notifications-component";
import ReactTooltip from "react-tooltip";

const MainDetails = ({
  savings,
  startCancelSavings,
  startCancelLoading,
  startWithdrawSavings,
  startTopUpSavings,
  pausePersonalTargetSavings,
  ChangeRunningSavingsCard,
  isPausePersonalTargetSavingsLoading,
  resumePersonalTargetSavings,
  isResumePersonalTargetSavingsLoading,
}) => {
  const checkProgressPercentageValueIfNotExceed100 = (savings.amountSaved / savings.amountToSave) * 100
  const progressPercentage = (checkProgressPercentageValueIfNotExceed100 > 100) ? 100 : checkProgressPercentageValueIfNotExceed100
  const maturityDateReached = new Date() > new Date(savings.estimatedTerminationDate);

  const canBeCancelled =
    savings.savingsType !== SavingsType.FixedLockSavings &&
    savings.status !== SavingsStatus.Finished;

  const canBeWithdrawn = savings.amountSaved > 0 || maturityDateReached;

  const savingsIcon = () => {
    switch (savings.savingsType) {
      case 1:
        return personalSavings;
      case 2:
        return fixedSavings;
      case 3:
        return fixedFlexSavings;
      default:
        return groupSavings;
    }
  };


  return (
    <>
    <div className="card card-padding min-card w-full flex flex-col justify-between">
      <div className="flex flex-col flex-summary white-card card-x--padding">
        <div className="savings-heading">
          <Link to="/dashboard/savings" className="back font-medium">
            <span
              className="back"
              dangerouslySetInnerHTML={{ __html: arrowIcon }}
            />
            Back
            </Link>
             <img src={savingsIcon()} alt="" /> 
                              
        </div>
        <div className="flex flex-col justify-center items-center card-margin--x pb-14">
          <div className=" flex flex-col justify-center items-center w-full">
            <div className="image-saving--summary">
              {savings.imageURL !== "" ? (
                <img src={savings.imageURL} alt="" />
              ) : (
                <img src={savingsIcon()} alt="" />
              )}
            </div>
            <div className="text-savings--summary w-full flex align-items-center">
              <div className="left-tran--summary w-full flex flex-col justify-center align-items-center">
                <div className="flex justify-center text-center align-items-center">
                  <div className="flex flex-col justify-center">
                    <p className="savings-inner--title font-medium text-gray-300">
                      {`${savings.name}`}
                    </p>
                    <h1 className="mt-3 mb-4 text-4xl font-medium">
                      {`₦${formatCurrency(savings.amountToSave)}`}
                    </h1>
                    <div className="flex flex-row justify-center items-center mb-4">
                      <p className="savings-inner--title font-medium color-black">{`${
                        savings.savingsType === 1
                          ? "Personal Target Savings"
                          : savings.savingsType === 2
                          ? "Fixed Lock Savings"
                          : savings.savingsType === 3
                          ? "Fixed Flexible Savings"
                          : "Group Savings"
                      }`}</p>
                      {maturityDateReached && (
                        <span className="font-medium color-green ml-2">
                          {savings.status === SavingsStatus.Finished ? (
                            <FaCheckCircle />
                          ) : (
                            <FaExclamationCircle />
                          )}
                        </span>
                      )}
                    </div>
                    {(
                      savings.savingsType === SavingsType.PersonalTargetSavings
                      && !maturityDateReached) &&
                       (
                      <div className="flex flex-row justify-center items-center mb-4">
                           <div className="trans-image">
                              <div className="flex flex-row">
                                <span data-tip="Update to a new card on this savings"
                                  onClick={ChangeRunningSavingsCard}
                                    className=" hover:text-green-300 text-center cursor-pointer">
                                    <FaRegCreditCard size={20} />
                              </span> &nbsp; &nbsp; 
                            
                              { savings.isPaused ? (
                                <span data-tip="This Savings is paused. You can click to Resume it"
                                   onClick={resumePersonalTargetSavings}
                                    className=" hover:text-green-300 text-center cursor-pointer">
                                    <FaPlayCircle size={20} />
                                </span>
                              ) : (
                                <span data-tip="Click to Pause this Savings"
                                    onClick={pausePersonalTargetSavings}
                                    className=" hover:text-green-300 text-center cursor-pointer">
                                    <FaPauseCircle size={20} />
                              </span>
                              )}&nbsp; &nbsp;
                                
                                {/* <img src={savingsIcon()} alt="" /> */}
                              </div>
                            </div>
                      </div>
                      )}
                  </div>
                </div>
                <div className="summary-progress w-full">
                  <div className="progress">
                    <div
                      className={classNames({
                        "progress-meter": true,
                        "progress-meter-green": progressPercentage >= 100,
                      })}
                      style={{ width: `${progressPercentage}%` }}
                    />
                  </div>
                  <div className="flex justify-between mt-3">
                    <p className="text-gray-300">
                      {`${formatCurrency(progressPercentage)}% achieved`}
                    </p>
                    <p className="color-black">
                      {`₦${formatCurrency(savings.amountSaved)}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nav-buttons flex justify-center">
            {savings.savingsType !== SavingsType.FixedLockSavings && (
              <div
                className={classNames({
                  "w-40 border-b text-center bg-white cta-del leading-loose border-wb-primary text-wb-primary mr-3 border wealth-buddy--cta text-white rounded-sm": true,
                  loading: startCancelLoading,
                  opaque: !canBeCancelled,
                })}
                onClick={canBeCancelled ? startCancelSavings : null}
              >
                Cancel <span className="loader" />
              </div>
            )}
            {savings.savingsType !== SavingsType.FixedLockSavings ? (
              // <button
              //   className={`w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta cta-black text-white rounded-sm ${
              //     savings.amountSaved === 0 ? "opaque" : ""
              //   }`}
              //   onClick={
              //     savings.amountSaved === 0 ? null : startWithdrawSavings
              //   }
              // >
              //   Withdraw
              // </button>

              <div className="dropdown inline-block relative">
                <button className="w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta cta-black text-white rounded-sm">
                  <span className="mr-1">Action</span>
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
                </button>
                <ul className="dropdown-menu absolute hidden">
                  {
                    // savings.status == SavingsStatus.InProgress &&
                    <li className="w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta cta-black text-white rounded-sm" href="#">
                      <button onClick={startTopUpSavings}>
                        Top up
                      </button>
                    </li>
                  }
                  <li className={`w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta cta-black text-white rounded-sm ${
                  savings.amountSaved === 0 ? "opaque" : ""}`}>
                     <button onClick={ savings.amountSaved === 0 ? null : startWithdrawSavings}>
                      Withdraw
                     </button>
                  </li>
                  
                </ul>
              </div>
            ) : (
                
              <button
                className={` w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta cta-black text-white rounded-sm ${
                  !canBeWithdrawn ? "opaque" : ""
                }`}
                onClick={canBeWithdrawn ? startWithdrawSavings : null}
              >
                Withdraw
              </button>
                
            )}
          </div>
        </div>

        <div className="view-summary--items card-margin--x px-0 flex-wrap top-inner--summary">
          <div className="savings-inner--item">
            <h5 className="savings-inner--subtitle text-gray-300 text-xs">
              Contribution
            </h5>
            <h1 className="mt-3 font-medium">{`₦${formatCurrency(
              savings.installmentalContribution
            )}/${
              savings.schedule === 2
                ? "Week"
                : savings.schedule === 3
                ? "Month"
                : "Day"
            }`}</h1>
          </div>
          <div className="savings-inner--item">
            <h5 className="savings-inner--subtitle text-gray-300 text-xs">
              Start Date
            </h5>
            <h1 className=" mt-3 font-medium">
              {getHumanDate(savings.startDate)}
            </h1>
          </div>
          <div className="savings-inner--item">
            <h5 className="savings-inner--subtitle text-gray-300 text-xs">
              Maturity Date
            </h5>
            <h1 className="mt-3 font-medium">{`${getHumanDate(
              savings.estimatedTerminationDate
            )}`}</h1>
          </div>
        </div>

        <div className="view-summary--items card-margin--x px-0 flex-wrap no-border--bottom">
          <div className="savings-inner--item">
            <h5 className="savings-inner--subtitle text-gray-300 text-xs">
              Interest Rate
            </h5>
            <h1 className="mt-3 font-medium">{`${
              savings.interestRate === 0 ? "N/A" : savings.interestRate + "%"
            }`}</h1>
          </div>
          {savings.interestRate !== 0 && (
            <div className="savings-inner--item">
              <h5 className="savings-inner--subtitle text-gray-300 text-xs">
                Interest Earned
              </h5>
              <h1 className="mt-3 font-medium color-primary">{`₦${formatCurrency(
                savings.totalInterestEarned
              )}`}</h1>
            </div>
          )}
        </div>
      </div>
    </div>
     <ReactTooltip effect="solid" multiline={true} />
    </>
  );
};

const mapStateToProps = (state) => ({
  customerSavings: state.customerSavings.data,
  startCancelLoading: state.savings.startCancelLoading,
});

export default connect(mapStateToProps)(MainDetails);
