import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { connect, useDispatch } from "react-redux";
import { investBars } from "../../imageLinks";
import {
  getAllTbillsTransactions,
  getInvestmentTransactionsForFund,
  getAllFixedTransactions,
} from "state/slices/investments";
import Loading from "shared-components/Loading";
import { formatCurrency } from "utils";
import LinesEllipsis from "react-lines-ellipsis";
import EmptyCard from "pages/dashboard/components/DashboardHome/components/EmptyCard";
import MeristemLogo from "assets/opengraph.png";
import "./style.css";

const TransactHistory = (props) => {
  const [activeOne, setActiveOne] = useState(true);
  const [activeTwo, setActiveTwo] = useState(false);
  const [activeThree, setActiveThree] = useState(false);

  const naira = "₦";
  const dollar = "$";

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      properTransactions.length === 0 &&
      fixedTransactions.length === 0 &&
      tBillsTransactions.length === 0
    ) {
      dispatch(getAllTbillsTransactions());
      dispatch(getAllFixedTransactions());
      dispatch(getInvestmentTransactionsForFund());
    }
  }, []);

  const properTransactions = props.investmentTransactionsForFundsData;
  const fixedTransactions = props.allFixedTrasactionsData;
  const tBillsTransactions = props.allTbillsTrasactionsData;

  return (
    <Fragment>
      <div className="card-shadow-radius w-full bg-white border px-6 sm:px-8 py-5 mb-10 overflow-hidden border-gray-200">
        <h1 className="text-base mb-6 font-medium">Transaction history</h1>

        {props.investmentTransactionsForFundsLoading ? (
          <div className="flex flex-col justify-center items-center h-full">
            <img className="w-16 h-16 mb-2" src={MeristemLogo} alt="" />
            <Loading text="" />
          </div>
        ) : fixedTransactions.length === 0 &&
          properTransactions.length === 0 &&
          tBillsTransactions.length === 0 ? (
          <EmptyCard
            title="Nothing here yet"
            message="Create an Investment plan today"
          />
        ) : (
          <Fragment>
            <div className="flex flex-row mt-8 justify-between main_border_wrap content-center items-center">
              {fixedTransactions.length === 0 ? null : (
                <p
                  onClick={() => {
                    setActiveOne(true);
                    setActiveTwo(false);
                    setActiveThree(false);
                  }}
                  className={`text-black text-base font-light sm:w-1/3 text-center cursor-pointer ${
                    activeOne ? "active_me" : null
                  }`}
                >
                  Fixed Deposits
                </p>
              )}

              {properTransactions.length === 0 ? null : (
                <p
                  onClick={() => {
                    setActiveTwo(true);
                    setActiveOne(false);
                    setActiveThree(false);
                  }}
                  className={`text-black text-base font-light sm:w-1/3 text-center two_borders cursor-pointer ${
                    activeTwo ? "active_me" : null
                  }`}
                >
                  Mutual Funds
                </p>
              )}

              {tBillsTransactions.length === 0 ? null : (
                <p
                  onClick={() => {
                    setActiveThree(true);
                    setActiveTwo(false);
                    setActiveOne(false);
                  }}
                  className={`text-black text-base font-light sm:w-1/3 text-center cursor-pointer ${
                    activeThree ? "active_me" : null
                  }`}
                >
                  Treasury Bills
                </p>
              )}
            </div>

            <div className="hide-scroll overflow-y-scroll hide-scroll pb-64 h-screen">
              <div style={{ display: activeTwo ? "block" : "none" }}>
                {props.investmentTransactionsForFundsLoading
                  ? null
                  : properTransactions.map((num, index) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-row justify-between content-center items-center mt-8"
                        >
                          <div className="flex flex-col sm:flex-row content-center items-center">
                            <img src={investBars} className="hidden sm:flex" />

                            <div className="sm:ml-5 mt-4 sm:mt-0">
                              <p className="text-black text-base font-light">
                                {num.description}
                              </p>
                              <p
                                style={{ color: "#999999" }}
                                className="text-sm mt-2"
                              >
                                {num.transType}
                              </p>
                            </div>
                          </div>

                          <div className=" sm:mt-0">
                            <p className="text-black font-bold text-right font-light">
                              {num.currency === "NGN" ? naira : dollar}
                              {formatCurrency(num.transAmount)}
                            </p>
                            <p
                              style={{ color: "#999999" }}
                              className="text-sm text-right w-full sm:mt-2"
                            >
                               
                              {/* {moment(parseInt(num.orderDate)).format("L")} */}
                            </p>
                          </div>
                        </div>
                      );
                    })}
              </div>

              <div style={{ display: activeOne ? "block" : "none" }}>
                {props.allFixedTrasactionsLoading
                  ? null
                  : !fixedTransactions
                  ? null
                  : fixedTransactions.map((num, index) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-row justify-between content-center items-center mt-8"
                        >
                          <div className="flex flex-col sm:flex-row content-center items-center">
                            <img src={investBars} className="hidden sm:flex" />

                            <div className="sm:ml-5 mt-4 sm:mt-0">
                              <p className="text-black text-sm font-light">
                                {num.portfolioLabel}
                              </p>
                              <p
                                style={{ color: "#999999" }}
                                className="text-xs mt-2"
                              >
                                {num.label.substring(0, 15)}
                              </p>
                            </div>
                          </div>

                          <div className="sm:mt-0">
                            <p className="text-black font-bold text-right font-light">
                              {num.currency === "NGN" ? naira : dollar}
                              {formatCurrency(num.faceValue)}
                            </p>
                            <p
                              style={{ color: "#999999" }}
                              className="text-sm sm:mt-2 w-full text-right"
                            >
                              {/* {moment(num.startDate).format("L")} */}
                            </p>
                          </div>
                        </div>
                      );
                    })}
              </div>

              <div style={{ display: activeThree ? "block" : "none" }}>
                {props.allTbillsTrasactionsLoading ? (
                  <Loading text="" />
                ) : (
                  tBillsTransactions.map((num, index) => {
                    return (
                      <div
                        key={index}
                        className="flex flex-row justify-between content-center items-center mt-8"
                      >
                        <div className="flex flex-col sm:flex-row content-center items-center">
                          <img src={investBars} className="hidden sm:flex" />

                          <div className="sm:ml-5 mt-4 sm:mt-0">
                            <p className="text-black text-base font-light">
                              {num.instrumentTypeLabel}
                            </p>
                            <div
                              style={{ color: "#999999" }}
                              className="text-sm mt-2"
                            >
                              <LinesEllipsis
                                text={`${num.label}`}
                                maxLine="2"
                                ellipsis="..."
                                trimRight
                              />
                            </div>
                          </div>
                        </div>

                        <div className=" sm:mt-0">
                          <p className="text-black text-right font-bold font-light">
                            {num.currency === "NGN" ? naira : dollar}
                            {formatCurrency(num.faceValue)}
                          </p>
                          <p
                            style={{ color: "#999999" }}
                            className="text-sm text-right sm:mt-2"
                          >
                            {/* {moment(num.orderDate).format("L")} */}
                          </p>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  investmentTransactionsForFundsLoading:
    state.investments.investmentTransactionsForFundsLoading,
  investmentTransactionsForFundsData:
    state.investments.investmentTransactionsForFundsData,
  investmentTransactionsForFundsError:
    state.investments.investmentTransactionsForFundsError,
  allFixedTrasactionsLoading: state.investments.allFixedTrasactionsLoading,
  allFixedTrasactionsData: state.investments.allFixedTrasactionsData,
  allFixedTrasactionsError: state.investments.allFixedTrasactionsError,
  allTbillsTrasactionsLoading: state.investments.allTbillsTrasactionsLoading,
  allTbillsTrasactionsData: state.investments.allTbillsTrasactionsData,
  allTbillsTrasactionsError: state.investments.allTbillsTrasactionsError,
  investmentValuationLoading: state.investments.investmentValuationLoading,
  investmentValuationData: state.investments.investmentValuationData,
});

export default connect(mapStateToProps)(TransactHistory);
