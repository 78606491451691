import React, { Fragment, useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import PlusIcon from "assets/img/plus.svg";
import { connect, useDispatch } from "react-redux";
import AddBankModal from "./components/AddBankModal";
import produce from "immer";
import AddBankSuccess from "./components/AddBankSuccess";
import { deleteBankAccount as deleteBankAccountRequest } from "state/slices/customer";
import { store } from "react-notifications-component";
import notification from "node -v/notification";
import EmptyBank from "./components/EmptyBank";
import BankListItem from "./components/BankListItem";
import DeleteBankConfirmationModal from "./components/DeleteBankConfirmationModal";

const Banks = ({ banksAccounts }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    isAddBankModalOpen: false,
    closeAddBankModal: false,
    showAddBankSuccess: false,
    isAddBankSuccessModalOpen: false,
    bankAccountId: null,
    deletingBankAccountId: null,
    showDeleteBankConfirmation: false,
    isDeletingBankAccount: false,
    deleteBankAccountError: "",
  });

  const setBankAccountId = (bankAccountId) => {
    setState(
      produce((draftState) => {
        draftState.bankAccountId = bankAccountId;
      })
    );
  };

  const requestVerification = (bankAccountId) => {
    setState(
      produce((draftState) => {
        draftState.bankAccountId = bankAccountId;
        draftState.isAddBankModalOpen = true;
      })
    );
  };

  const showAddBankModal = () => {
    setState(
      produce((draftState) => {
        draftState.isAddBankModalOpen = true;
      })
    );
  };

  const closeAddBankModal = () => {
    setState(
      produce((draftState) => {
        draftState.bankAccountId = null;
        draftState.isAddBankModalOpen = false;
      })
    );
  };

  const showAddBankSuccess = () => {
    setState(
      produce((draftState) => {
        draftState.isAddBankModalOpen = false;
        draftState.isAddBankSuccessModalOpen = true;
      })
    );
  };

  const closeAddBankSuccessModal = () => {
    setState(
      produce((draftState) => {
        draftState.isAddBankSuccessModalOpen = false;
      })
    );
  };

  const openBankAccountDeleteConfirmationModal = (e, bankAccountId) => {
    console.log(bankAccountId);
    e.preventDefault();

    setState(
      produce((draftState) => {
        draftState.deletingBankAccountId = bankAccountId;
        draftState.showDeleteBankConfirmation = true;
      })
    );
  };

  const closeDeleteBankConfirmationModal = () => {
    setState(
      produce((draftState) => {
        draftState.showDeleteBankConfirmation = false;
      })
    );
  };

  const deleteBankAccount = async () => {
    setState(
      produce((draftState) => {
        draftState.isDeletingBankAccount = true;
        draftState.deleteBankAccountError = "";
      })
    );

    const resultAction = await dispatch(
      deleteBankAccountRequest(state.deletingBankAccountId)
    );

    if (deleteBankAccountRequest.fulfilled.match(resultAction)) {
      setState(
        produce((draftState) => {
          draftState.showDeleteBankConfirmation = false;
          draftState.isDeletingBankAccount = false;
        })
      );

      store.addNotification({
        ...notification,
        title: "Success",
        message: "Bank account deleted successfully",
        type: "success",
      });
    } else {
      setState(
        produce((draftState) => {
          draftState.isDeletingBankAccount = false;
          draftState.deleteBankAccountError = resultAction.payload?.message;
        })
      );

      store.addNotification({
        ...notification,
        title: "Error",
        message: resultAction.payload?.message,
        type: "error",
      });
    }
  };

  return (
    <Fragment>
      {banksAccounts && banksAccounts.length > 0 ? (
        <Fragment>
          <div className="user-card--wrap  flex-col">
            <div className="user-card--heading flex justify-between items-center">
              <h3 className="card-main--heading font-medium">Your Banks</h3>
              <div className="plus-wrap">
                <img
                  onClick={showAddBankModal}
                  className="plus"
                  src={PlusIcon}
                  alt=""
                />
              </div>
            </div>
            <div className="flex justify-between flex-wrap">
              {banksAccounts.map((bankAccount) => (
                <BankListItem
                  key={bankAccount.id}
                  bankAccount={bankAccount}
                  deleteBankAccount={openBankAccountDeleteConfirmationModal}
                  requestVerification={requestVerification}
                />
              ))}
            </div>
          </div>
        </Fragment>
      ) : (
        <EmptyBank showAddBankModal={showAddBankModal} />
      )}

      <AddBankModal
        bankAccountId={state.bankAccountId}
        setBankAccountId={setBankAccountId}
        isAddBankModalOpen={state.isAddBankModalOpen}
        closeAddBankModal={closeAddBankModal}
        showAddBankSuccess={showAddBankSuccess}
      />

      <AddBankSuccess
        isAddBankSuccessModalOpen={state.isAddBankSuccessModalOpen}
        closeAddBankSuccessModal={closeAddBankSuccessModal}
      />

      <DeleteBankConfirmationModal
        isVisible={state.showDeleteBankConfirmation}
        isDeleting={state.isDeletingBankAccount}
        onProceed={deleteBankAccount}
        onBack={closeDeleteBankConfirmationModal}
        close={closeDeleteBankConfirmationModal}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  banksAccounts: state.bankAccounts.data,
});

export default connect(mapStateToProps)(Banks);
