import React, { Fragment } from "react";
import "./styles.css";

const Loading = ({ text = "Loading" }) => {
    return (
        <Fragment>
            <div className="flex flex-col justify-center items-center">
                <span className="font-medium">{text}</span>
                <div className="lds-ellipsis">
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        </Fragment>
    );
};

export default Loading;
