import React, { useEffect, useState, useContext, Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Toggle from "shared-components/Toggle/Toggle";
import AddBankContext from "contexts/AddBankContext";
import classNames from "classnames";
import "../../wallet.scss";
import { formatCurrency } from "utils";

// import Loading from "shared-components/Loading";

const WalletBalanceCard = ({ dashboard }) => {
    const {
        openFundWalletModal,
        startWithdrawFundsIntent,
        account,
        handleAccount,
    } = useContext(AddBankContext);

    //console.log(`data here: ${JSON.stringify(account)}`)

    return (
        <div
            className={classNames({
                "card-padding card h-auto card-black  flex-column  text-white": true,
                "bg-dollar": account,
            })}
        >
            <div className="">
                <div className="flex flex-row items-center justify-between content-center">
                    <div className="flex items-center">
                        <img
                            src={require("../../../../assets/img/small-dollar-bag.svg")}
                            alt="wealth-buddy"
                            className="pr-3"
                        />
                        <div className="text-left">
                            <h4
                                className={classNames({
                                    "text-white self-center pt-1": true,
                                    "text-white": account,
                                    "text-white text-opacity-25": !account,
                                })}
                            >
                                Wallet Balance
                            </h4>
                            <p className="ext-gray-100 text-2xl font-bold">
                                {account
                                    ? `$${formatCurrency(
                                        dashboard.netAssetValueDollar
                                    )}`
                                    : `₦${formatCurrency(
                                          dashboard.walletBalance
                                      )}`}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <span className="pdr-right-10">Naira Account</span>

                        <Toggle
                            onClick={handleAccount}
                            className={account ? "toggle" : ""}
                        />
                        <span className="pdr-left-10">Dollar Account</span>
                    </div>
                </div>

                <div className="flex flex-summary card-margin--x flex-wrap justify-between items-center content-center pt-6">
                    <div className="text-left pl-6">
                        <p
                            className={classNames({
                                "text-white self-center pt-1": true,
                                "text-white": account,
                                "text-white text-opacity-25": !account,
                            })}
                        >
                            Inflow
                        </p>
                        <p className="ext-gray-100 text-2xl font-bold">
                            {account
                                ? new Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                  }).format(0)
                                : new Intl.NumberFormat("en-NG", {
                                      style: "currency",
                                      currency: "NGN",
                                  }).format(dashboard.totalMonthlyInflow)}
                        </p>
                    </div>
                    <div
                        style={{
                            width: "2px",
                            height: "50px",
                            backgroundColor: "#222222",
                        }}
                    />
                    <div className="text-left card-margin--y ml-6 sm:ml-0 pr-6">
                        <p
                            className={classNames({
                                "text-white self-center pt-1": true,
                                "text-white": account,
                                "text-white text-opacity-25": !account,
                            })}
                        >
                            Outflow
                        </p>
                        <p className="text-gray-100 text-2xl font-bold text-right">
                            {account
                                ? new Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                  }).format(0)
                                : new Intl.NumberFormat("en-NG", {
                                      style: "currency",
                                      currency: "NGN",
                                  }).format(dashboard.totalMonthlyOutFlow)}
                        </p>
                    </div>
                </div>
            </div>

            {/* SECOND TOTAL SCREEN END */}
        </div>
    );
};

const mapStateToProps = (state) => ({
    dashboard: state.dashboard.data,
});

export default connect(mapStateToProps)(WalletBalanceCard);
