import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import produce from "immer";
import ReactApexChart from "react-apexcharts";
import numeral from "numeral";

const SavingsStatistics = ({ savingsStatistics }) => {
  const [state, setState] = useState({
    series: [
      {
        name: "Savings",
        data: Object.values(savingsStatistics),
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ["#ef878e"],
        width: 2,
      },
      markers: {
        colors: ["#ef878e"],
      },
      fill: {
        colors: ["#d9e8fc"],
      },
      yaxis: {
        labels: {
          formatter: (value) => numeral(value).format("0,0.00"),
        },
      },
      xaxis: {
        type: "string",
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    },
  });

  useEffect(() => {
    setState(
      produce((draft) => {
        draft.series[0].data = Object.values(savingsStatistics);
      })
    );
  }, [savingsStatistics]);

  return (
    <ReactApexChart
      className="w-full"
      options={state.options}
      series={state.series}
      type="area"
      height={250}
    />
  );
};

const mapStateToProps = (state) => ({
  savingsStatistics: state.savings.savingsStatisticsEntities,
});

export default connect(mapStateToProps)(SavingsStatistics);
