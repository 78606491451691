import React, { useState, useContext, useEffect } from "react";
import WalletProvider from "providers/WalletProvider";
import StickyBox from "react-sticky-box";
import SidePane from "../SidePane";
import ProviderCard from "../ProviderCard";
import CreatePaymentForm from "../ProviderForms/CreatePaymentForm";
import ChoosePaymentMethodModal from "../ChoosePaymentMethodModal";
import AddBankContext from "contexts/AddBankContext";
import {
  getBillCategories,
  getBillersPerCategory,
} from "state/slices/billsPayment";

import "../../wallet.scss";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { WalletContext } from "../WalletProvider/WalletProvider";
// import AddBankContext from "contexts/AddBankContext";
import Loading from "shared-components/Loading";
import BillProviders from "../BillProviders";
import ProviderForm from "../ProviderForms/ProviderForm";

const BillPayment = (props) => {
  // CONTEXT
  const {
    billCategories,
    billersByCategory,
    dispatchGetBillersByCategory,
  } = useContext(AddBankContext);

  // ROUTER
  const { id } = useParams();

  const activeCategory = billCategories.data.length
    ? billCategories.data.find((category) => category.categoryid === id)
    : {};

  // STATE
  const [selectedCategoryId, setSelectedCategoryId] = useState(id);
  const [title, setTitle] = useState(
    billersByCategory.data.length ? billersByCategory.data[0].categoryname : ""
  );

  const handleTitleChange = (text) => {
    setTitle(text);
  };

  const handleSelectedCategoryChange = (categoryId) => {
    setSelectedCategoryId(categoryId);
  };

  useEffect(() => {
    dispatchGetBillersByCategory(selectedCategoryId);
  }, [selectedCategoryId]);

  return (
    <div className="px-12 pb-8 flex flex-col fadeIn mt-10">
      <h1 className="text-4xl py-8 font-medium content-header--border content-header--title">
        Bill Payment
      </h1>

      <div className="flex flex-col md:flex-row justify-between">
        <div className="sm:w-full md:w-2/6">
          <SidePane
            sidePaneItems={billCategories.data}
            handleTitleChange={handleTitleChange}
            handleCategoryChange={handleSelectedCategoryChange}
          />
        </div>

        <div className="mt-10 md:mt-0 md:ml-6 md:w-4/6 sm:w-full bg-white card">
          <div className="px-8 py-6 border border-orange-900 wb-border-b">
            <p className="font-bold text-lg">{activeCategory.categoryname}</p>
          </div>

          {billersByCategory.loading ? (
            <div className="flex justify-center my-10">
              <Loading />
            </div>
          ) : (
            <>
              <BillProviders
                categoryId={id}
                billers={billersByCategory}
                handleTitleChange={handleTitleChange}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillPayment;
