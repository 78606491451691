import React, { useEffect, Fragment, useContext, useState } from "react";
import CardIcon from "assets/img/cardIcon.png";
import Loading from "shared-components/Loading";
import classNames from "classnames";
import * as yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import UserBanksDropdown from "./components/UserBanksDropdown";
import NumberFormat from "react-number-format";
import AddBankContext from "contexts/AddBankContext";
import { closeModalOnOutsideClick, formatCurrency } from "utils";
import { connect, useDispatch } from "react-redux";
import {
  startTransferToBank as startTransferToBankRequest,
  completeTransferToBank as completeTransferToBankRequest,
} from "state/slices/cashTransfer";
import produce from "immer";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";

const WithdrawFundsModal = ({dashboard}) => {
  const dispatch = useDispatch();

  const {
    isWithdrawFundsModalOpen,
    openChangeWithdrawalLimitModal,
    closeWithdrawFundsModal,
    showWithdrawFundsSuccessModal,
  } = useContext(AddBankContext);

  useEffect(() => {
    if (isWithdrawFundsModalOpen) {
      closeModalOnOutsideClick(closeWithdrawFundsModal);
    }
  }, [isWithdrawFundsModalOpen]);

  const [state, setState] = useState({
    isTransferProcessing: false,
    transferError: null,
    requestingConfirmation: false,
    transaction: {},
    amount: "",
    bankId: "",
  });

  const initialValues = {
    amount: state.amount,
    bankId: state.bankId,
  };

  const validationSchema = yup.object().shape({
    amount: yup.number().label("Amount")
      .test(
      'Is positive?', 
      'ERROR: The minimum withdrawal amount is #100', 
      (value) => value > 99
    ).required(),
    bankId: yup.string().label("Bank account").required(),
  });

  const restartTransferToBank = () => {
    setState(
      produce((draft) => {
        draft.transferError = null;
        draft.requestingConfirmation = false;
        draft.transaction = {};
      })
    );
  };

  const handleStartTransferToBank = async (formValues) => {
    const payload = {
      bankId: formValues.bankId,
      amount: parseFloat(formValues.amount),
    };

    setState(
      produce((draft) => {
        draft.amount = payload.amount;
        draft.bankId = payload.bankId;
        draft.isTransferProcessing = true;
        draft.transferError = null;
      })
    );

    const resultAction = await dispatch(startTransferToBankRequest(payload));

    if (startTransferToBankRequest.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);

      setState(
        produce((draft) => {
          draft.requestingConfirmation = true;
          draft.isTransferProcessing = false;
          draft.transaction = response;
        })
      );

    } else {
      setState(
        produce((draft) => {
          draft.isTransferProcessing = false;
          draft.transferError =
            resultAction.error?.message || resultAction.error?.payload;
        })
      );
    }
  };

  const handleCompleteTransferToBank = async () => {
    const payload = {
      bankId: state.bankId,
      amount: state.amount,
    };

    setState(
      produce((draft) => {
        draft.isTransferProcessing = true;
        draft.transferError = null;
      })
    );

    const resultAction = await dispatch(completeTransferToBankRequest(payload));

    if (completeTransferToBankRequest.fulfilled.match(resultAction)) {
      showWithdrawFundsSuccessModal();
    } else {
      setState(
        produce((draft) => {
          draft.isTransferProcessing = false;
          draft.transferError =
            resultAction.error?.message || resultAction.payload?.message;
        })
      );
    }
  };
  return (
    isWithdrawFundsModalOpen && (
      <div className="modal fixed inset-0 bg-wb-overlay flex justify-center items-center modal-active">
        <Fragment>
          <div className="auth-modal flex flex-col items-center bg-white fadeIn login-fieldset">
            <div className="flex flex-col items-center mb-0">
              <i className="w-20 mb-4">
                <img src={CardIcon} alt="" />
              </i>
              <h1 className="text-2xl font-medium mb-2">
                Withdraw from wallet
              </h1>
              <p className="text-center text-gray-500 leading-normal">
              Withdraw from your wallet into your Bank Account
              </p>
            </div>
            <br />

            <Fragment>
              {state.isTransferProcessing ? (
                <div className="mt-8 flex flex-col items-center">
                  <Loading text="Processing withdrawal" />
                </div>
              ) : (
                <Fragment>
                  {state.transferError && (
                    <div className="w-72 py-8 text-xs text-left">
                      <p className="w-full p-3 bg-red-200 text-red-700 rounded text-center font-medium">
                        {state.transferError}
                      </p>
                    </div>
                  )}

                  {!state.requestingConfirmation ? (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      validateOnMount={true}
                      onSubmit={handleStartTransferToBank}
                    >
                      {({
                        handleSubmit,
                        isValid,
                        setFieldValue,
                        values,
                        handleBlur,
                      }) => {
                        return (
                          <Form
                            className="flex flex-col items-center"
                            onSubmit={handleSubmit}
                          >
                            <Fragment>
                              <fieldset className="w-full">
                                <label className="block text-xs mb-2">
                                  Amount
                                </label>
                                <NumberFormat
                                  thousandSeparator={true}
                                  placeholder="eg: 20,000"
                                  autoComplete="off"
                                  type="text"
                                  id="amount"
                                  name="amount"
                                  className="block w-full text-xs p-3 border border-gray-400 rounded"
                                  value={values.amount}
                                  onBlur={handleBlur}
                                  onValueChange={({ value }) =>
                                    setFieldValue("amount", value)
                                  }
                                />
                              </fieldset>
                              <ErrorMessage
                                name="amount"
                                render={(errorMessage) => (
                                  <p className="label-error--text mt-3 text-xs color-red font-medium text-center bg-red-200">
                                    {errorMessage}
                                  </p>
                                )}
                              />
                              <div className="mt-6 w-full">
                                <label className="block text-xs mb-2">
                                  Bank
                                </label>
                                <div className="fieldset">
                                  <UserBanksDropdown
                                    selectedItemId={values.bankId}
                                    onSelectItem={(item) =>
                                      setFieldValue("bankId", item.value)
                                    }
                                  />
                                </div>
                              </div>
                            </Fragment>

                            <div className="nav-buttons flex justify-center">
                              <div
                                onClick={closeWithdrawFundsModal}
                                className=" w-40  border-b text-center bg-white leading-loose border-wb-primary text-wb-primary mr-3 border wealth-buddy--cta text-white rounded-sm"
                              >
                                Back
                              </div>
                              <button
                                type="submit"
                                className={classNames({
                                  "w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm": true,
                                  opaque: !isValid,
                                })}
                                disabled={!isValid}
                              >
                                Proceed
                              </button>
                            </div>

                            <div className="inline-flex justify-center pt-10">
                              <button
                                type="button"
                                className="text-base text-primary"
                                onClick={openChangeWithdrawalLimitModal}
                              >
                              Your daily withdrawal limit is <span style={{color: "red"}}>{`₦${formatCurrency(dashboard.withdrawalLimit)}`}</span>. 
                              <br />You can click here to change withdrawal limit
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  ) : (
                    <div className="flex flex-col items-center w-2/3 pt-10">
                      <div className="confirm-wrap w-full">
                        <div className="confirm-item">
                          <span className="plan-name">Wallet Balance</span>
                          <span className="plan-type font-medium">
                            {`₦${formatCurrency(
                              state.transaction.walletBalance
                            )}`}
                          </span>
                        </div>
                        <div className="confirm-item">
                          <span className="plan-name">Amount to withdraw</span>
                          <span className="plan-type font-medium">
                            {`₦${formatCurrency(state.transaction.amount)}`}
                          </span>
                        </div>
                        <div className="confirm-item">
                          <span className="plan-name">Fee</span>
                          <span className="plan-type font-medium color-red">
                            {`₦${formatCurrency(state.transaction.fee)}`}
                          </span>
                        </div>
                      </div>

                      <div className="nav-buttons flex justify-center">
                        <div
                          onClick={restartTransferToBank}
                          className=" w-40  border-b text-center bg-white leading-loose border-wb-primary text-wb-primary mr-3 border wealth-buddy--cta text-white rounded-sm"
                        >
                          Back
                        </div>
                        <button
                          type="button"
                          className={classNames({
                            "w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm": true,
                          })}
                          onClick={handleCompleteTransferToBank}
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                  )}
                </Fragment>
              )}
            </Fragment>
          </div>
        </Fragment>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard.data,
});

export default connect(mapStateToProps)(WithdrawFundsModal);
