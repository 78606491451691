import React, { Fragment, useContext } from "react";
import AddBankContext from "contexts/AddBankContext";
import { formatCurrency } from "utils";
import CardIcon from "assets/img/cardIcon.png";
import SuccessModal from "shared-components/modals/SuccessModal";

const WithdrawFundsSuccess = () => {
  const {
    isWithdrawFundsSuccessModalOpen,
    closeWithdrawFundsSuccessModal,
  } = useContext(AddBankContext);

  return (
    isWithdrawFundsSuccessModalOpen && (
      <SuccessModal
        title="Success"
        subtitle="Funds withdrawn successfully"
        icon={CardIcon}
        buttonTitle="Done"
        closeModal={closeWithdrawFundsSuccessModal}
      />
    )
  );
};

export default WithdrawFundsSuccess;
