import React, { Fragment, useEffect } from "react";
import CloseModalIcon from "shared-components/svgs/CloseModalIcon";
import DisclaimerIcon from "assets/img/disclaimerIcon.png";
import { closeModalOnOutsideClick } from "utils";
import CardIcon from "assets/img/cardIcon.png";
import Loading from "shared-components/Loading";

const DisclaimerModal = ({
  isVisible,
  isCreateLoading,
  createError,
  onBack: handleBack,
  onProceed: handleProceed,
  close,
}) => {
  useEffect(() => {
    isVisible && closeModalOnOutsideClick(close);
  }, [isVisible]);

  return (
    isVisible && (
      <div className="modal fixed inset-0 bg-wb-overlay flex justify-center items-center modal-active">
        <div className="auth-modal flex flex-col items-center bg-white fadeIn login-fieldset" style={{"height": "700px","overflow": "auto"}}>
          {!isCreateLoading && (
            <span className="closeModal" onClick={close}>
              <CloseModalIcon />
            </span>
          )}

          {!isCreateLoading ? (
            <Fragment>
              <div className="flex flex-col items-center mb-6">
                {/* <i className="w-20 mb-4">
                  <img src={DisclaimerIcon} alt="" />
                </i> */}
                <h1 className="text-2xl font-medium mb-2">Terms and Conditions</h1>
              </div>

              {createError && (
                <div className="w-72 text-xs text-left mb-8">
                  <p className="w-full p-3 bg-red-200 text-red-700 rounded text-center font-medium">
                    {createError}
                  </p>
                </div>
              )}

              <div className="disclaimer-text">
              <p className="mb-6 text-gray-300">
                <ol>
    <li> 
        <b>Purpose of the Fund: </b>
         The Fund is created with the primary objective of providing
         financial assistance for the educational pursuits of the Beneficiary. 
         This includes but is not limited to tuition fees, educational materials,
         books, boarding expenses, and other educational-related costs.
    </li><br />
    <li>
        <b>Contributions: </b> 
        You shall make periodic contributions to the Fund which can be Daily, 
        Weekly, Monthly or a lump sum. Contributions to the Fund cannot be made by
        third parties without our consent and subject to our AML/CFT and KYC protocols

  </li> <br />
    <li>
      <b>Trustee: </b> 
      Meristem Trustees Limited shall stand possessed as the Trustee of
      the fund and shall have fiduciary responsibility for administering
      the Fund. The Trustee shall act in the best interests of the Beneficiary
      and in accordance with the terms laid out in this T&C.

  </li> <br />
    <li>
      <b>Vesting Period: </b> 
      The Fund shall be subject to a lock-in period, during which you will not
      access the funds. The duration of the lock-in period shall be one year 
      commencing from the date of the first funding. After the lock-in period
      expires Periodic Withdrawals for the sole purpose of the trust are allowed.
      The Interest rate of the fund is not fixed for the entire duration and may 
      vary due market influences


    </li> <br />
  <li>
      <b>Educational Expenses: </b> You agree and understand that the Fund shall only be used to cover legitimate educational expenses, which shall be directly related to the Beneficiary's pursuit of academic, vocational, or professional education. 


  </li> <br />
  <li>
      <b>Discretion of Trustees: </b> 
      The Trustee reserves the right to exercise reasonable discretion in approving or denying withdrawal requests, ensuring compliance with the Fund's purpose and applicable laws.

  </li> <br />
  <li>
      <b>Reporting and Accounting:</b> The records of all contributions and withdrawals which are provided on the web/app shall be taken as the Trustee’s report without any additional obligation on the Trustee to provide a report.

  </li> <br />
  <li>
      <b>Incapacitation:</b>  In the event of incapacitation or demise of the client, Meristem Trustees Limited takes possession and administers the balance in the Children Education Trust savings account for the use of the named beneficiary (ies).
 
  </li> <br />
  <li>
      <b>Governing Law: </b>	These T&C shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria.

  </li> <br />
                </ol>  
              </p>
              <p className="text-gray-300">
              By signing up for this service on the Wealth Buddy App or web you unequivocally accept these terms and conditions.
              </p>
              </div>

              <div className="nav-buttons flex justify-center">
                <div
                  onClick={handleBack}
                  className=" w-40 border-b text-center bg-white leading-loose border-wb-primary text-wb-primary mr-3 border wealth-buddy--cta text-white rounded-sm"
                >
                  Back
                </div>

                <button
                  type="submit"
                  className="w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm"
                  onClick={handleProceed}
                >
                  Proceed
                </button>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="flex flex-col items-center mb-0">
                <i className="w-20 mb-4">
                  <img src={CardIcon} alt="" />
                </i>
                <h1 className="text-2xl font-medium mb-2">Trust Savings</h1>
                <p className="text-center text-gray-500 leading-normal">
                  We will help you grow your wealth from here. let's take you farther
                </p>
              </div>

              <div className="flex flex-col items-center mt-8">
                <Loading text="Creating goal" />
              </div>
            </Fragment>
          )}
        </div>
      </div>
    )
  );
};

export default DisclaimerModal;
