import React, { useState, Fragment, useEffect } from "react";
import CardIcon from "assets/img/cardIcon.png";
import "./invest.css";
import { connect } from "react-redux";
import InvestmentDropdown from "../investmentDropdown/InvestmentDropdown";
import { formatCurrency } from "utils";
import InvestModalSuccess from "./InvestModalSuccess";
import CloseModalIcon from "shared-components/svgs/CloseModalIcon";
import { Link } from "react-router-dom";
import { AddNewCard } from "assets/exports";
//import PaymentCardDropdown from "./components/PaymentCardDropdown";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { closeModalOnOutsideClick } from "utils";

const InvestModal = ({
  createInvestmentPayload,
  close,
  cards,
  dashboard, 
  formValues,
  isVisible,
  onSubmit: handleOnSubmit

}) => {

  useEffect(() => {
    isVisible && closeModalOnOutsideClick(close);
  }, [isVisible]);

  const initialValues = {
    cardId: "",
  };

  const validationSchema = yup.object().shape({
    cardId: yup.string().label("Card").required(),
  });

  const [isReadyForSubmission, setIsReadyForSubmission] = useState(false);
  const [userCard, setUserCard] = useState("");
  const [usePaymentOptionsSelection, setUsePaymentOptionsSelection] = useState(false);
  const [useWalletSelection, setUseWalletSelection] = useState(false);

  const setInvestmentPayload = createInvestmentPayload;

  if (usePaymentOptionsSelection) {
    setInvestmentPayload.cardId = `${userCard}`;
  }

  const setAvailableCard = (value) => {
    setUserCard(value);
  };

  const onclose = () => {
    close(false);
  };



  return (
 
    <div className="modal fixed inset-0 bg-wb-overlay flex justify-center items-center modal-active">
     <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnMount={true}
              onSubmit={handleOnSubmit}
            >
              {({ handleSubmit, isValid, setFieldValue, values }) => {
               
          return (
      <div className="auth-modal flex flex-col items-center bg-white fadeIn login-fieldset">
        <span
          className="closeModal cursor-pointer"
          onClick={() => {
            onclose();
          }}
        >
          <span className="closeModal">
            <CloseModalIcon />
          </span>
        </span>

        {!isReadyForSubmission ? (
          <Fragment>
            <div className="flex flex-col items-center mb-0">
              <i className="w-20 mb-4">
                <img src={CardIcon} alt="" />
              </i>
              <h1 className="text-2xl font-medium mb-2">Fund Investments</h1>
              <p className="text-center text-gray-500 leading-normal">
              Make deposit into this investments.<br /> Lets grow wealth for you...
              </p>
            </div>

            {!useWalletSelection && (
              <div
                onClick={() => {
                  setUsePaymentOptionsSelection(true);
                  setUseWalletSelection(false);
                }}
                className={`rounded-md  w-11/12 sm:w-8/12 border mt-16 flex flex-row items-center content-center py-4 px-4 ${
                  usePaymentOptionsSelection ? "blessed" : "good-time"
                }`}
              >
                <div className={`${usePaymentOptionsSelection ? "plain-b" : "plain-two"}`}>
                  <div className={`${usePaymentOptionsSelection ? "plain-inner" : ""}`} />
                </div>
                <label
                  className="ml-4 font-medium text-base"
                  htmlFor="payment_method"
                >
                  Payment Options
                </label>
              </div>
            )}

            {!usePaymentOptionsSelection && (
  
                <div
                    onClick={() => {
                      setUseWalletSelection(true);
                      setUsePaymentOptionsSelection(false);
                    }}
                    className={`rounded-md  w-11/12 sm:w-8/12 border mt-2 flex flex-row items-center content-center py-4 px-4 ${
                      useWalletSelection ? "blessed" : "good-time"
                    }`}
                  >
                    <div className={`${useWalletSelection ? "plain-b" : "plain-two"}`}>
                      <div className={`${useWalletSelection ? "plain-inner" : ""}`} />
                    </div>
                    <label
                      className="ml-4 font-medium text-base"
                      htmlFor="payment_method"
                    >
                      Use wallet
                    </label>
                  </div>
              
            )}


            {usePaymentOptionsSelection && (
              <Fragment>
                <div className="fieldset w-11/12 mt-2 sm:w-8/12">
                   {/* <InvestmentDropdown
                    cardsData={cards}
                    settingCard={setAvailableCard}
                  />  */}
                  <InvestmentDropdown
                      selectedItemId={values.cardId}
                      onSelectItem={(item) =>
                        setFieldValue("cardId", item.value)
                      }
                  /> 
                    <button
                        disabled={!isValid}
                        onClick={() => setIsReadyForSubmission(true)}
                         className={`mt-6 w-40 text-center leading-loose mx-auto bg-wb-primary wealth-buddy--cta text-white rounded-sm`}
                  >
                    Done
                  </button>
                </div>
              </Fragment>
            )}

            {useWalletSelection && (
              <Fragment>
                <div className="text-xs text-gray-400 text-center flex flex-row mt-4 ">
                  You have{" "}
                  <span className="text-orange-700 mx-2">
                    ₦{formatCurrency(dashboard.walletBalance)}
                  </span>
                  in your wallet
                </div>
                <button
                  onClick={() => setIsReadyForSubmission(true)}
                  className={`mt-6 w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm`}
                >
                  Continue
                </button>
              </Fragment>
            )}

          
            <Link
                to="/dashboard/settings?page=cards"
                className="add-new--card flex items-center justify-center"
            >
            <span
              className="add-new--icon"
              dangerouslySetInnerHTML={{ __html: AddNewCard }}
            />
              Add a New Card
            </Link>
          
          </Fragment>
        ) : (
          <InvestModalSuccess
            createInvestmentPayload={setInvestmentPayload}
            close={onclose}
          />
        )}


      </div>
          )
        }}
    </Formik>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cards: state.cards.data,
  dashboard: state.dashboard.data,
});

export default connect(mapStateToProps)(InvestModal);


