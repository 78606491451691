import React, { Fragment, useEffect, useState } from "react";
import { logo } from "assets/exports";
import { useHistory, useParams } from "react-router-dom";
import Loading from "shared-components/Loading";
import "react-perfect-scrollbar/dist/css/styles.css";
import "toasted-notes/src/styles.css";
import { connect, useDispatch } from "react-redux";
import MainDetails from "./components/MainDetails";
import ChangeRunningSavingsCard from "./components/ChangeRunningSavingsCardModal";
import ChangeRunningSavingCardSuccess from "./components/ChangeRunningSavingCardSuccess";
import TransactionHistory from "./components/TransactionHistory";
import {
  completeWithdrawSavings,
  fetchSavingsById,
  startWithdrawSavings,
  startTopUpPersonalTargetSavings as startTopUpPersonalTargetSavingsRequest,
  startCancelSavings,
  completeCancelSavings,
  pausePersonalTargetSavings as pausePersonalTargetSavingsRequest,
  resumePersonalTargetSavings as resumePersonalTargetSavingsRequest,
  changeCardOnRunningSavings as changeCardOnRunningSavingsRequest,
} from "state/slices/savings";
import { unwrapResult } from "@reduxjs/toolkit";
import produce from "immer";
import CancelSavingsModal from "./components/StartCancelSavingsModal";
import CancelSavingsSuccess from "./components/CancelSavingsSuccess";
import StartWithdrawSavingsModal from "./components/StartWithdrawSavingsModal";
import StartTopUpSavingsModal from "./components/StartTopUpSavingsModal";
import WithdrawalSummaryModal from "./components/WithdrawalSummaryModal";
import WithdrawSavingsSuccess from "./components/WithdrawSavingsSuccess";
import { getDashboardData } from "state/ducks/dashboard/actions";
import { getCustomerSavingsData } from "state/ducks/customerSavings/actions";
import { getRecentSavingTransactionsData } from "state/ducks/recentSavingTransactions/actions";
import notification from "node -v/notification";
import { store } from "react-notifications-component";


const ViewSimpleSavings = ({ customerSavings }) => {
  const { savingsId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const savings =
    customerSavings.find((savingsItem) => {
      return savingsItem.savingsID === savingsId;
    }) || {};

  const [state, setState] = useState({
    transactionsLoaded: false,
    savingsTransactions: [],
    amountToDisburse: 0,
    penalty: 0,
    amountSaved: 0,
    formValues: {
      cardId: "",
      savingsID: savings.savingsID,
      savingsType: savings.savingsType,
    },
    showChangeRunningSavingsCardModal: false,
    showFundSavingsModal: false,
    showChangeRunningSavingsCardModal: false,
    isStartCancelSavingsModalVisible: false,
    isCancelSavingsSuccessModalVisible: false,
    isStartWithdrawSavingsModalVisible: false,
    isWithdrawalSummaryModalVisible: false,
    isStartTopUpModalVisible: false,
    isStartTopUpPersonalTargetSavingsLoading: false,
    isWithdrawSavingsSuccessModalVisible: false,
    isPausePersonalTargetSavingsLoading: false,
    isResumePersonalTargetSavingsLoading: false,
    showChangeRunningSavingsSuccessModal: false
  });

  useEffect(() => {
    getPersonalSavings().then(undefined);
  }, []);

  const groups = state.savingsTransactions.reduce((groups, transactions) => {
  const date = transactions.creationDate.split("T")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(transactions);
    return groups;
  }, {});

  const groupedTransactions = Object.keys(groups).map((date) => {
    return {
      date: date,
      transactions: groups[date],
    };
  });

  const getPersonalSavings = async () => {
    const resultAction = dispatch(fetchSavingsById(savingsId));
    const response = await unwrapResult(resultAction);

    setState(
      produce((draft) => {
        draft.transactionsLoaded = true;
        draft.savingsTransactions = response || [];
      })
    );
  };

  const startCancelProcess = () => {
    dispatch(startCancelSavings(savings))
      .then(unwrapResult)
      .then((data) => {
        setState(
          produce((draft) => {
            draft.isStartCancelSavingsModalVisible = true;
            draft.amountToDisburse = data.amountToDisburse;
          })
        );
      });
  };

  const completeCancelProcess = () => {
    dispatch(completeCancelSavings(savings))
      .then(unwrapResult)
      .then(() => {
        setState(
          produce((draft) => {
            draft.isStartCancelSavingsModalVisible = false;
            draft.isCancelSavingsSuccessModalVisible = true;
          })
        );
      });
  };


  const startTopUpPersonalTargetSavings = async (formValues) => {
  
    setState(
      produce((draft) => {
        draft.isStartTopUpPersonalTargetSavingsLoading = true;
      })
    );
    let payload = {
      savingsId: savings.savingsID,
      amount: parseFloat(formValues.amount),
      cardId: formValues.cardId,
      remark: `Top up my savings with amount of ${formValues.amount}`,
    }
  
    const resultAction = await dispatch(
      startTopUpPersonalTargetSavingsRequest(payload)
    );

    if (startTopUpPersonalTargetSavingsRequest.fulfilled.match(resultAction)) {
      setState(
        produce((draft) => {
          draft.isStartTopUpPersonalTargetSavingsLoading = false;
        })
      );
      getPersonalSavings().then(undefined);
    } else {
      setState(
        produce((draft) => {
          draft.isStartTopUpPersonalTargetSavingsLoading = false;
        })
      );
    }

    // store.addNotification({
    //   ...notification,
    //   title: "Success",
    //   message: `This savings is now paused. We won't debitting your card or wallet.`,
    //   type: "success",
    //   insert: "top",
    //   container: "top-center",
    //   animationIn: ["animate__animated", "animate__fadeIn"],
    //   animationOut: ["animate__animated", "animate__fadeOut"],
    //   dismiss: {
    //     duration: 7000,
    //     onScreen: true
    //   }
    // });

  };


  const startWithdrawProcess = async (formValues) => {
    const payload = {
      savingsType: savings.savingsType,
      formValues: {
        amount: parseFloat(formValues.amount),
        savingsID: savings.savingsID,
      },
    };

    try {
      const resultAction = await dispatch(startWithdrawSavings(payload));

      if (startWithdrawSavings.fulfilled.match(resultAction)) {
        const withdrawalDetails = unwrapResult(resultAction);
        setState(
          produce((draft) => {
            draft.isStartWithdrawSavingsModalVisible = false;
            draft.isWithdrawalSummaryModalVisible = true;
            draft.penalty = withdrawalDetails.penalty;
            draft.amountSaved = withdrawalDetails.amountSaved;
            draft.amountToDisburse = withdrawalDetails.amountToDisburse;
          })
        );
      }
    } catch (err) {}
  };

  const completeWithdrawProcess = async () => {
    const payload = {
      savingsType: savings.savingsType,
      formValues: {
        amount: parseFloat(state.amountToDisburse),
        savingsID: savings.savingsID,
      },
    };

    try {
      const resultAction = await dispatch(completeWithdrawSavings(payload));
      if (completeWithdrawSavings.fulfilled.match(resultAction)) {
        setState(
          produce((draft) => {
            draft.isWithdrawalSummaryModalVisible = false;
            draft.isWithdrawSavingsSuccessModalVisible = true;
          })
        );
      }
    } catch (err) {}
  };

  const pausePersonalTargetSavings = async () => {
    setState(
      produce((draft) => {
        draft.isPausePersonalTargetSavingsLoading = true;
      })
    );

  
    const resultAction = await dispatch(
      pausePersonalTargetSavingsRequest({
        savingsID: savings.savingsID,
      })
    );

    if (pausePersonalTargetSavingsRequest.fulfilled.match(resultAction)) {
      setState(
        produce((draft) => {
          draft.isPausePersonalTargetSavingsLoading = false;
        })
      );
      getPersonalSavings().then(undefined);
    } else {
      setState(
        produce((draft) => {
          draft.isPausePersonalTargetSavingsLoading = false;
        })
      );
    }

    store.addNotification({
      ...notification,
      title: "Success",
      message: `This savings is now paused. We won't debit your card or wallet.`,
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 7000,
        onScreen: true
      }
    })

  }

  const resumePersonalTargetSavings = async () => {
    setState(
      produce((draft) => {
        draft.isResumePersonalTargetSavingsLoading = true;
      })
    );

    const resultAction = await dispatch(
      resumePersonalTargetSavingsRequest({
        savingsID: savings.savingsID,
      })
    );

    if (resumePersonalTargetSavingsRequest.fulfilled.match(resultAction)) {
      setState(
        produce((draft) => {
          draft.isResumePersonalTargetSavingsLoading = false;
        })
      );
      getPersonalSavings().then(undefined);
    } else {
      setState(
        produce((draft) => {
          draft.isResumePersonalTargetSavingsLoading = false;
        })
      );
    }

    store.addNotification({
      ...notification,
      title: "Success",
      message: `You have resumed this savings`,
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 7000,
        onScreen: true
      }
    });


  };

  const closeStartCancelSavingsModal = () => {
    setState(
      produce((draft) => {
        draft.isStartCancelSavingsModalVisible = false;
      })
    );
  };

  const closeStartCancelSavingsSuccessModal = () => {
    setState(
      produce((draft) => {
        draft.isCancelSavingsSuccessModalVisible = false;
      })
    );

    dispatch(getDashboardData());
    dispatch(getCustomerSavingsData());
    dispatch(getRecentSavingTransactionsData());

    history.push("/dashboard/savings");
  };

  const openStartTopUpSavingsModal = () => {
    setState(
      produce((draft) => {
        draft.isStartTopUpModalVisible = true;
      })
    );
  };

  const closeStartTopUpSavingsModal = () => {
    setState(
      produce((draft) => {
        draft.isStartTopUpModalVisible = false;
      })
    );
  };

  const openStartWithdrawSavingsModal = () => {
    setState(
      produce((draft) => {
        draft.isStartWithdrawSavingsModalVisible = true;
      })
    );
  };

  const closeStartWithdrawSavingsModal = () => {
    setState(
      produce((draft) => {
        draft.isStartWithdrawSavingsModalVisible = false;
      })
    );
  };

  const showWithdrawalSummaryModal = (data) => {
    setState(
      produce((draft) => {
        draft.isStartWithdrawSavingsModalVisible = false;
        draft.isWithdrawalSummaryModalVisible = true;
        draft.penalty = data.penalty;
        draft.amountSaved = data.amountSaved;
        draft.amountToDisburse = data.amountToDisburse;
      })
    );
  };

  const closeWithdrawalSummaryModal = () => {
    setState(
      produce((draft) => {
        draft.isWithdrawalSummaryModalVisible = false;
      })
    );
  };

  const closeWithdrawSavingsSuccessModal = () => {
    setState(
      produce((draft) => {
        draft.isWithdrawSavingsSuccessModalVisible = false;
      })
    );

    history.push("/dashboard/wallet");
  };
  const handleOpenChangeRunningSavingsCardModal = () => {
    setState(
      produce((draft) => {
        draft.showChangeRunningSavingsCardModal = true;
      })
    );
  };
  const handleCloseChangeRunningSavingsCardModal = () => {
    setState(
      produce((draft) => {
        draft.showChangeRunningSavingsCardModal = false;
      })
    );
  };

  const handleSubmitChangeRunningSavingsCardForm = async({ cardId, savingsID, savingsType }) => {
    setState(
      produce((draft) => {
        draft.showChangeRunningSavingsCardModal = false;
        draft.formValues.cardId = cardId;
        draft.formValues.savingsID = savingsID;
        draft.formValues.savingsType = savingsType;
      })
    );
    let payload = {
      allowCardDebit : true,
      savingsID: savingsID,
      cardId: cardId,
      savingsType: savingsType
    }
  
    const resultAction = await dispatch(
      changeCardOnRunningSavingsRequest(payload)
    );

    if (changeCardOnRunningSavingsRequest.fulfilled.match(resultAction)) {
      setState(
        produce((draft) => {
          draft.showChangeRunningSavingsCardModal = false;
          draft.showChangeRunningSavingsSuccessModal = true;
        })
      );
     
    } else {
      
      setState(
        produce((draft) => {
          draft.showChangeRunningSavingsCardModal = false;
          draft.createError = resultAction.error.message;
        })
      );
    }


  };

  const handleChangeRunningCardSuccessModalClose = () => {
    setState(
      produce((draft) => {
        draft.showChangeRunningSavingsSuccessModal = false;
      })
    );
  
  };


  return (
    <Fragment>
      <div className="px-12 pb-12 inner-savings--wrap flex-wrap flex justify-between mt-10">
        {customerSavings.length > 0 && state.transactionsLoaded ? (
          <React.Fragment>
            <div className="w65">
              <div className="view-savings--wrap">
                <MainDetails
                  savings={savings}
                  startWithdrawSavings={openStartWithdrawSavingsModal}
                  startTopUpSavings={openStartTopUpSavingsModal}
                  startCancelSavings={startCancelProcess}
                  ChangeRunningSavingsCard={handleOpenChangeRunningSavingsCardModal}
                  pausePersonalTargetSavings={pausePersonalTargetSavings}
                  isPausePersonalTargetSavingsLoading={
                    state.isPausePersonalTargetSavingsLoading
                  }
                  resumePersonalTargetSavings={resumePersonalTargetSavings}
                  isResumePersonalTargetSavingsLoading={
                    state.isResumePersonalTargetSavingsLoading
                  }
                />
              </div>
            </div>

            <div className="w35">
              <TransactionHistory groupedTransactions={groupedTransactions} />
            </div>
          </React.Fragment>
        ) : (
          <div className="flex flex-col justify-center min-screen items-center">
            <div className="flex flex-col justify-center items-center">
              <i
                className="w-10 mb-4"
                dangerouslySetInnerHTML={{ __html: logo }}
              />
              <Loading text="" />
            </div>
          </div>
        )}
      </div>

      <CancelSavingsModal
        isVisible={state.isStartCancelSavingsModalVisible}
        closeModal={closeStartCancelSavingsModal}
        amountToDisburse={state.amountToDisburse}
        completeCancelSavings={completeCancelProcess}
      />

      <CancelSavingsSuccess
        isVisible={state.isCancelSavingsSuccessModalVisible}
        closeModal={closeStartCancelSavingsSuccessModal}
        amountToDisburse={state.amountToDisburse}
      />
      <StartTopUpSavingsModal
        isVisible={state.isStartTopUpModalVisible}
        closeModal={closeStartTopUpSavingsModal}
        savings={savings}
        startTopUpProcess={startTopUpPersonalTargetSavings}
      />

      <StartWithdrawSavingsModal
        isVisible={state.isStartWithdrawSavingsModalVisible}
        closeModal={closeStartWithdrawSavingsModal}
        savings={savings}
        startWithdrawProcess={startWithdrawProcess}
        continueToWithdrawalSummary={showWithdrawalSummaryModal}
      />

      <WithdrawalSummaryModal
        isVisible={state.isWithdrawalSummaryModalVisible}
        closeModal={closeWithdrawalSummaryModal}
        savings={savings}
        withdrawalDetails={{
          penalty: state.penalty,
          amountSaved: state.amountSaved,
          amountToDisburse: state.amountToDisburse,
        }}
        completeWithdrawSavings={completeWithdrawProcess}
      />

      <WithdrawSavingsSuccess
        isVisible={state.isWithdrawSavingsSuccessModalVisible}
        closeModal={closeWithdrawSavingsSuccessModal}
        amountToDisburse={state.amountToDisburse}
      />

     <ChangeRunningSavingsCard
          formValues={state.formValues}
          isVisible={state.showChangeRunningSavingsCardModal}
          onSubmit={handleSubmitChangeRunningSavingsCardForm}
          close={handleCloseChangeRunningSavingsCardModal}
      />
      <ChangeRunningSavingCardSuccess
          isVisible={state.showChangeRunningSavingsSuccessModal}
          close={handleChangeRunningCardSuccessModalClose}
        />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  customerSavings: state.customerSavings.data,
});

export default connect(mapStateToProps)(ViewSimpleSavings);
