import React, { useState } from "react";
import produce from "immer";
import NumberFormat from "react-number-format";
import { formatCurrency } from "utils";
import moment from "moment";
import { Redirect } from "react-router-dom";
import fundImg from "assets/img/funds_img.jpg";
import { Link, useHistory } from "react-router-dom";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { connect, useDispatch } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./styles.scss";
import {
  createDollarInvestment,
  uploadDollarInvestmentImage,
} from "state/slices/investments";
import { unwrapResult } from "@reduxjs/toolkit";
import DollarCreateSuccessModal from "./DollarCreateSuccessModal";
import { InvestmentType } from "constants/enums";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

const DollarInvestment = (props) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const [files, setFiles] = useState({
    imagePreviewUrl: "",
  });

  const investment = props.getAllInvestmentsData.find(
    (item) =>
      item.investmentID.toString() === props.location.investmentId.toString()
  );

  const [state, setState] = useState({
    imagePreviewUrl: "",
    isSuccessModalVisible: false,
    isSubmitting: false,
    submitError: "",
  });

  const closeSuccessModal = () => {
    setState(
      produce((draft) => {
        draft.isSuccessModalVisible = false;
      })
    );
  };

  const handleImageChange = (e, setFieldValue) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFieldValue("file", file);
      setState(
        produce((draft) => void (draft.imagePreviewUrl = reader.result))
      );
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  if (!investment) {
    return <Redirect to="/dashboard/investment/add-investment" />;
  }

  const initialFormValues = {
    amount: "",
    duration: "",
    file: "",
    reference: "",
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .min(
        investment.minimumAmount,
        `You can only invest a minimum of ₦${formatCurrency(
          investment.minimumAmount
        )}`
      )
      .label("Amount")
      .required(),
    duration: Yup.number()
      .min(
        investment.minimumDurationInDays,
        `You can only invest for a minimum of ${investment.minimumDurationInDays} days`
      )
      .label("Duration")
      .required(),
    file: Yup.mixed().label("You need to upload an image").required(),
    reference: Yup.string().label("Reference").required(),
  });

  const handleOnSubmit = async (formValues, formikHelper) => {
    setState(
      produce((draft) => {
        draft.isSuccessModalVisible = true;
        draft.isSubmitting = true;
        draft.submitError = "";
      })
    );

    const imageFormData = new FormData();
    imageFormData.append("file", formValues.file);

    const imageUploadResultAction = await dispatch(
      uploadDollarInvestmentImage(imageFormData)
    );

    if (!uploadDollarInvestmentImage.fulfilled.match(imageUploadResultAction)) {
      setState(
        produce((draft) => {
          draft.isSuccessModalVisible = true;
          draft.isSubmitting = false;
          draft.submitError =
            imageUploadResultAction.payload?.message ||
            imageUploadResultAction.error?.message ||
            "Image upload failed";
        })
      );

      return;
    }

    const imageUploadResponse = unwrapResult(imageUploadResultAction);

    const createPayload = {
      investmentImage: imageUploadResponse.url,
      investmentID: (investment.investmentID || "").toString(),
      currency: investment.currency,
      autoRollover: true,
      currentRate: 6.4,
      instrumentTypeName: (investment.name || "").toString(),
      rollOverrule: "NO_ROLLOVER",
      startDate: new Date().toISOString(),
      faceValue: parseFloat(formValues.amount),
      tenure: parseInt(formValues.duration),
      investmentType: InvestmentType.Dollar,
    };

    const createResultAction = await dispatch(
      createDollarInvestment(createPayload)
    );

    if (!createDollarInvestment.fulfilled.match(createResultAction)) {
      setState(
        produce((draft) => {
          draft.isSuccessModalVisible = true;
          draft.isSubmitting = false;
          draft.submitError =
            createResultAction.payload?.message ||
            createResultAction.error?.message ||
            "Investment creation failed";
        })
      );

      return;
    }

    console.log("SUBMIT PASSED");

    setState(
      produce((draft) => {
        draft.isSuccessModalVisible = true;
        draft.isSubmitting = false;
        draft.submitError = "";
      })
    );
  };

  const formCurrency = investment.currency === "USD" ? "$" : "₦";

  return (
    <div className="px-4 sm:px-12  flex flex-col fadeIn mt-10">
      <div className="flex flex-row sm:w-8/12 items-center  mb-10 ">
        <p
          onClick={() => {
            return history.push("/dashboard/investment");
          }}
          style={{ color: "#999999" }}
          className="text-xs cursor-pointer"
        >
          Investment
        </p>
        <p style={{ color: "#999999" }} className="text-xs mx-4">
          {" "}
          {">>"}{" "}
        </p>
        <p
          onClick={() => {
            return history.push("/dashboard/investment/add-investment");
          }}
          style={{ color: "#999999" }}
          className="text-xs ml-4 sm:ml-1 cursor-pointer"
        >
          Add new Investment
        </p>
        <p style={{ color: "#999999" }} className="text-xs mx-4">
          {" "}
          {">>"}{" "}
        </p>
        <p className="text-sm text-black"> {investment.label} </p>
      </div>
      <p className="text-black font-bold text-2xl text-left">
        Add new investment
      </p>

      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={handleOnSubmit}
      >
        {({ handleSubmit, isValid, values, setFieldValue, handleBlur }) => {
          return (
            <Form
              className="create-personal--savings w-full flex justify-between"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col sm:flex-row">
                <div
                  style={{ border: "1px solid #F1F1F1" }}
                  className="card sm:w-1/2 pt-24 pb-56 w-auto mb-20 flex flex-col justify-center content-center mt-6 sm:mr-4"
                >
                  <fieldset className="mb-4 w-full px-6 mx-auto">
                    <label className="block text-xs font-medium">
                      How much do you want to invest?
                    </label>
                    <NumberFormat
                      thousandSeparator={true}
                      placeholder="ex. 1000"
                      autoComplete="off"
                      type="text"
                      id="amount"
                      name="amount"
                      className="block w-full text-xs mt-2 p-3 border border-gray-400 rounded"
                      value={values.amount}
                      onValueChange={({ value }) =>
                        (value > 0) ? setFieldValue(value) : setFieldValue(value * (-1))
              
                      }
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="amount"
                      component="p"
                      className="label-error--text mt-3 text-xs color-red font-medium bg-red-200"
                    />
                  </fieldset>

                  <fieldset className="mb-4 w-full px-6 mx-auto mt-4">
                    <label className="block text-xs font-medium">
                      How long do you want to invest?
                    </label>
                    <Field
                      placeholder="Enter duration in days"
                      type="number"
                      id="duration"
                      name="duration"
                      className="block w-full text-xs mt-2 p-3 border border-gray-400 rounded"
                    />
                    <ErrorMessage
                      name="duration"
                      component="p"
                      className="label-error--text mt-3 text-xs color-red font-medium bg-red-200"
                    />
                  </fieldset>

                  <fieldset className="mb-4 w-full px-6 mx-auto mt-4">
                    <label className="block text-xs font-medium">
                      Upload proof of payment
                    </label>
                    <div
                      style={{ border: "1px solid #a0aec0" }}
                      className="myInput block w-full relative text-xs mt-2 p-3 border border-gray-400 rounded"
                    >
                      <p
                        className="absolute left-0 pl-3"
                        style={{ marginTop: "3px" }}
                      >
                        {state.imagePreviewUrl
                          ? `${values.file.name}`
                          : "Choose File"}
                      </p>

                      <input
                        className="fileInput w-full h-full"
                        type="file"
                        onChange={(e) => handleImageChange(e, setFieldValue)}
                        accept="image/*"
                      />
                    </div>
                    <ErrorMessage
                      name="file"
                      component="p"
                      className="label-error--text mt-3 text-xs color-red font-medium bg-red-200"
                    />
                  </fieldset>

                  <fieldset className="mb-4 w-full px-6 mx-auto">
                    <label className="block text-xs font-medium">
                      Generate reference code
                    </label>
                    <div className="flex justify-between items-center">
                      <Field
                        name="reference"
                        className="block w-full text-xs mt-2 p-3 mr-2 border border-gray-400 rounded"
                        readOnly={true}
                      />
                      <button
                        type="button"
                        onClick={() =>
                          setFieldValue(
                            "reference",
                            Math.random().toString(32).substr(2, 6)
                          )
                        }
                        style={{
                          position: "relative",
                          top: "4px",
                        }}
                        className="w-full text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm "
                      >
                        generate
                      </button>
                    </div>

                    <ErrorMessage
                      name="reference"
                      component="p"
                      className="label-error--text mt-3 text-xs color-red font-medium bg-red-200"
                    />
                  </fieldset>

                  <div
                    style={{
                      border: "1px solid #8CB13D",
                      background: "#f9ffeb",
                    }}
                    className="flex flex-col self-center sm:w-8/12 mt-4 p-2 pb-4"
                  >
                    <div className="flex flex-row items-center">
                      <p
                        style={{ color: "#8CB13D" }}
                        className="text-sm font-bold"
                      >
                        Payment information
                      </p>
                    </div>

                    <PerfectScrollbar className="mt-4 account-info-wrapper">
                      <p className="text-sm font-thin">
                        Make payment to account number
                      </p>
                      <p className="text-sm font-bold mt-2">
                        Meristem's Zenith Bank UK Account Details:
                      </p>
                      <p className="text-sm mt-2">
                        Beneficiary Name: Meristem Wealth Management Limited
                        <br />
                        Address: 3 Norman Williams Street, Ikoyi, Lagos.
                        <br />
                        Beneficiary Account Number: 00019838 Beneficiary
                        <br />
                        IBAN: GB32ZEIB40624700019838
                        <br />
                        Beneficiary Bank Name: Zenith Bank (UK) Ltd.
                        <br />
                        Beneficiary Bank Swift Code: ZEIBGB2L <br />
                        Sort Code: 40-62-47 <br />
                        ABA No/Fed Wire: 021000089 Beneficiary Bank <br />
                        Address: 39 Cornhill, London, EC3V 3ND, UK
                      </p>
                      <p className="text-sm mt-2">
                        Intermediary Bank Name: CitiBank N.A Intermediary Bank
                        Swift
                        <br />
                        Code: CITIUS33
                        <br />
                        Account Number with Intermediary Bank: 36874973
                        <br />
                        Intermediary Bank Address: 111 Wall Street, New York,
                        New York, 10043, USA. <br />
                        Purpose of Payment: Eurobond Investment in favour
                      </p>
                      <p className="text-sm mt-4 font-bold text-red-500">
                        PS. make use of generated reference code during proof of
                        payment.
                      </p>
                    </PerfectScrollbar>
                  </div>
                </div>

                <div
                  style={{ border: "1px solid #F1F1F1" }}
                  className="sm:w-1/2 w-auto card sm:w-1/2 pt-24  pb-20  flex flex-col justify-center mt-6 items-center"
                >
                  <div className="w-72 shadow-lg p-2">
                    <img src={fundImg} alt="" className="h-full w-full" />
                  </div>

                  <p className="text-xs mt-10 text-black text-center text-opacity-25">
                    Investment capital
                  </p>

                  <p className="text-black text-lg text-center mt-2 font-bold">
                    {formCurrency + formatCurrency(values.amount)}
                  </p>

                  <div className="flex flex-row justify-between w-full mt-4 px-16 items-center">
                    <p className="text-left text-black text-opacity-25 text-base">
                      Interest rate per year
                    </p>
                    <p className="text-right text-black text-base">
                      {" "}
                      {investment.interestRate.toFixed(2)}%
                    </p>
                  </div>

                  <div className="flex flex-row justify-between px-16 mt-6 w-full items-center">
                    <p className="text-left text-black text-opacity-25 text-base">
                      Start date
                    </p>

                    <p className="text-right text-black text-base">
                      {moment().format("L")}
                    </p>
                  </div>

                  <div className="nav-buttons flex justify-center">
                    <Link
                      to="/dashboard/investment/add-investment"
                      className="mt-12 w-20 sm:w-40  border-b text-center bg-white leading-loose border-wb-primary text-wb-primary mr-3 border wealth-buddy--cta text-white rounded-sm"
                    >
                      Back
                    </Link>

                    <button
                      onClick={handleSubmit}
                      className={`mt-12 w-20 sm:w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm `}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      <DollarCreateSuccessModal
        isVisible={state.isSuccessModalVisible}
        loading={state.isSubmitting}
        error={state.submitError}
        close={closeSuccessModal}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  createInvestmentLoading: state.investments.createInvestmentLoading,
  createInvestmentError: state.investments.createInvestmentError,
  createInvestmentData: state.investments.createInvestmentData,
  getAllInvestmentsData: state.investments.getAllInvestmentsData,
});

export default connect(mapStateToProps)(DollarInvestment);
