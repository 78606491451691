import React from "react";

const LegalIcon = (props) => {
  return (
    <svg width={14} height={17} viewBox="0 0 14 17" fill="none" {...props}>
      <path
        d="M.008 8.5C.008 5.987.015 3.474 0 .961 0 .415.406.087.82.021.935.008 1.05 0 1.165 0c2.76 0 5.528 0 8.288-.007.391 0 .713.109.982.342 1.05.896 2.1 1.784 3.166 2.666.276.226.399.488.399.816-.008 4.064 0 8.128 0 12.2 0 .633-.406.983-1.158.983H1.173c-.759 0-1.158-.342-1.158-.983C.008 13.51.008 11.006.008 8.5zm3.028-1.457V7.7h7.92v-.656h-7.92zm7.928 2.265H3.036v.634h7.928v-.634zm0 2.258h-7.92v.649h7.92v-.649zM8.74 5.434v-.649H3.036v.649H8.74z"
        fill="#FCFCFC"
      />
    </svg>
  );
};

export default LegalIcon;
