import { createSelector } from "@reduxjs/toolkit";

const getFilteredCustomerSavings = (state, props) => {
  if (!props.statusFilter) {
    return state.customerSavings.data;
  }

  return state.customerSavings.data.filter(
    (s) => s.status === props.statusFilter
  );
};

export const selectFilteredCustomerSavings = createSelector(
  getFilteredCustomerSavings,
  (customerSavings) => customerSavings
);
