import React from "react";

const SavingsIcon = (props) => {
  return (
    <svg width={18} height={22} viewBox="0 0 18 22" fill="none" {...props}>
      <path
        d="M9.44091 4.79001C8.32824 4.79001 7.21556 4.79001 6.10289 4.79001C5.96204 4.79001 5.77894 4.75074 5.69443 4.67222C4.96204 3.92623 4.32824 3.11481 4.07472 2.10708C3.91979 1.49197 4.00429 0.903033 4.34232 0.340273C4.51134 0.0654368 4.7226 -0.0261754 5.04655 0.104699C5.38458 0.235574 5.7226 0.353361 6.06063 0.471148C6.9057 0.772159 7.73669 0.706722 8.55359 0.340273C8.68035 0.287923 8.8212 0.235574 8.93387 0.170136C9.30007 -0.0523503 9.6381 -0.0261754 10.0184 0.183224C11.1029 0.772159 12.2296 0.785246 13.3705 0.287923C13.4832 0.235574 13.6099 0.196311 13.7226 0.157049C14.2578 -0.0392628 14.3846 -4.62867e-07 14.6381 0.471148C15.0465 1.23022 15.0043 2.01547 14.5958 2.73528C14.2156 3.41582 13.6944 4.03093 13.2156 4.65913C13.1451 4.75074 12.962 4.79001 12.8212 4.79001C11.6944 4.79001 10.5677 4.79001 9.44091 4.79001Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6C3.13403 6 0 9.13401 0 13V17C0 19.7614 2.23853 22 5 22H13C15.7615 22 18 19.7614 18 17V13C18 9.13401 14.866 6 11 6H7ZM12.5469 18H11.416L7.12109 11.4258V18H5.99023V16H5V15H5.99023V13H5V12H5.99023V9.46875H7.12109L11.4277 16.0723V9.46875H12.5469V12H13.5V13H12.5469V18Z"
        fill="white"
      />
    </svg>
  );
};

export default SavingsIcon;
