import React, { Fragment, useEffect, useState } from "react";
import CloseModalIcon from "shared-components/svgs/CloseModalIcon";
import { closeModalOnOutsideClick } from "utils";
import "./styles.scss";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import PrimarySolidButton from "shared-components/forms/PrimarySolidButton";
import PrimaryOutlineButton from "shared-components/forms/PrimaryOutlineButton";
import { useDispatch } from "react-redux";
import { createSubAccount } from "state/slices/investments";
import produce from "immer";

const AddSubAccountModal = ({
  isVisible,
  isCreateLoading = false,
  createError = null,
  onClose: handleClose,
  onSuccess: handleSuccess,
}) => {
  useEffect(() => {
    isVisible && closeModalOnOutsideClick(handleClose);
  }, [isVisible]);

  const CurrentStep = {
    Disclaimer: 0,
    Profile: 1,
  };

  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(CurrentStep.Disclaimer);
  const goToDisclaimerStep = () => setCurrentStep(CurrentStep.Disclaimer);
  const goToProfileStep = () => setCurrentStep(CurrentStep.Profile);

  const [state, setState] = useState({
    isCreating: false,
    isCreationSuccessful: false,
  });

  const initialFormValues = {
    name: "",
    age: "",
    relationship: "",
    description: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().label("Name").required(),
    age: Yup.number().label("Age").required(),
    relationship: Yup.string().label("Relationship").required(),
    description: Yup.string().label("Description").required(),
  });

  const handleCreateSubAccount = async (formValues) => {
    setState(produce((draft) => void (draft.isCreating = true)));
    
    const resultAction = await dispatch(createSubAccount(formValues));

    setState(produce((draft) => void (draft.isCreating = false)));

    if (createSubAccount.fulfilled.match(resultAction)) {
      setState(produce((draft) => void (draft.isCreationSuccessful = true)));
      handleClose();
      handleSuccess();
    } else {
      setState(produce((draft) => void (draft.isCreationSuccessful = false)));
    }
  };

  return (
    isVisible && (
      <div className="modal fixed inset-0 bg-wb-overlay flex justify-center items-center modal-active add-sub-account-modal">
        <div className="auth-modal flex flex-col items-center bg-white fadeIn login-fieldset">
          {!isCreateLoading && (
            <span className="closeModal" onClick={handleClose}>
              <CloseModalIcon />
            </span>
          )}

          <Fragment>
            {createError && (
              <div className="w-72 text-xs text-left mb-8">
                <p className="w-full p-3 bg-red-200 text-red-700 rounded text-center font-medium">
                  {createError}
                </p>
              </div>
            )}

            <div className="flex flex-col align-content-center w-full items-center mb-6">
              <div className="w-2/3 mb-5">
                <div className="progressbar-wrapper">
                  <ul className="progressbar">
                    <li
                      className={
                        currentStep === CurrentStep.Disclaimer ? "active" : ""
                      }
                    >
                      Disclaimer
                    </li>
                    <li
                      className={
                        currentStep === CurrentStep.Profile ? "active" : ""
                      }
                    >
                      Profile
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {currentStep === CurrentStep.Disclaimer ? (
              <div className="w-full">
                <div className="px-12">
                  <div className="mb-6 text-gray-800 text-center">
                    This is a sub account and cannot be used as a corporate
                    account. To open a corporate account, please contact
                  </div>
                  <div className="mb-6 text-gray-300 text-center">
                    crmwealth@meristemng.com
                  </div>
                </div>

                <div className="mt-10 flex justify-center space-x-3">
                  <PrimaryOutlineButton title="Cancel" onClick={handleClose} />

                  <PrimarySolidButton title="Next" onClick={goToProfileStep} />
                </div>
              </div>
            ) : currentStep === CurrentStep.Profile ? (
              <Formik
                initialValues={initialFormValues}
                validationSchema={validationSchema}
                validateOnMount={true}
                onSubmit={handleCreateSubAccount}
              >
                {({ handleSubmit, isValid }) => (
                  <Form className="w-full" onSubmit={handleSubmit}>
                    <fieldset className="mb-6">
                      <label className="block text-xs mb-3">Name</label>
                      <Field
                        placeholder="Sub-account name"
                        type="text"
                        name="name"
                        className="block w-full text-xs p-3 readOnly border border-gray-400 rounded"
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="mt-2 text-sm text-red-500"
                      />
                    </fieldset>

                    <fieldset className="mb-6">
                      <label className="block text-xs mb-3">Age</label>
                      <Field
                        placeholder="Enter age"
                        type="number"
                        name="age"
                        className="block w-full text-xs p-3 readOnly border border-gray-400 rounded"
                      />
                      <ErrorMessage
                        component="div"
                        name="age"
                        className="mt-2 text-sm text-red-500"
                      />
                    </fieldset>

                    <fieldset className="mb-6">
                      <label className="block text-xs mb-3">Relationship</label>
                      <Field
                        placeholder="Enter relationship"
                        type="text"
                        name="relationship"
                        className="block w-full text-xs p-3 readOnly border border-gray-400 rounded"
                      />
                      <ErrorMessage
                        component="div"
                        name="relationship"
                        className="mt-2 text-sm text-red-500"
                      />
                    </fieldset>

                    <fieldset className="mb-6">
                      <label className="block text-xs mb-3">Description</label>
                      <Field
                        placeholder="Enter description"
                        type="text"
                        name="description"
                        className="block w-full text-xs p-3 readOnly border border-gray-400 rounded"
                      />
                      <ErrorMessage
                        component="div"
                        name="description"
                        className="mt-2 text-sm text-red-500"
                      />
                    </fieldset>

                    <div className="mt-10 flex justify-center space-x-3">
                      <PrimaryOutlineButton
                        title="Back"
                        onClick={goToDisclaimerStep}
                      />

                      <PrimarySolidButton
                        type="submit"
                        title="Create Account"
                        loadingTitle="Creating Account..."
                        loading={state.isCreating}
                        disabled={!isValid}
                        onClick={handleSubmit}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            ) : null}
          </Fragment>
        </div>
      </div>
    )
  );
};

export default AddSubAccountModal;
