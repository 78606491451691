import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const quiz = [
  {
    question: "What is your annual income?",
    options: [
      "360,000 - 480,000",
      "480,000 - 1,200,000",
      "1,200,000 - 5,400,000",
      "5,400,000 - 10,000,000",
    ],
  },
  {
    question: "Are you an high risk, low risk or medium rist taker?",
    options: ["High risk taker", "Medium risk taker", "Low risk taker"],
  },
  {
    question:
      "What percentage of your income are you wllling to put into an investment",
    options: [
      "Less than 10%",
      "Less than 20%",
      "Less than 30%",
      "Less than 40%",
      "Less than 50%",
    ],
  },
];

const ProgressBar = (props) => {
  return (
    <div className="w-full h-2 bg-gray-400 rounded-sm">
      <div
        className="h-full bg-wb-primary"
        style={{
          width: `${props.progress}%`,
        }}
      ></div>
    </div>
  );
};

const RoboAdvisory = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [answers, setAnswers] = useState([]);
  const history = useHistory();

  const navigateNext = () => {
    setPageIndex(pageIndex + 1);
  };

  const navigatePrev = () => {
    setPageIndex(pageIndex - 1);
  };

  const handleAnswerChange = (e) => {
    const value = e.target.value;

    const newAnswers = [...answers];

    newAnswers[pageIndex - 2] = value;

    console.log(newAnswers);

    setAnswers(newAnswers);
  };

  const handleCompleteSetup = () => {
    history.push("dashbord/wallet");
  };

  const renderIntro = () => {
    return (
      <div className="pt-12 text-center">
        <p>
          Robo Advisory is an Artificaial Intelligence system provided by
          Meristem to help you make better investment choices and manage your
          investment portfolios. To continue, ansswer this few questions to help
          Robo get some useful tips about you.
        </p>
      </div>
    );
  };

  const renderOutro = () => {
    return (
      <div>
        <p>
          Thank you for answering this questions. Now Robo will be able to bring
          to you investment plans that suit your personality and also make the
          best choices for you.
        </p>
      </div>
    );
  };

  const renderQuestions = () => {
    const percentageCompletion = ((pageIndex - 1) / quiz.length) * 100;

    return (
      <div>
        <div className="my-4">
          <ProgressBar progress={percentageCompletion} />
        </div>
        <p>
          {pageIndex - 1}. {quiz[pageIndex - 2].question}
        </p>
        <div className="my-4">
          {quiz[pageIndex - 2].options.map((option, index) => {
            return (
              <p>
                <input
                  type="radio"
                  className="mr-2"
                  checked={answers[pageIndex - 2] === index}
                  name={option}
                  value={index}
                  onChange={handleAnswerChange}
                />{" "}
                {option}
              </p>
            );
          })}
        </div>
      </div>
    );
  };

  const renderPage = () => {
    switch (pageIndex) {
      case 1:
        return renderIntro();

      case quiz.length + 1:
        return renderOutro();

      default:
        return renderQuestions();
    }
  };

  return (
    <div className="px-12 full-page flex flex-col">
      <h1 className="text-4xl mb-6 font-medium">Robo Advisory</h1>
      <div className="flex h-full">
        {/* <div className="flex"> */}
        {/* <div className="flex-1 w-full"> */}
        <div className="main-section p-12 mr-6 rounded-sm h-full flex-1 flex flex-col items-center justify-between">
          <div className="w-3/4 leading-6">{renderPage()}</div>
          {pageIndex - 1 < quiz.length ? (
            <div className="mt-8 mb-4 flex justify-center">
              <button
                className="border border-gray-400 text-gray-400 rounded-md px-16 py-3"
                disabled={pageIndex === 1}
                onClick={navigatePrev}
              >
                Back
              </button>
              <button
                className="ml-4 bg-wb-primary shadow text-white rounded-md px-16 py-3"
                onClick={navigateNext}
              >
                Next
              </button>
            </div>
          ) : (
            <div className="mt-8 mb-4 flex justify-center">
              <button
                className="bg-wb-primary shadow text-white rounded-md px-16 py-3"
                onClick={handleCompleteSetup}
              >
                Complete Setup
              </button>
            </div>
          )}
          {/* </div> */}
          {/* </div> */}
        </div>
        <div className="tips-section p-8 w-1/4">Tips</div>
      </div>
    </div>
  );
};

export default RoboAdvisory;
