import React, { useState,useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { formatCurrency } from "utils";
import InvestModalSuccess from "../../../../components/investModal/InvestModalSuccess";
import CloseModalIcon from "shared-components/svgs/CloseModalIcon";
import { closeModalOnOutsideClick } from "utils";
import NumberFormat from "react-number-format";
import PaymentCardDropDown from "./PaymentCardDropDown"
import { Form, Formik } from "formik";
import * as yup from "yup";
import DatePicker, { utils } from "react-modern-calendar-datepicker";
import { MutualFundsRecurrentFrequency, MutualFundsRecurrentInterval } from '../../../../../../constants/enums'
import { createRecurringFundsConfig, toggleRecurringFunds } from "state/slices/investments";
import produce from "immer";
import { useDispatch } from "react-redux";
import { convertYmdJsonToDate } from "utils";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import {
    getAllFixedTransactions,
    getInvestmentTransactionsForFund,
    getAllTbillsTransactions,
    getInvestmentValuation,
  } from "state/slices/investments";

  import {
    toast
  } from 'react-toastify';



const initialValues = {
    amount: "",
    cardId: "",
}
const MutualFundsRecurrentFrequencies = {
    [MutualFundsRecurrentFrequency.Daily.toString()]: "Day",
    [MutualFundsRecurrentFrequency.Weekly.toString()]: "Week",
    [MutualFundsRecurrentFrequency.Monthly.toString()]: "Month",
};

const MutualFundsRecurrentIntervals = {
    [MutualFundsRecurrentInterval.One.toString()]: "One",
    [MutualFundsRecurrentInterval.Two.toString()]: "Two",
    [MutualFundsRecurrentInterval.Three.toString()]: "Three",
    [MutualFundsRecurrentInterval.Four.toString()]: "Four",
    [MutualFundsRecurrentInterval.Five.toString()]: "Five",
};


const MutualFundRecurrentInvestment = ({
    payload,
    close,
    isVisible,
    cards,
    
}) => {
    
    const dispatch = useDispatch();
    const history = useHistory();

    const [state, setState] = useState({
        isCreating: false,
        isCreationSuccessful: false,
    });
    
    const refresh = () => {

        dispatch(getAllTbillsTransactions());
        dispatch(getInvestmentTransactionsForFund());
        dispatch(getAllFixedTransactions());
        dispatch(getInvestmentValuation());
        history.push("/dashboard/investment");
    }

    const validationSchema = yup.object().shape({
        amount: yup
          .number()
            .label("Amount")
            .min("1000",`You can only save a minimum of ₦${formatCurrency("1000")}`)
            .required(),
        startDate: yup.string().label("Start Date").required(),
        frequency: yup.string().label("Frequency").required(),
        interval: yup.string().label("Interval").required(),
        cardId: yup.string().label("Card").required(),
    })
    
    const handleCreateRecurringFundsConfig = async (formValues) => {
           setState(produce((draft) => void (draft.isCreating = true)));
             const data = {
            "cardID": formValues.cardId,
            "investmentID": payload.securityId,
            "isRecurrent": true,
            "recurringAmount": parseFloat(formValues.amount),
            "schedule": parseInt(formValues.frequency),
            "interval": parseInt(formValues.interval),
            "startDate": convertYmdJsonToDate(formValues.startDate),
             }
             const resultAction = await dispatch(createRecurringFundsConfig(data));
    
            setState(produce((draft) => void (draft.isCreating = false)));
    
            if (createRecurringFundsConfig.fulfilled.match(resultAction)) {
                const response = unwrapResult(resultAction);
                close()
                refresh()
                toast.success(response.message || "Recurring Enabled Created Successfully", {
                    icon: "🚀"
                  });
                // store.addNotification({
                // ...notification,
                // title: "Success",
                // message: response.message || "Recurring Enabled Created Successfully",
                // type: "success",
                // });
        
            
            } else {
            setState(produce((draft) => void (draft.isCreationSuccessful = false)));
            }
      };

    useEffect(() => {
      isVisible && closeModalOnOutsideClick(close);
    }, [isVisible]);

  const [isReadyForSubmission, setIsReadyForSubmission] = useState(false);
  const [userCard, setUserCard] = useState("");
  const [activeOne, setActiveOne] = useState(false)

  const setInvestmentPayload = payload;

  if (activeOne) {
    setInvestmentPayload.cardId = `${userCard}`;
  }

  const setAvailableCard = (value) => {
    setUserCard(value);
  }
 return (

        <div className="modal fixed inset-0 bg-wb-overlay flex justify-center items-center modal-active">
        <div className="auth-modal flex flex-col items-center bg-white fadeIn login-fieldset">
            <span
            className="closeModal cursor-pointer"
            onClick={close}
            >
            <span className="closeModal">
                <CloseModalIcon />
            </span>
            </span>

            {!isReadyForSubmission ? (
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            onSubmit={handleCreateRecurringFundsConfig}
        >
            {({
            handleSubmit,
            isValid,
            setFieldValue,
            values,
            handleChange,
            handleBlur,
            ...formikProps
            }) => {
            return (
                <Form
                className="flex flex-col items-center text-black "
                onSubmit={handleSubmit}
                >
                    <p className="text-red-600">{values.amount != "" && values.frequency !== "" && values.interval !== "" 
                        ? `👉🏾 We will debit you ₦${values.amount}, at ${values.interval || 0} ${MutualFundsRecurrentFrequencies[values.frequency] || 0}(s) interval.`
                        : ""}
                    </p>
                <fieldset className="mt-6 w-full">
                    <label className="block text-xs mb-2"> Amount</label>
                    <NumberFormat
                    thousandSeparator={true}
                    placeholder="eg: 20,000"
                    autoComplete="off"
                    type="text"
                    id="amount"
                    name="amount"
                    className="block w-72 text-xs p-3 border border-gray-400 rounded"
                    value={values.amount}
                    onBlur={formikProps.handleBlur}
                    onValueChange={({ value }) =>
                        setFieldValue("amount", value)
                    }
                    />
                        
                </fieldset>  <br />    
                
                <fieldset className="mb-6">
                    <label className="block text-xs mb-3">
                        How often do you want us to debit you?
                    </label>
                    <select
                        name="frequency"
                        className="block w-full text-xs p-3 border border-gray-400 rounded"
                        value={values.frequency}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                    <option defaultValue="1" disabled>
                            Select how often you want to save
                        </option>
                            {Object.entries(MutualFundsRecurrentFrequency).map(
                                (option, key) => (
                                <option key={key} value={option[1]}>
                                    {option[0]}
                                </option>
                                )
                            )}
                    </select>
                </fieldset>
                    
                <fieldset className="mb-6">
                        <label className="block text-xs mb-3">
                            At what Interval ?
                        </label>
                        <select
                            name="interval"
                            className="block w-full text-xs p-3 border border-gray-400 rounded"
                            value={values.interval}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        >
                        <option defaultValue="1" disabled>
                            At what interval ?
                        </option>
                        {Object.entries(MutualFundsRecurrentInterval).map(
                            (option, key) => (
                            <option key={key} value={option[1]}>
                                {option[0]}
                            </option>
                            )
                        )}
                        </select>
                </fieldset>      

                <fieldset className="mb-6">
                    <label className="block text-xs mb-2">Select Card</label>
                    <div className="fieldset">
                    <PaymentCardDropDown
                        selectedItemId={values.cardId}
                        onSelectItem={(item) =>
                        setFieldValue("cardId", item.value)
                        }
                    />
                    </div>
                </fieldset>

                <fieldset className="mb-6">
                    <label className="block text-xs mb-3">
                            When do you want to start ?
                    </label>
                    <DatePicker
                        inputPlaceholder="Select Start Date"
                        value={values.startDate}
                        onChange={(value) =>  setFieldValue("startDate", value) }
                        shouldHighlightWeekends
                        minimumDate={utils("en").getToday()}                            
                        name="startDate"
                        inputClassName="w-full text-xs p-3 border border-gray-400 rounded text-left-f"
                    />
                </fieldset>     



                <div className="nav-buttons flex justify-center">
                    <div
                    onClick={close}
                    className=" w-40 border-b text-center bg-white leading-loose border-wb-primary text-wb-primary mr-3 border wealth-buddy--cta text-white rounded-sm"
                    >
                    Back
                    </div>
                    <button
                    type="submit"
                    className="w-40 text-center leading-loose bg-wb-primary wealth-buddy--cta text-white rounded-sm"
                    onSubmit={handleSubmit}
                    disabled={!isValid}
                    >
                    {state.isCreating ? " Processing..." : "Proceed"}
                    </button>
                </div>
                </Form>
            );
            }}
            </Formik>
            ) : (
            <InvestModalSuccess
                createInvestmentPayload={setInvestmentPayload}
                close={close}
            />
            )}
        </div>
        </div>
   
 )

};

const mapStateToProps = (state) => ({
  cards: state.cards.data,
  dashboard: state.dashboard.data,
});



export default connect(mapStateToProps)(MutualFundRecurrentInvestment);
