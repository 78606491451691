import React from "react";

const WalletIcon = (props) => {
  return (
    <svg width={13} height={10} viewBox="0 0 13 10" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0)" fill="#fff">
        <path d="M12.99 3.748H8.466c-.764 0-1.343.582-1.317 1.305.027.668.606 1.189 1.332 1.194.659.005 1.317 0 1.975 0H12.995v.147c0 .97-.01 1.937.005 2.908.005.48-.31.698-.732.698C8.42 9.995 4.57 9.995.727 9.995c-.395 0-.616-.142-.7-.445-.022-.076-.022-.157-.022-.238C0 6.434 0 3.556 0 .672 0 .223.237 0 .706 0h11.583c.385 0 .6.142.69.445A.653.653 0 0113 .622v3.045c-.005.02-.005.04-.01.081z" />
        <path d="M9.098 4.997c0 .35-.29.623-.653.623a.629.629 0 01-.642-.633.63.63 0 01.648-.612c.363 0 .647.273.647.622z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h13v10H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WalletIcon;
