import React from "react";

const InvestmentIcon = (props) => {
  return (
    <svg width={13} height={13} viewBox="0 0 13 13" fill="none" {...props}>
      <path
        d="M12.049 13h-1.732a.953.953 0 01-.95-.954V.954c0-.526.426-.953.95-.953h1.732c.525 0 .951.427.951.953V12.04c0 .533-.426.96-.951.96zM8.16 5.74v6.3a.953.953 0 01-.952.953h-1.6a.953.953 0 01-.951-.953v-6.3c0-.526.426-.953.95-.953h1.601c.525 0 .951.427.951.953zM2.506 13H.95A.953.953 0 010 12.047v-1.559c0-.526.426-.953.951-.953h1.555c.524 0 .95.427.95.953v1.559a.953.953 0 01-.95.953z"
        fill="#fff"
      />
    </svg>
  );
};

export default InvestmentIcon;
