import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import _ from "lodash";
import produce from "immer";
import numeral from "numeral";

const DashboardAssetsAllocation = ({
  dashboard,
  savingsStatistics,
  investmentValuation,
}) => {
  const fundCost  = investmentValuation !== null ? investmentValuation.fundCost : 0
  const fundReturn = investmentValuation !== null ?  investmentValuation.fundReturn : 0
  const fundValue = investmentValuation !== null ?  investmentValuation.fundValue : 0
  const totalFixedDepositPrincipal = investmentValuation !== null ?  investmentValuation.totalFixedDepositPrincipal : 0
  const totalFixedDepositIncome = investmentValuation !== null ?  investmentValuation.totalFixedDepositIncome : 0
  const totalTBillDiscountedValue = investmentValuation !== null ?  investmentValuation.totalTBillDiscountedValue : 0
  const totalTBillInterestValue = investmentValuation !== null ?  investmentValuation.totalTBillInterestValue : 0
  const bondCost = investmentValuation !== null ?  investmentValuation.bondCost : 0
  const bondReturn = investmentValuation !== null ? investmentValuation.bondReturn : 0
  const equityCost = investmentValuation !== null ? investmentValuation.equityCost : 0
  const equityReturn = investmentValuation !== null ? investmentValuation.equityReturn : 0
  

  const [state, setState] = useState({
    series: [0, 0, 0, 0, 0, 0],
    options: {
      chart: {
        type: "pie",
      },
      labels: [
        "Savings",
        "Mutual Funds",
        "Fixed Deposit",
        "Treasury Bills",
        "Bonds",
        "Equities",
      ],
      colors: [
        "#ef878e",
        "#3986ee",
        "#008000",
        "#4863A0",
        "#98FB98",
        "#503335",
      ],

      plotOptions: {
        pie: {
          expandOnClick: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          formatter: (value) => numeral(value).format("0,0.00"),
        },
      },
      legend: {
        onItemHover: {
          highlightDataSeries: true,
        },
      },
    },
  });

  useEffect(() => {
    const series = [
      dashboard.totalSavings,
      fundCost + (fundValue - fundCost),
      totalFixedDepositPrincipal + totalFixedDepositIncome,
      totalTBillDiscountedValue + totalTBillInterestValue,
      bondCost + bondReturn,
      equityCost + equityReturn,
    ];

    setState(
      produce((draft) => {
        draft.series = series;
      })
    );
  }, [savingsStatistics, investmentValuation]);

  return (
    <div className="flex flex-col card">
      <h1 className="text-4xl mb-3 font-medium card-header">
        Assets Allocation
      </h1>
      <div className="flex-grow flex justify-center items-center">
        <ReactApexChart
          type="pie"
          options={state.options}
          series={state.series}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  savingsStatistics: state.savings.savingsStatisticsEntities,
  dashboard: state.dashboard.data,
  investmentValuation: state.investments.investmentValuationData,
});

export default connect(mapStateToProps)(DashboardAssetsAllocation);
