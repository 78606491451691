export const VERIFY_FUND_WALLET_WITH_NEW_CARD_START =
  "VERIFY_FUND_WALLET_WITH_NEW_CARD_START";
export const VERIFY_FUND_WALLET_WITH_NEW_CARD_SUCCESS =
  "VERIFY_FUND_WALLET_WITH_NEW_CARD_SUCCESS";
export const VERIFY_FUND_WALLET_WITH_NEW_CARD_FAIL =
  "VERIFY_FUND_WALLET_WITH_NEW_CARD_FAIL";
export const VERIFY_FUND_WALLET_WITH_NEW_CARD =
  "VERIFY_FUND_WALLET_WITH_NEW_CARD";

export default {
  VERIFY_FUND_WALLET_WITH_NEW_CARD_START,
  VERIFY_FUND_WALLET_WITH_NEW_CARD_SUCCESS,
  VERIFY_FUND_WALLET_WITH_NEW_CARD_FAIL,
  VERIFY_FUND_WALLET_WITH_NEW_CARD,
};
