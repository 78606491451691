export const GET_INVESTMENT_CONFIG_SUCCESS = "GET_INVESTMENT_CONFIG_SUCCESS";
export const GET_INVESTMENT_CONFIG_FAIL = "GET_INVESTMENT_CONFIG_FAIL";
export const GET_INVESTMENT_CONFIG = "GET_INVESTMENT_CONFIG";
export const GET_INVESTMENT_CONFIG_START = "GET_INVESTMENT_CONFIG_START";

export default {
  GET_INVESTMENT_CONFIG,
  GET_INVESTMENT_CONFIG_FAIL,
  GET_INVESTMENT_CONFIG_SUCCESS,
  GET_INVESTMENT_CONFIG_START,
};
