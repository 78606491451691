import React, { useState } from "react";
import StickyBox from "react-sticky-box";
import TransactionHistory from "./components/TransactionHistory";
import SavingsSummary from "./components/SavingsSummary";
import SavingsList from "./components/SavingsList";
import Select from "react-select";
import { SavingsStatus } from "constants/enums";

const SavingsHome = () => {
  const customSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#4b4b4b" : "#4b4b4b",
      backgroundColor: state.isSelected ? "#f4f4f4" : "#ffffff",
      boxSizing: "border-box",
      padding: "10px 20px",
      width: "100%",
      cursor: "pointer",
      fontSize: 13,
      fontWeight: "500",
      "&:hover": {
        backgroundColor: "#f4f4f4",
      },
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid rgba(33,33,33,.14)",
      borderBottomRightRadius: 2,
      borderBottomLeftRadius: 2,
      paddingTop: 5,
      boxShadow: "0 0.5px 0.2px rgba(0, 0, 0, .15)",
      boxSizing: "border-box",
      top: "70%",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
    }),
    container: (provided) => ({
      ...provided,
      width: 200,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "solid 1px #99bf17" : "solid 1px #e9e9e9",
      padding: "10px",
      margin: "-4px 0 0 0",
      boxShadow: "0 2px 5px rgba(0, 0, 0, .08)",
      minHeight: "auto",
      cursor: "pointer",
      fontSize: 13,
      fontWeight: "500",
      "&:hover": {
        border: "solid 1px #99bf17",
      },
    }),
  };

  const options = [
    { value: null, label: "All" },
    { value: SavingsStatus.Pending, label: "Pending" },
    { value: SavingsStatus.InProgress, label: "In Progress" },
    { value: SavingsStatus.Completed, label: "Completed" },
    { value: SavingsStatus.Cancelled, label: "Cancelled" },
    { value: SavingsStatus.Finished, label: "Finished" },
    { value: SavingsStatus.Withdrawn, label: "Withdrawn" },
  ];

  const [statusFilter, setStatusFilter] = useState(null);

  return (
    <div className="px-12 flex flex-col fadeIn">
      <div className="w-full flex flex-row justify-between py-8 content-header--border">
        <h1 className="text-4xl font-medium content-header--title">Savings</h1>
        <Select
          isSearchable={false}
          styles={customSelectStyles}
          placeholder="Filter savings..."
          options={options}
          defaultValue={options[0]}
          value={options.find((option) => option.label === statusFilter)}
          onChange={(filter) => setStatusFilter(filter.value)}
        />
      </div>

      <div className="flex mt-2 mt-10">
        <div
          className="flex justify-between savings-home--wrap w-full"
          style={{ display: "flex", alignItems: "flex-start" }}
        >
          <StickyBox offsetTop={115} offsetBottom={20} className="w35">
            <div className="w-full w-transaction--max">
              <div className="savings-transactions flex flex-col">
                <SavingsSummary />
                <TransactionHistory />
              </div>
            </div>
          </StickyBox>

          <div className="w65">
            <SavingsList statusFilter={statusFilter} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavingsHome;
