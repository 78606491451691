export const SavingsFrequency = {
  Daily: 1,
  Weekly: 2,
  Monthly: 3
};

export const SavingsType = {
  PersonalTargetSavings: 1,
  FixedLockSavings: 2,
  FixedFlexibleSavings: 3,
  GroupTargetSavings: 4,
  GroupChallengeSavings: 5,
  GroupContributorySavings: 6,
  MKATSavings: 7,
  MFATSavings: 8,
};

export const GroupSavingsType = {
  GroupTargetSavings: 1,
  GroupChallengeSavings: 2,
  GroupContributorySavings: 3,
};

export const InvestmentType = {
  MutualFunds: 1,
  FixedDeposits: 2,
  TreasuryBills: 3,
  Dollar: 4,
  ForeignCurrency: 5,
  Ethical: 6,
  Bonds: 7,
};

export const SavingsStatus = {
  Pending: 1,
  InProgress: 2,
  Completed: 3, // target reached
  Cancelled: 4,
  Finished: 5, // maturity date reached
  Withdrawn: 6,
};

export const GroupInvitationStatus = {
  Pending: 1,
  Accepted: 2,
  Declined: 3,
};

export const MeansOfIdentificationApprovalStatus = {
  Default: 1,
  Approved: 2,
  Denied: 3,
  RejectSelfie: 4,
  NotUploaded: 5,
};

export const UtilityBillApprovalStatus = {
  Default: 1,
  Approved: 2,
  Denied: 3,
  NotUploaded: 4,
};

export const AccountsTab = {
  PRIMARY_ACCOUNT: "PRIMARY_ACCOUNT",
  SUB_ACCOUNTS: "SUB_ACCOUNTS",
};

export const MutualFundsRecurrentFrequency = {
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
};

export const MutualFundsRecurrentInterval = {
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5
};

export const TrustSavingsSchedule = {
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
  OneOff: 4
};