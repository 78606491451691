import React from "react";
import ButtonSpinnerSvg from "./ButtonSpinnerSvg";

const PrimaryOutlineButton = ({ title, loadingTitle, loading, ...props }) => (
  <button
    className="appearance-none focus:outline-none min-w-40 px-4 border-b text-center bg-white hover:bg-gray-100 leading-loose border-wb-primary text-wb-primary border wealth-buddy--cta text-white text-sm rounded-sm transition-colors ease-in duration-200"
    {...props}
  >
    {loading && (
      <ButtonSpinnerSvg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
    )}
    {loading ? loadingTitle || title : title}
  </button>
);

export default PrimaryOutlineButton;
